import { useStringFlagValue } from '@openfeature/react-sdk'
import { sendUpriseCallZap } from '../../actions/zapierActions'
import { User } from '../../reducers/auth/userReducer'
import { getStore } from '../../store'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import {
  createOrUpdateFinancialAdvisoryProfile,
  FinancialAdvisoryFeatureFlagValues,
  FinancialAdvisoryProfilesStatus,
} from './financialAdvisory.slice'

/**
 *
 * Intended to house logic related to advisory logics
 *
 */
export const createIntroCallScheduledAfterEffects = async ({
  currentUser,
}: {
  currentUser: User | null
  eventUri?: string
}) => {
  const store = getStore()
  if (!store) {
    return false
  }
  // Create Advisory Profile + Update to Event Scheduled
  const faProfile = await createOrUpdateFinancialAdvisoryProfile({
    userId: currentUser?.id,
    status: FinancialAdvisoryProfilesStatus.intro_call_scheduled,
  })(store.dispatch)
  // Add to Slack Channel Notif
  await sendUpriseCallZap(faProfile, currentUser)
  return true
}

/**
 * Constants
 */
export const UPRISE_INBOX_EVENT_CODE = 'launch_inbox'
export const UPRISE_SESSION_EXPIRED_EVENT_CODE = 'session_expired'

/**
 * Advisory Status Helpers
 */

export const userHasFinancialAdvisoryAccess = () => {
  const store = getStore()
  if (!store) {
    return false
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ffValue = useStringFlagValue(
    FEATURE_FLAG_KEYS.financialAdvisoryPilot,
    FinancialAdvisoryFeatureFlagValues.noPilot
  )
  // Check advisory profile exists
  const financialAdvisoryProfile = store.getState().financialAdvisoryProfile
  return (
    ffValue !== FinancialAdvisoryFeatureFlagValues.noPilot &&
    financialAdvisoryProfile
  )
}
