import { Grid, List } from 'semantic-ui-react'
import {
  Text,
  Card,
  GridRowColumn,
} from '../../../../../components/BaseComponents'
import { BalanceSheetReportData } from '../../../../../actions/reportActions'
import { formatCurrencyFromCentsAndRound } from '../../../../../utils/currencyHelpers'
import { BalanceSheetCategory } from './BalanceSheetCategory'

interface BalanceSheetReportProps {
  balanceSheetDate: string
  reportData: BalanceSheetReportData
  isPdf?: boolean
}

const BalanceSheetReport = ({
  balanceSheetDate,
  reportData,
  isPdf = false,
}: BalanceSheetReportProps) => {
  return (
    <Grid style={{ width: '90%', margin: '0 auto' }} columns={'equal'}>
      {/* Header */}
      <GridRowColumn short>
        <Text as="h2" textAlign="center">
          Balance Sheet
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text textAlign="center">As of {balanceSheetDate}</Text>
      </GridRowColumn>
      <GridRowColumn />

      {/* Table / Report */}
      <Grid.Row>
        <Grid.Column>
          <Text as="h2">Assets</Text>
        </Grid.Column>
        <Grid.Column>
          <Text as="h2">Liabilities</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <BalanceSheetCategory
            categoryData={reportData.accounts.Assets}
            totalLabel="Total Assets"
            isPdf={isPdf}
            balanceSheetDate={balanceSheetDate}
          />
        </Grid.Column>
        <Grid.Column>
          <BalanceSheetCategory
            categoryData={reportData.accounts.Liability}
            totalLabel="Total Liabilities"
            isPdf={isPdf}
            balanceSheetDate={balanceSheetDate}
          />
          <Text as="h2" style={{ marginTop: 48, marginBottom: 24 }}>
            Equity
          </Text>
          <BalanceSheetCategory
            categoryData={reportData.accounts.Equity}
            totalLabel="Total Equity"
            isPdf={isPdf}
            balanceSheetDate={balanceSheetDate}
          />
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn />

      <GridRowColumn>
        <Card type="subsection" fullWidth>
          <Grid>
            <Grid.Row>
              <Grid.Column width={7}>
                <List>
                  <List.Item>
                    <List.Content floated="right">
                      <Text as="h3">
                        {formatCurrencyFromCentsAndRound(
                          reportData.totals.totalAssetsInCents,
                          { hideDecimalsIfZero: true }
                        )}
                      </Text>
                    </List.Content>
                    <List.Content>
                      <Text as="h3">Total Assets</Text>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={2}>
                <Text as="h3" textAlign="center">
                  =
                </Text>
              </Grid.Column>
              <Grid.Column width={7}>
                <List>
                  <List.Item>
                    <List.Content floated="right">
                      <Text as="h3">
                        {formatCurrencyFromCentsAndRound(
                          reportData.totals.totalLiabilitiesEquityInCents,
                          { hideDecimalsIfZero: true }
                        )}
                      </Text>
                    </List.Content>
                    <List.Content floated="left">
                      <Text as="h3">Total Liabilities & Equities</Text>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </GridRowColumn>
    </Grid>
  )
}

export default BalanceSheetReport
