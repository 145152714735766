/**
 * This Panel shows when a User has done nothing related to Advisory
 *
 */

import { Grid, Image, Icon } from 'semantic-ui-react'
import {
  GridRowColumn,
  Pill,
  Button,
  Text,
  Link,
  Card,
  Modal,
  Alert,
} from '../../../components/BaseComponents'
import CalendlyPopupModal from '../../../components/shared/CalendlyPopupModal'
import { useEffect, useState } from 'react'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { createIntroCallScheduledAfterEffects } from '../service'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { AdvisorHelpSummaryCard } from './PreIntroCallPanel'
import {
  getAdvisoryCallPricing,
  selectFinancialAdvisoryProfile,
} from '../financialAdvisory.selectors'
import { FinancialAdvisoryProfilesStatus } from '../financialAdvisory.slice'
const INTRO_CALL_CALENDLY_URL =
  'https://calendly.com/d/cmjd-h62-zbx/heard-intro-advisory-call'

const ScheduleCallCard = ({
  calendlyOpen,
  track,
  isMobile,
  setCalendlyOpen,
  introCallScheduledAfterEffects,
}: {
  calendlyOpen: boolean
  track: (arg0: string, arg1: { financial_advisory_step: string }) => void
  isMobile: boolean | undefined
  setCalendlyOpen: (arg0: boolean) => void
  introCallScheduledAfterEffects: (arg0: string | undefined) => void
}) => {
  return (
    <Card type="subsection" backgroundColor="moss">
      <Grid stackable doubling centered>
        <GridRowColumn />
        <Grid.Row>
          <Grid.Column textAlign="center" width={8}>
            <Text as="h1">Get Started with Financial Advisory</Text>
            <Text as="h3">$29/month, or $300 per year</Text>
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <Button
              fullWidth={isMobile}
              onClick={() => {
                setCalendlyOpen(true)
                track('clicked schedule intro call button', {
                  financial_advisory_step: 'start schedule introductary call',
                })
              }}
            >
              Schedule your 20 Minute Free Intro Call
            </Button>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn />
      </Grid>
      {calendlyOpen && (
        <CalendlyPopupModal
          url={INTRO_CALL_CALENDLY_URL}
          open={calendlyOpen}
          onClose={() => setCalendlyOpen(false)}
          onScheduled={(eventUri: string | undefined) => {
            introCallScheduledAfterEffects(eventUri)
            track('scheduled introductory financial advisory', {
              financial_advisory_step: 'completed schedule introductory call',
            })
          }}
        />
      )}
    </Card>
  )
}
const HowItWorksCard = () => {
  return (
    <Card type="subsection" backgroundColor="natural">
      <Grid stackable doubling centered>
        <Grid.Row>
          <Grid.Column textAlign="center" width={12}>
            <Text as="h2">Here&apos;s how it works:</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign="center">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisor_piggy_bank.svg"
                alt="heard financial advisory"
                width="100"
              />
            </div>
            <Text as="h3">1. Attend a free 20 minute consultation</Text>
            <br />
            <Text as="bodySm">
              Learn how you can benefit from Advisory with a 20–min introductory
              consultation led by a Certified Financial Planner
            </Text>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisor_financials.svg"
                alt="heard financial advisory"
                width="100"
              />
            </div>
            <Text as="h3">
              2. Add the Advisory package, and onboard to the platform
            </Text>
            <br />
            <Text as="bodySm">
              Post call, you will be able to add Advisory to your Heard
              experience. Once added, share additional information to help your
              advisor formulate your first financial plan.
            </Text>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisor_home.svg"
                alt="heard financial advisory"
                width="100"
              />
            </div>
            <Text as="h3">
              3. Your Advisor creates a 100% personalized, comprehensive
              financial plan
            </Text>
            <br />
            <Text as="bodySm">
              A comprehensive, holistic plan will be created for you, based on
              your intro call and your onboarding answers, personalisted towards
              your holistic financial goals.
            </Text>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisor_home.svg"
                alt="heard financial advisory"
                width="100"
              />
            </div>
            <Text as="h3">4. Work towards your financial goals, together</Text>
            <br />
            <Text as="bodySm">
              Review and access your finanial plan and recommended actions, with
              the ability to message your advisor at any point at time.
              Unlimited revisions and unlimited messaging, with 1:1 calls if
              needed.
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export const UpriseIntroCard = () => {
  return (
    <Card type="subsection" backgroundColor="stone40">
      <Grid stackable doubling centered>
        <Grid.Row>
          <Grid.Column textAlign="center" width={12}>
            <Text as="h2">Who will I be working with?</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12} textAlign="center">
            <Text>
              Heard offers financial advisory in partnership with Uprise, a team
              of Certified Financial Planners that build and create customized
              financial plans based on your goals.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center">
          <Grid.Column
            width={12}
            textAlign="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/uprise_team.svg"
              width="75"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={12}
            textAlign="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/uprise.svg"
              width="150"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const JoinedWaitlistModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  return (
    <Modal size={'small'} dimmer={'inverted'} open={open} onClose={onClose}>
      <Modal.Header>Got it, you’ve been added to the waitlist!</Modal.Header>
      <Modal.Content>
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/check.svg"
            alt="check"
          />
        </div>
        <Text as="bodyMd">
          We are so glad you&#39;re interested in Financial Advisory. We have
          added your name to our waitlist, and we will notify you when spaces
          open up. Thank you for your understanding!
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const WaitlistCard = ({
  waitlistJoined,
}: {
  waitlistJoined: boolean
}) => {
  if (waitlistJoined) {
    return (
      <Card type="subsection" backgroundColor="moss">
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column width={16}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Pill color="green">Currently Waitlisted</Pill>
                <Text as="h2">You’re on our waitlist!</Text>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Text as="bodyMd">
                Thank you for letting us know of your interest. You are now on
                the waitlist, and we will let you know when spaces open up.
              </Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    )
  }
  return (
    <Card type="subsection" backgroundColor="moss">
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Pill color="teal">Now Waitlist Only</Pill>
              <Text as="h2">
                Our Introductory Calls Are Fully Booked Right Now
              </Text>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Text as="bodyMd">
              Due to high demand, all available slots for our free 20-minute
              introductory calls have been booked.
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const IntroCallCTAPanel = () => {
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()

  /** Key Variables */
  const currentUser = useReselector(getCurrentUser)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const callPricing = useReselector(getAdvisoryCallPricing)
  const faProfile = useReselector(selectFinancialAdvisoryProfile)

  /** State Vars */
  const [calendlyOpen, setCalendlyOpen] = useState(false)

  /** Waitlist Vars */
  const isWaitlisted =
    faProfile?.status === FinancialAdvisoryProfilesStatus.waitlisted
  const [error] = useState('')
  const [waitlistJoined] = useState(Boolean(faProfile?.waitlistedAt))
  const [waitlistJoinedModalOpen, setWaitlistJoinedModalOpen] = useState(false)

  // Used to manage after effects of scheduling the intro call
  const introCallScheduledAfterEffects = async (
    eventUri: string | undefined
  ) => {
    track('scheduled intro advisory call', {
      user_id: currentUser?.id || null,
      event_uri: eventUri || null,
    })
    await createIntroCallScheduledAfterEffects({ currentUser })
    // Reload to properly view changes
    location.reload()
  }

  useEffect(() => {
    pageView('advisory: view intro call CTA page')
  }, [pageView])

  return (
    <>
      <Grid
        className="statementTitle"
        style={{ margin: '0 5px' }}
        stackable
        doubling
      >
        {isWaitlisted && (
          <GridRowColumn>
            <WaitlistCard waitlistJoined={waitlistJoined} />
          </GridRowColumn>
        )}
        {error && (
          <Alert type="error">
            It looks like we encountered an error! Please refresh the page and
            try again.
          </Alert>
        )}
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={8} textAlign="center">
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/advisory_screen.svg"
              alt="heard financial advisory"
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid style={{ margin: '0 5px' }}>
              <GridRowColumn>
                <Pill>★ New Add On</Pill>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h1">
                  Small business <b>and</b> personal financial planning for
                  therapists like you
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Heard has partnered with{' '}
                  <Link newPage href="https://uprise.us/" as="secondaryLink">
                    Uprise
                  </Link>
                  , a premium financial advisory platform, to connect our
                  therapists with financial experts geared towards mental health
                  small business owners like you.
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Text as="h2">What the Advisory Add On Includes:</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  <Icon name="check" size="small" />A 100% comprehensive
                  financial plan, build for both your personal and business
                  financial goals
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Unlimited revisions of your financial plan, to accommodate
                  changes in goals
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Expert guidance from your Advisor, who is a Certified
                  Financial Planner (CFP®)
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Unlimited messaging with your advisor to keep your plan
                  updated throughout the year
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Accountability in ensuring you are progressing towards your
                  financial goals
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  For additional support, one on one 45 minute follow up video
                  calls at ${callPricing}
                </Text>
              </GridRowColumn>

              <GridRowColumn>
                <Text as="bodyMd">
                  Attend a <b>free</b> 20–min introductory consultation with a
                  Certified Financial Planner to learn how Advisory could be a
                  fit for you.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  fullWidth={isMobile}
                  onClick={() => {
                    setCalendlyOpen(true)
                    track('clicked schedule intro call button', {
                      financial_advisory_step:
                        'start schedule introductary call',
                    })
                  }}
                >
                  Schedule your 20 Minute Free Intro Call
                </Button>
                <br />
                <Text as="h3" color="mediumGray">
                  Add On available at $29/month, or $300 per year
                </Text>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <AdvisorHelpSummaryCard />
      <br />
      <ScheduleCallCard
        calendlyOpen={calendlyOpen}
        track={track}
        isMobile={isMobile}
        setCalendlyOpen={setCalendlyOpen}
        introCallScheduledAfterEffects={introCallScheduledAfterEffects}
      />
      <br />
      <HowItWorksCard />
      {calendlyOpen && (
        <CalendlyPopupModal
          url={INTRO_CALL_CALENDLY_URL}
          open={calendlyOpen}
          onClose={() => setCalendlyOpen(false)}
          onScheduled={(eventUri: string | undefined) => {
            introCallScheduledAfterEffects(eventUri)
            track('scheduled introductory financial advisory', {
              financial_advisory_step: 'completed schedule introductory call',
            })
          }}
        />
      )}

      <br />
      <UpriseIntroCard />
      <br />
      <ScheduleCallCard
        calendlyOpen={calendlyOpen}
        track={track}
        isMobile={isMobile}
        setCalendlyOpen={setCalendlyOpen}
        introCallScheduledAfterEffects={introCallScheduledAfterEffects}
      />
      <br />
      <br />
      <br />
      <JoinedWaitlistModal
        open={waitlistJoinedModalOpen}
        onClose={() => setWaitlistJoinedModalOpen(false)}
      />
    </>
  )
}
export default IntroCallCTAPanel
