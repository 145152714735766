import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'

import {
  FETCH_TAX_FILINGS_KEY,
  fetchAnnualTaxFilingsIfNeeded,
} from '../annualTaxFilings.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectFilingFormsForCurrentTaxYear,
  selectIsTwoFormFiler,
} from '../annualTaxFilingForms.selector'
import {
  getIsFetching,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../reducers/fetch'
import {
  Accordion,
  Alert,
  BorderedIcon,
  Button,
  Card,
  CircularWrapper,
  Container,
  GridRowColumn,
  Icon,
  Link,
  Loader,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../annualTaxFilingForms.slice'
import PageHeader from '../../../../components/shared/PageHeader'
import { ANNUAL_TAX_FILING_FORM_TYPES } from '../Questionnaires/constants'
import { getUnpaidTaxEstimatesByYear } from '../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import {
  formatTaxQuarter,
  sortAndGroupEstimatesByTaxQuarter,
} from '../../QuarterlyTaxEstimates/helpers'
import {
  FETCH_TAX_ESTIMATES_KEY,
  fetchUserTaxEstimatesIfNeeded,
} from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import {
  StepStatus,
  fetchUserEoyReviewProgress,
  FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
} from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { fetchAllEoyReviewStepsIfNeeded } from './Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import {
  selectUserEoyReviewProgressForSubstepIdentifier,
  selectUserEoyReviewProgressStatusForChecklistSteps,
} from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import {
  CHANGES_IN_YEAR,
  CHECK_YOUR_DETAILS_1040,
  CHECK_YOUR_DETAILS_1120S,
  COMPLETE_PAYMENT,
  DEDUCTIONS_AND_CREDITS,
  ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
  NON_THERAPY_INCOME_AND_LIABILITIES,
  REVIEW_INCOME_AND_EXPENSES_INITIAL,
  SubStepIdentifiers,
  UPDATE_YOUR_BOOKS,
  UPLOAD_DOCUMENTS_1040,
  UPLOAD_DOCUMENTS_1120S,
} from './Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  selectAllRequiredDocsUploaded,
  selectDocsUploadedOrSkippedAndTotalCount,
  selectRequiresPaymentForPersonalFiling,
} from './taxChecklist.selectors'
import { ADDITIONAL_1040_COST, TaxChecklistPaths } from '../constants'
import useProgressSteps from './Shared/ReviewStepsandProgresses/useProgressSteps'
import { fetchUserTaxQuestionnaire } from './taxChecklistQuestion.actions'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../UserDocuments/userDocuments.slice'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { selectUserBooksLockedForCurrentTaxYear } from '../../../../reducers/auth/user.selectors'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import ExtensionAlert from '../ExtensionSurvey/ExtensionAlert'
import useTaxChecklistPayment from './Form1040/payment'
import { selectTaxOptInEligibility } from '../annualTaxFilings.selector'
import { PersonalFilingOptInReminder } from './PersonalFilingOptInReminder'
import { partition } from 'lodash'
import ApproveAndSubmitModal from './ApproveAndSubmitModal'

interface StepCardProps {
  title: ReactNode
  key: string
  content: ReactNode
  path?: TaxChecklistPaths
  buttonText?: string
  buttonIcon?: IconDefinition
  disabled?: boolean
  status?: StepStatus
  action?: () => void
  loading?: boolean
  hideStep?: boolean
  readOnly?: boolean
  formId: number
}

const StepCard = ({
  title,
  content,
  path,
  buttonText,
  buttonIcon,
  disabled,
  status,
  action,
  loading,
  hideStep,
  readOnly,
  formId,
}: StepCardProps) => {
  const navigate = useNavigate()

  const isFetchingProgress = useReselector(
    getIsFetching,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY
  )
  const toPage =
    path && formId
      ? `/taxes/annual/tax_checklist/${formId}/${path}`
      : '/taxes/annual'

  if (hideStep) {
    return null
  }

  return (
    <Card
      backgroundColor={status === StepStatus.completed ? 'stone40' : 'natural'}
      style={{ marginTop: 15 }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={1} verticalAlign="middle">
            {status !== StepStatus.completed && (
              <CircularWrapper
                height={30}
                wrapperColor="white"
                borderColor="gray"
              />
            )}
            {status === StepStatus.completed && (
              <BorderedIcon
                icon={regular('check')}
                size="sm"
                height={30}
                color="green"
                wrapperColor="lightGray"
              />
            )}
          </Grid.Column>
          <Grid.Column width={11}>
            <Text as="h3">{title}</Text>
            <Text style={{ marginTop: 5 }}>{content}</Text>
            {status === StepStatus.completed && !readOnly && (
              <Link
                to={toPage}
                style={{
                  marginTop: 5,
                  fontSize: 14,
                  textDecoration: 'underline',
                }}
              >
                Edit
              </Link>
            )}
          </Grid.Column>
          <Grid.Column width={4} verticalAlign="middle">
            {status !== StepStatus.completed && (
              <Button
                fullWidth
                onClick={() => (action ? action() : navigate(toPage))}
                disabled={disabled || isFetchingProgress}
                variant={isFetchingProgress ? 'secondary' : 'primary'}
                loading={loading || isFetchingProgress}
              >
                {status === StepStatus.inProgress
                  ? buttonIcon && (
                      <Icon icon={buttonIcon} style={{ marginRight: 8 }} />
                    )
                  : buttonIcon && (
                      <Icon icon={buttonIcon} style={{ marginRight: 8 }} />
                    )}
                {buttonText || status === StepStatus.inProgress
                  ? 'Continue'
                  : 'Start'}
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const BookkeeperReviewStatusNotice = () => {
  const updateYourBooksProgress = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    UPDATE_YOUR_BOOKS
  )
  const firstReviewStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    REVIEW_INCOME_AND_EXPENSES_INITIAL
  )

  const booksLocked = useReselector(selectUserBooksLockedForCurrentTaxYear)

  if (
    updateYourBooksProgress !== StepStatus.completed ||
    firstReviewStatus !== StepStatus.completed
  ) {
    return null
  }

  return (
    <Card backgroundColor="stone" type="subsection">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon icon={regular('thumbs-up')} style={{ marginRight: 16 }} />
        <div>
          <Text as="h3" style={{ marginBottom: 5 }}>
            Bookkeeper Review
          </Text>
          <Text color="forest">
            {!booksLocked
              ? 'Your books are under review. We’ll let you know when it’s time to come back and approve changes.'
              : 'Your books are ready! Do one final review of any changes that may have been made.'}
          </Text>
        </div>
      </div>
    </Card>
  )
}

const CombinedChecklist = () => {
  const [approveAndSubmitOpen, setApproveAndSubmitOpen] = useState(false)
  const forms = useReselector(selectFilingFormsForCurrentTaxYear)

  const personalForm = forms.find(
    (form) => form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040
  )
  const businessForm = forms.find(
    (form) => form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  )

  const { personalOptInEligible } = useReselector(selectTaxOptInEligibility)
  const paymentRequired = useReselector(selectRequiresPaymentForPersonalFiling)

  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const { makePayment, paymentLoading, paymentPending } =
    useTaxChecklistPayment({
      formId: personalForm?.id,
    })

  const paymentStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    COMPLETE_PAYMENT
  )

  //Missing QTE payments related
  const unpaidEstimates = useReselector(
    getUnpaidTaxEstimatesByYear,
    currentTaxYear
  )
  const qteProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.addMissingQTEPayments
  )
  const qteStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    ENTER_MISSING_QUARTERLY_TAX_PAYMENTS
  )

  const qteSecCompleted = qteStatus === StepStatus.completed

  //todo
  // const readOnly = useReselector(selectTqIsReadOnly, form.id)
  const readOnly = false

  const unpaidEstimatesByTaxQuarter = useMemo(
    () => sortAndGroupEstimatesByTaxQuarter(unpaidEstimates),
    [unpaidEstimates]
  )
  const { updateProgressData, isFetching: isFetchingProgress } =
    useProgressSteps({
      steps: [
        ...(personalForm
          ? [SubStepIdentifiers.addMissingQTEPayments, ...UPLOAD_DOCUMENTS_1040]
          : []),
        ...(businessForm ? UPLOAD_DOCUMENTS_1120S : []),
      ],
    })

  const allRequiredDocsUploadedBusiness = useReselector(
    selectAllRequiredDocsUploaded,
    businessForm?.id
  )
  const uploadDocsProgressBusiness = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.uploadDocuments1120s
  )

  const qtePaymentsCompleted = useMemo(
    () => Object.keys(unpaidEstimates).length === 0,
    [unpaidEstimates]
  )

  useEffect(() => {
    const checkAndSetQTEProgress = async () => {
      if (qteProgress?.id) {
        if (!qtePaymentsCompleted && qteProgress.completedAt) {
          await updateProgressData({
            incompleteSteps: [SubStepIdentifiers.addMissingQTEPayments],
          })
        }
      }
    }
    checkAndSetQTEProgress()
  }, [updateProgressData, qtePaymentsCompleted, qteProgress])

  const allRequiredDocsUploadedPersonal = useReselector(
    selectAllRequiredDocsUploaded,
    personalForm?.id
  )

  const uploadDocsProgressPersonal = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.uploadDocuments1040
  )

  useEffect(() => {
    if (!uploadDocsProgressPersonal?.id) {
      return
    }

    if (
      allRequiredDocsUploadedPersonal &&
      !uploadDocsProgressPersonal.completedAt
    ) {
      updateProgressData({
        completedSteps: [SubStepIdentifiers.uploadDocuments1040],
      })
    }
    if (
      !allRequiredDocsUploadedPersonal &&
      uploadDocsProgressPersonal.completedAt
    ) {
      updateProgressData({
        incompleteSteps: [SubStepIdentifiers.uploadDocuments1040],
      })
    }
  }, [
    allRequiredDocsUploadedPersonal,
    updateProgressData,
    uploadDocsProgressPersonal?.completedAt,
    uploadDocsProgressPersonal?.id,
  ])

  const lifeChangesStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHANGES_IN_YEAR
  )
  const deductionsAndCreditsStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    DEDUCTIONS_AND_CREDITS
  )
  const incomeAndLiabilitiesStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    NON_THERAPY_INCOME_AND_LIABILITIES
  )

  const qteStepContent = useMemo(() => {
    if (qteSecCompleted) {
      return 'Thanks for updating your payments'
    }

    const unpaidQuarters = Object.keys(unpaidEstimatesByTaxQuarter)

    if (!unpaidQuarters.length) {
      return `Review and update your ${currentTaxYear} quarterly tax payments`
    }

    return (
      <>
        We are missing payments for the following quarters:
        {unpaidQuarters.map((taxQuarter) => (
          <li key={taxQuarter}>{formatTaxQuarter(taxQuarter)}</li>
        ))}
      </>
    )
  }, [currentTaxYear, qteSecCompleted, unpaidEstimatesByTaxQuarter])

  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, currentTaxYear)

  const aboutYourBusinessStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHECK_YOUR_DETAILS_1120S
  )

  const personalDetailsStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHECK_YOUR_DETAILS_1040
  )

  const docsSectionCountsPersonal = useReselector(
    selectDocsUploadedOrSkippedAndTotalCount,
    personalForm?.id
  )

  const docsSectionCountsBusiness = useReselector(
    selectDocsUploadedOrSkippedAndTotalCount,
    businessForm?.id
  )

  const uploadDocumentStatus = useMemo(() => {
    if (
      docsSectionCountsPersonal.completeCount ===
        docsSectionCountsPersonal.totalCount &&
      docsSectionCountsBusiness.completeCount ===
        docsSectionCountsBusiness.totalCount
    ) {
      return StepStatus.completed
    } else if (
      docsSectionCountsPersonal.completeCount > 0 ||
      docsSectionCountsBusiness.completeCount > 0
    ) {
      return StepStatus.inProgress
    }
    return StepStatus.notStarted
  }, [
    docsSectionCountsBusiness.completeCount,
    docsSectionCountsBusiness.totalCount,
    docsSectionCountsPersonal.completeCount,
    docsSectionCountsPersonal.totalCount,
  ])

  useEffect(() => {
    if (!uploadDocsProgressBusiness?.id) {
      return
    }

    if (
      allRequiredDocsUploadedBusiness &&
      !uploadDocsProgressBusiness.completedAt
    ) {
      updateProgressData({
        completedSteps: [SubStepIdentifiers.uploadDocuments1120s],
      })
    }
    if (
      !allRequiredDocsUploadedBusiness &&
      uploadDocsProgressBusiness.completedAt
    ) {
      updateProgressData({
        incompleteSteps: [SubStepIdentifiers.uploadDocuments1120s],
      })
    }
  }, [
    allRequiredDocsUploadedBusiness,
    updateProgressData,
    uploadDocsProgressBusiness,
  ])

  const { incompleteItems: newChecklistItems, completedItems } = useMemo(() => {
    const checklistItems = []

    if (personalForm) {
      checklistItems.push({
        loading: isFetchingProgress,
        title: qteSecCompleted
          ? 'Review quarterly tax payments'
          : 'Enter missing quarterly tax payments',
        key: 'Enter missing quarterly tax payments',
        status: qteStatus,
        path: TaxChecklistPaths.addMissingQtePayments,
        content: qteStepContent,
        formId: personalForm.id,
        completed: qteSecCompleted,
      })
    }

    if (businessForm) {
      checklistItems.push({
        title: 'About your business',
        key: 'About your business',
        content: readOnly
          ? 'Review your business information.'
          : 'Review and update your business information.',
        path: readOnly
          ? TaxChecklistPaths.checkYourDetailsReadOnly
          : TaxChecklistPaths.checkYourDetails,
        status: aboutYourBusinessStatus,
        formId: businessForm.id,
        completed: aboutYourBusinessStatus === StepStatus.completed,
      })
    }

    if (personalForm) {
      checklistItems.push(
        {
          title: 'Personal details',
          key: 'Personal details',
          content: 'Review and update your personal information.',
          path: readOnly
            ? TaxChecklistPaths.checkYourDetailsPersonalReadOnly
            : TaxChecklistPaths.checkYourDetailsPersonal,
          status: personalDetailsStatus,
          formId: personalForm.id,
          completed: personalDetailsStatus === StepStatus.completed,
        },
        {
          title: `Changes in ${currentTaxYear}`,
          key: `Changes in ${currentTaxYear}`,
          content: 'Fill us in on any big life changes.',
          path: readOnly
            ? TaxChecklistPaths.lifeChangesReadOnly
            : TaxChecklistPaths.lifeChanges,
          status: lifeChangesStatus,
          formId: personalForm.id,
          completed: lifeChangesStatus === StepStatus.completed,
        },
        {
          title: 'Deductions and credits',
          key: 'Deductions and credits',
          status: deductionsAndCreditsStatus,
          path: readOnly
            ? TaxChecklistPaths.deductionsAndCreditsReadOnly
            : TaxChecklistPaths.deductionsAndCredits,
          content:
            'Tell us about your personal expenses, and we’ll help you identify relevant deductions.',
          formId: personalForm.id,
          completed: deductionsAndCreditsStatus === StepStatus.completed,
        },
        {
          title: (
            <>
              Non-therapy income and liabilities
              <Popup
                content={
                  <>
                    <Text as="h3">Non-therapy income and liabilities</Text>
                    <br />
                    <Text as="bodyLg">
                      This refers to any income you earn outside of your private
                      practice (i.e. investments, other businesses, bank account
                      interest) as well as any personal financial situations
                      (i.e. retirement contributions, donating to charities,
                      etc.)
                    </Text>
                  </>
                }
              />
            </>
          ),
          key: 'Non-therapy income and liabilities',
          status: incomeAndLiabilitiesStatus,
          content: 'Report income outside of your therapy practice.',
          path: readOnly
            ? TaxChecklistPaths.incomeAndLiabilitiesReadOnly
            : TaxChecklistPaths.incomeAndLiabilities,
          formId: personalForm.id,
          completed: incomeAndLiabilitiesStatus === StepStatus.completed,
        }
      )
    }

    // todo business or personal for upload docs?
    const anyFormId = personalForm?.id || businessForm?.id

    if (anyFormId) {
      checklistItems.push({
        title: 'Upload documents',
        key: 'Upload documents',
        content:
          'A personalized list based upon your answers in the previous steps.',
        path: readOnly
          ? TaxChecklistPaths.documentsReadOnly
          : TaxChecklistPaths.documents,
        status: uploadDocumentStatus,
        formId: anyFormId,
        completed: uploadDocumentStatus === StepStatus.completed,
      })
    }

    if (personalForm && paymentRequired) {
      const paymentCompleted = paymentStatus === StepStatus.completed
      checklistItems.push({
        title: 'Complete payment',
        key: 'Complete payment',
        content: paymentCompleted
          ? 'Thank you for your payment.'
          : `Checkout with Stripe for the $${ADDITIONAL_1040_COST} fee of filing your personal tax return.`,
        status: paymentStatus,
        action: makePayment,
        loading: paymentLoading,
        disabled: paymentLoading || paymentCompleted || paymentPending,
        formId: personalForm.id,
        readOnly: paymentCompleted,
        completed: paymentCompleted,
      })
    }

    if (anyFormId) {
      const approveAndSend = {
        title: 'Share with tax preparer',
        key: 'Share with tax preparer',
        content: 'Send anwsers and documents to your tax preparer.',
        status: StepStatus.notStarted,
        action: () => setApproveAndSubmitOpen(true),
        formId: anyFormId,
        completed: false,
      }
      checklistItems.push(approveAndSend)
    }

    // split into completed and incomplete
    const partitionedItems = partition(checklistItems, (item) => item.completed)
    return {
      incompleteItems: partitionedItems[1],
      completedItems: partitionedItems[0],
    }
  }, [
    businessForm,
    aboutYourBusinessStatus,
    currentTaxYear,
    deductionsAndCreditsStatus,
    incomeAndLiabilitiesStatus,
    isFetchingProgress,
    lifeChangesStatus,
    makePayment,
    paymentLoading,
    paymentPending,
    paymentStatus,
    personalForm,
    qteSecCompleted,
    qteStatus,
    qteStepContent,
    readOnly,
    uploadDocumentStatus,
    paymentRequired,
    personalDetailsStatus,
  ])

  return (
    <Container variant="noShadow">
      <Grid>
        {/*todo extension alert*/}
        {!readOnly && <ExtensionAlert is1040 inChecklist />}

        {readOnly && (
          <GridRowColumn spacer={4} width={8}>
            <Text textAlign="center">
              You can no longer edit your responses, but you can still view
              answers for your Personal Tax Questionnaire and quarterly tax
              payments.
            </Text>
          </GridRowColumn>
        )}
        {!readOnly && !isTwoFormFiler && (
          <GridRowColumn spacer={1} width={14}>
            <BookkeeperReviewStatusNotice />
          </GridRowColumn>
        )}
        {newChecklistItems.map((item) => (
          <GridRowColumn short spacer={1} width={14} key={item.key}>
            <StepCard {...item} />
          </GridRowColumn>
        ))}
        {personalOptInEligible && (
          <GridRowColumn spacer={1} width={14}>
            <PersonalFilingOptInReminder />
          </GridRowColumn>
        )}
        {completedItems.length > 0 && (
          <>
            <GridRowColumn />
            <GridRowColumn spacer={1} width={14}>
              <Text as="h2">Completed</Text>
            </GridRowColumn>
          </>
        )}
        {completedItems.map((item) => (
          <GridRowColumn short spacer={1} width={14} key={item.key}>
            <StepCard {...item} />
          </GridRowColumn>
        ))}
      </Grid>
      <ApproveAndSubmitModal
        open={approveAndSubmitOpen}
        close={() => setApproveAndSubmitOpen(false)}
      />
    </Container>
  )
}

const TaxChecklistTitle = () => {
  const forms = useReselector(selectFilingFormsForCurrentTaxYear)

  const config = useMemo(() => {
    if (forms.length === 2) {
      return {
        image: 'https://heard-images.s3.amazonaws.com/assets/practice.png',
        title: 'Business and Personal Tax Questionnaire',
        subtitle: 'Preparation for Form 1120-S and Form 1040',
        description: (
          <>
            In order to prepare your tax return, we need additional information
            and documents from you. This checklist takes about{' '}
            <b>30 minutes.</b>
          </>
        ),
      }
    }

    if (
      forms.some(
        (form) =>
          form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
      )
    ) {
      return {
        image: 'https://heard-images.s3.amazonaws.com/assets/bank-shrubs.svg',
        title: 'Business Tax Questionnaire',
        subtitle: 'Preparation for Form 1120-S',
        description: (
          <>
            In order to prepare your tax return, we need additional information
            and documents from you. This checklist takes about{' '}
            <b>30 minutes.</b>
          </>
        ),
      }
    }

    return {
      image: 'https://heard-images.s3.amazonaws.com/assets/home.svg',
      title: 'Personal Tax Questionnaire',
      subtitle: 'Preparation for Form 1040',
      description: (
        <>
          In order to prepare your tax return, we need additional information
          and documents from you. This checklist takes about <b>60 minutes.</b>
        </>
      ),
    }
  }, [forms])

  if (forms.length === 0) {
    return null
  }

  return (
    <>
      <GridRowColumn spacer={4} width={8} centerContent>
        <Image style={{ width: 300, height: 300 }} src={config.image} />
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8} centerContent>
        <Text as="display2" textAlign="center">
          {config.title}
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8} short>
        <Text as="h2" textAlign="center">
          {config.subtitle}
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8} short>
        <Text>{config.description}</Text>
      </GridRowColumn>
    </>
  )
}

const TaxChecklist = () => {
  const dispatch = useAppDispatch()

  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  ])
  const error = useReselector(selectErrorsForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  // const readOnly = useReselector(selectTqIsReadOnly, formId)
  // todo readonly states
  const readOnly = false

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchUserTaxEstimatesIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (currentTaxYear) {
      dispatch(fetchUserEoyReviewProgress(currentTaxYear))
      dispatch(fetchUserTaxQuestionnaire(currentTaxYear))
    }
  }, [dispatch, currentTaxYear])

  //todo
  const deadlineIsMarch = true

  return (
    <>
      <PageHeader
        header=""
        backControl={{ link: '/taxes/annual', text: 'Back to Tax Center' }}
      />
      <Grid>
        {isLoading && (
          <GridRowColumn>
            <Loader loading={isLoading} />
          </GridRowColumn>
        )}
        {!isLoading && error.length > 0 && (
          <GridRowColumn>
            <Alert type="error">Something went wrong</Alert>
          </GridRowColumn>
        )}
        <TaxChecklistTitle />
        <GridRowColumn>
          <CombinedChecklist />
        </GridRowColumn>
        <Grid.Row />
        {!readOnly && (
          <GridRowColumn spacer={4} width={8}>
            <Accordion
              title="What do I need to complete this checklist?"
              content={
                <Text>
                  <ul style={{ paddingLeft: 10, marginTop: 0 }}>
                    <li>Access to all business bank accounts</li>
                    <li>All the tax forms you’ve received so far</li>
                    <li>
                      Additional details around your business expenses and
                      owner’s investments
                    </li>
                  </ul>
                </Text>
              }
              variant="default"
            />
            <Accordion
              title="Is my progress saved?"
              content="Yes, your progress is automatically saved, so feel free to exit and come back."
              variant="default"
            />
            <Accordion
              title="What if I don’t know how to answer something?"
              content="If you have questions or feel stuck, please reach out to the Heard Tax Team via Conversations— they’ll help you move forward."
              variant="default"
            />
            <Accordion
              title="What if I don’t have a form or document?"
              content={
                <Text>
                  In order to move through your tax return without any pauses or
                  hiccups, we require all tax documents to be uploaded. If you
                  need help locating a document, please reach out to the Heard
                  Tax Team or check out our{' '}
                  <Link
                    href="https://support.joinheard.com/hc/en-us/articles/19233040314775-What-tax-documents-should-I-be-looking-out-for-And-when-"
                    newPage
                  >
                    Tax Document FAQs
                  </Link>
                  .
                  <br />
                  <br />
                  You can come back later once you’ve located all the necessary
                  documents. If you haven’t completed your checklist by{' '}
                  <b>
                    {deadlineIsMarch ? 'March' : 'April'} 1,{' '}
                    {Number(currentTaxYear) + 1}
                  </b>
                  , we’ll automatically file an extension for you.
                  <Popup
                    content={
                      <>
                        <Text as="h3">Extension Filing</Text>
                        <Text as="bodyLg">
                          The reason we put customers (who are late on
                          completing the tax questionnaire) on extensions is to
                          prevent them from paying penalties for late filings.
                          <br />
                          <br />
                          There’s nothing bad about filing an extension for your
                          tax return. It simply postpones the process until you
                          have all of your information gathered. However, you’ll
                          still need to pay an estimated tax amount before the
                          filing deadline to avoid a penalty.
                        </Text>
                      </>
                    }
                  />
                </Text>
              }
              variant="default"
            />
          </GridRowColumn>
        )}
        <Grid.Row />
        <Grid.Row />
      </Grid>
    </>
  )
}

export default TaxChecklist
