import axios from 'axios'
import { fetchWrapper } from '../../../reducers/fetch'

interface BookkeepingStep {
  id: number
  step: string
  status: string
  context: Record<string, unknown>
}

/**
 * Fetches the YE Bookkeeping step, including the step context.
 * Step context is used to store the state of the step and is
 * currently untyped. This may change in future updates.
 *
 * @param stepId the backend identifier for the step
 * @returns step
 */
export const userGetBookkeepingStep = (stepId: string) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching step context.',
    fetchFunction: (_) =>
      axios
        .get<BookkeepingStep>(`/finances/api/v1/bookkeeping/year-end/${stepId}`)
        .then((json) => json.data),
  })

/**
 * Updates the YE Bookkeeping step context for the fromStep and
 * updates the last working on in YE Home to the toStep.
 *
 * @param fromStepId the backend identifier for the step origin
 * @param toStepId the backend identifier for the step target
 * @param context the fromStep context to be updated
 * @returns true if the context was updated successfully
 */
export const userUpdateBookkeepingStepContext = (
  fromStepId: string,
  toStepId: string,
  context: Record<string, unknown>
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating step context.',
    defaultValue: false,
    fetchFunction: (_) =>
      axios
        .post(`/finances/api/v1/bookkeeping/year-end/${fromStepId}`, {
          toStep: toStepId,
          context: context ? context : undefined,
        })
        .then(() => true),
  })

/**
 * Updates the status of the appropriate YE Bookkeeping module
 * to complete. The backend will determine which module to mark
 * as complete based on the state of the user's bookkeeping.
 *
 * @returns true if the books were submitted successfully
 */
export const userSubmitBookkeepingModule = () =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error submitting your books.',
    defaultValue: false,
    fetchFunction: (_) =>
      axios.post('/finances/api/v1/bookkeeping/year-end').then(() => true),
  })
