import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import { FORM_1120_S_SCREENS, Form1120sProps } from '.'
import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectTaxListQuestionResponsesByQuestionIds } from '../taxChecklist.selectors'
import ReviewSubSection from '../Shared/ReviewSubSection'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import {
  getBusinessQuestionIds,
  getPersonalQuestionIds,
} from '../Shared/PersonalDetails/CheckYourDetailsPanel'
import { updateDetailsQuestionsIds } from './UpdateDetailsPanel'
import { uncommonScenariosQuestionIds } from './UncommonScenariosPanel'
import { uncommonScenarioFollowUpQuestionIds } from './UncommonScenariosContinuedPanel'
import { TAX_ENTITY_TYPES } from '../../../taxConstants'
import { TaxListQuestionId } from '../service'
import { useNavigateWithLocation } from '../../../../../utils/routeHelpers'
import { selectTqIsReadOnly } from '../../annualTaxFilings.selector'

const Form1120SReviewPanel = ({ goBack, previousScreen }: Form1120sProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const navigate = useNavigateWithLocation()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqIsReadOnly, formId)
  const uncommonScenariosResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    uncommonScenariosQuestionIds.filter(
      (qId) =>
        qId === TaxListQuestionId.sold_fixed_assets ||
        qId === TaxListQuestionId.engaged_in_real_estate ||
        qId === TaxListQuestionId.scorp_owned_other_businesses
    ),
    Number(formId)
  )
  const showFollowScenarios = useMemo(() => {
    return uncommonScenariosResponses.some(
      (response) => response.value === true
    )
  }, [uncommonScenariosResponses])

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            Review your answers for accuracy. Click edit to go back to the
            previous section.
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            You can no longer edit your Business Tax Questionnaire responses,
            but you can view them at anytime.
          </Text>
        </GridRowColumn>
      )}
      <Grid.Row />
      <ReviewSubSection
        title="confirm personal details"
        questionIds={getPersonalQuestionIds(TAX_ENTITY_TYPES.form_1120_s)}
        editScreen={FORM_1120_S_SCREENS.confirmDetails}
      />
      <ReviewSubSection
        title="confirm business details"
        questionIds={getBusinessQuestionIds(TAX_ENTITY_TYPES.form_1120_s)}
        editScreen={FORM_1120_S_SCREENS.confirmDetails}
      />
      <ReviewSubSection
        title={`update details for ${taxYear}`}
        questionIds={updateDetailsQuestionsIds}
        editScreen={FORM_1120_S_SCREENS.updateDetails}
      />
      <ReviewSubSection
        title="Less common scenarios"
        questionIds={uncommonScenariosQuestionIds}
        editScreen={FORM_1120_S_SCREENS.uncommonScenarios}
      />
      {showFollowScenarios && (
        <ReviewSubSection
          title="Less common scenarios follow-up"
          questionIds={uncommonScenarioFollowUpQuestionIds}
          editScreen={FORM_1120_S_SCREENS.uncommonScenariosContinued}
        />
      )}
      {!readOnly && (
        <FormFlowFooter
          isSubmit
          onBack={() =>
            goBack(
              showFollowScenarios
                ? (previousScreen ?? null)
                : FORM_1120_S_SCREENS.uncommonScenarios
            )
          }
          onForward={() => navigate('/taxes/annual/tax_checklist/')}
        />
      )}
    </Grid>
  )
}

export default Form1120SReviewPanel
