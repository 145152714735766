import {
  EOYBookkeepingSubstep,
  EOYBookkeepingSubstepId,
  TQSubstep,
  TQSubstepId,
  YearEndModuleStatusOptions,
  YearEndModuleSubstepId,
  YearEndModuleType,
} from './yearEndModuleStatus.slice'

type LastWorkedOnSubsteps = EOYBookkeepingSubstepId | TQSubstepId
const lastWorkedOnSubsteps: LastWorkedOnSubsteps[] = [
  ...Object.values(EOYBookkeepingSubstep),
  ...Object.values(TQSubstep),
]

const lastWorkedOnSubstepsMap: Record<
  LastWorkedOnSubsteps,
  string | ((taxYear?: string) => string)
> = {
  [EOYBookkeepingSubstep.disconnectedAccounts]: 'Review disconnected accounts',
  [EOYBookkeepingSubstep.uploadMissingStatements]:
    'Upload missing bank statements',
  [EOYBookkeepingSubstep.unclarifiedTransactions]:
    'Review “Uncategorized” transactions',
  [EOYBookkeepingSubstep.otherIncome]: 'Review “Other Income” transactions',
  [EOYBookkeepingSubstep.otherExpenses]: 'Review “Other Expenses” transactions',
  [EOYBookkeepingSubstep.personalAccountBusinessIncome]:
    'Business income in personal accounts',
  [EOYBookkeepingSubstep.personalAccountBusinessExpense]:
    'Business expenses in personal accounts',
  [EOYBookkeepingSubstep.ownerInvestments]: 'Review owner’s investments',
  [EOYBookkeepingSubstep.ownerDistributions]: 'Review owner’s distributions',
  [EOYBookkeepingSubstep.yearEndDocs]: 'Upload year-end documents',
  [EOYBookkeepingSubstep.initialSubmissionReview]: 'Review and submit',
  [TQSubstep.checkYourDetails]: 'Check your details',
  [TQSubstep.tqBigChanges]: (taxYear) => {
    return taxYear ? `Changes in ${taxYear}` : 'Changes this tax year'
  },
  [TQSubstep.tqDeductionsCredits]: 'Deductions and Credits',
  [TQSubstep.tqIncomeLiabilities]: 'Non-therapy income and liabilities',
  [TQSubstep.tqUploadDocuments]: 'Upload documents',
  [TQSubstep.tqAboutYourBusiness]: 'About your business',
  [TQSubstep.tqMissingQtePayments]: 'Missing QTE payments',
}

export const getSubstepIdentifierLastWorkedOnCopy = (
  status: YearEndModuleStatusOptions,
  substepIdentifier: YearEndModuleSubstepId | null | undefined,
  taxYear?: string
): string | null => {
  const id = substepIdentifier as LastWorkedOnSubsteps
  if (
    status === YearEndModuleStatusOptions.upcoming ||
    !substepIdentifier ||
    !lastWorkedOnSubsteps.includes(id)
  ) {
    return null
  }
  const copy = lastWorkedOnSubstepsMap[id]
  if (typeof copy === 'function') {
    return copy(taxYear)
  }
  return copy
}

export const getModuleNameCopy = (moduleType: YearEndModuleType) => {
  switch (moduleType) {
    case YearEndModuleType.verifyTaxEntity:
      return 'Verify tax entity status'
    case YearEndModuleType.taxQuestionnaire:
      return 'Complete Tax Questionnaire'
    case YearEndModuleType.fileExtensionRequest:
      return 'File Extension Request'
    case YearEndModuleType.submitExtensionRequest:
      return 'Submit extension request'
    case YearEndModuleType.eoyBookkeeping:
      return 'Complete Year-End Bookkeeping'
    case YearEndModuleType.file1099Nec:
      return 'File 1099-NEC'
    case YearEndModuleType.eoyBookkeepingFollowup:
      return 'Year-End Bookkeeping Follow-up'
    case YearEndModuleType.file1120S:
      return 'File Business Tax Return (Form 1120-S)'
    case YearEndModuleType.file1040:
      return 'File Personal Tax Return (Form 1040)'
    default:
      return moduleType satisfies never
  }
}
