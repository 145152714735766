import { AnySchema } from 'yup'

import { FormikCheckboxMulti } from '../../../../../components/BaseComponents'
import { TaxListQuestionId } from '../service'
import { selectTaxListQuestionsByIds } from '../taxChecklist.selectors'
import { useReselector } from '../../../../../utils/sharedHooks'
import { filterNulls } from '../../../../../utils/typeHelpers'

export const skipQuestionId = 'TQ_SKIP_QUESTION'

export const useSkipQuestion = (questionIds: TaxListQuestionId[]) => {
  const questions = useReselector(selectTaxListQuestionsByIds, questionIds)

  return {
    initialValue: filterNulls(
      questions
        .filter((q) => q.responses?.[0]?.needsAssistance)
        .map((q) => q.question?.id)
    ),
  }
}

export const createSkipSchema = <T extends AnySchema>(
  values: {
    [skipQuestionId]: TaxListQuestionId[]
  } & Record<string, string | number | boolean | string[] | undefined>,
  questionId: TaxListQuestionId,
  schema: T
) => (!values[skipQuestionId].includes(questionId) ? schema : undefined)

const SkipQuestion = ({ questionId }: { questionId: TaxListQuestionId }) => (
  <FormikCheckboxMulti
    name={skipQuestionId}
    label="Unsure, have tax preparer folow-up"
    value={questionId}
    variant="default"
  />
)

export default SkipQuestion
