import { List } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import { DateTime } from 'luxon'

import {
  createSingleAnnualTaxDetail,
  CREATE_SINGLE_ANNUAL_TAX_DETAIL_KEY,
  AnnualTaxDetail,
  updateSingleAnnualTaxDetail,
  UPDATE_SINGLE_ANNUAL_TAX_DETAIL_KEY,
} from '../annualTaxDetails.slice'
import { getFetchError } from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  Button,
  Alert,
  Text,
  FormikDateInput,
  FormikInput,
  getFieldName,
} from '../../../../components/BaseComponents'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'

interface Props {
  details?: AnnualTaxDetail
  onCancel: () => void
}

type DetailsPayload = Omit<
  AnnualTaxDetail,
  'status' | 'createdAt' | 'updatedAt' | 'modifiedBy'
>
const inputToStartIso = (val: string | undefined) =>
  val
    ? DateTime.fromFormat(val, DATE_FORMATS_LUXON.INPUT)
        .toUTC()
        .startOf('day')
        .toISO() || ''
    : ''

const AnnualTaxDetailsForm = ({ onCancel, details }: Props) => {
  const dispatch = useAppDispatch()

  const hasCreateErrored = useReselector(
    getFetchError,
    CREATE_SINGLE_ANNUAL_TAX_DETAIL_KEY
  )
  const hasUpdateErrored = useReselector(
    getFetchError,
    UPDATE_SINGLE_ANNUAL_TAX_DETAIL_KEY + details?.taxYear
  )

  const currentUser = useReselector(getCurrentUser)

  const initialValues: DetailsPayload = {
    taxYear: details?.taxYear || '',
    newUserCutOffAt: formatISOFromUTC(details?.newUserCutOffAt),
    financialOverviewDueAt: formatISOFromUTC(details?.financialOverviewDueAt),
    taxSeasonKickoffStartAt: formatISOFromUTC(details?.taxSeasonKickoffStartAt),
    taxSeasonKickoffDueAt: formatISOFromUTC(details?.taxSeasonKickoffDueAt),
    ten99NECFormsStartAt: formatISOFromUTC(details?.ten99NECFormsStartAt),
    ten99NECFormsDueAt: formatISOFromUTC(details?.ten99NECFormsDueAt),
    extensionSurveyStartAt: formatISOFromUTC(details?.extensionSurveyStartAt),

    irsFormDueDates: {
      form_1040: {
        irs: {
          dueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1040.irs.dueDate
          ),
          extendedDueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1040.irs.extendedDueDate
          ),
          extensionFileDueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1040.irs.extensionFileDueDate
          ),
        },
        internal: {
          dueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1040.internal.dueDate
          ),
          extendedDueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1040.internal.extendedDueDate
          ),
          extensionRequestCutoffDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1040.internal
              .extensionRequestCutoffDate
          ),
        },
      },
      form_1120_s: {
        irs: {
          dueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1120_s.irs.dueDate
          ),
          extendedDueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1120_s.irs.extendedDueDate
          ),
          extensionFileDueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1120_s.irs.extensionFileDueDate
          ),
        },
        internal: {
          dueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1120_s.internal.dueDate
          ),
          extendedDueDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1120_s.internal.extendedDueDate
          ),
          extensionRequestCutoffDate: formatISOFromUTC(
            details?.irsFormDueDates.form_1120_s.internal
              .extensionRequestCutoffDate
          ),
        },
      },
    },

    taxQuestionnaireDueDates: {
      form_1040: {
        endAt: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1040.endAt
        ),
        startAt: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1040.startAt
        ),
        cutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1040.cutoffDate
        ),
        lateJoinerOnTimeCutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1040
            .lateJoinerOnTimeCutoffDate
        ),
        lateJoinerFinalCutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1040.lateJoinerFinalCutoffDate
        ),
        lateJoinerAutomaticExtensionCutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1040
            .lateJoinerAutomaticExtensionCutoffDate
        ),
      },
      form_1120_s: {
        endAt: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1120_s.endAt
        ),
        startAt: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1120_s.startAt
        ),
        cutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1120_s.cutoffDate
        ),
        lateJoinerOnTimeCutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1120_s
            .lateJoinerOnTimeCutoffDate
        ),
        lateJoinerFinalCutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1120_s
            .lateJoinerFinalCutoffDate
        ),
        lateJoinerAutomaticExtensionCutoffDate: formatISOFromUTC(
          details?.taxQuestionnaireDueDates?.form_1120_s
            .lateJoinerAutomaticExtensionCutoffDate
        ),
      },
    },
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const dataContents = {
        ...values,
        newUserCutOffAt: inputToStartIso(values.newUserCutOffAt),
        taxSeasonKickoffStartAt: inputToStartIso(
          values.taxSeasonKickoffStartAt
        ),
        taxSeasonKickoffDueAt: inputToStartIso(values.taxSeasonKickoffDueAt),
        ten99NECFormsStartAt: inputToStartIso(values.ten99NECFormsStartAt),
        ten99NECFormsDueAt: inputToStartIso(values.ten99NECFormsDueAt),
        financialOverviewDueAt: inputToStartIso(values.financialOverviewDueAt),
        extensionSurveyStartAt: inputToStartIso(values.extensionSurveyStartAt),
        irsFormDueDates: {
          form_1040: {
            irs: {
              dueDate: inputToStartIso(
                values.irsFormDueDates.form_1040.irs.dueDate
              ),
              extendedDueDate: inputToStartIso(
                values.irsFormDueDates.form_1040.irs.extendedDueDate
              ),
              extensionFileDueDate: inputToStartIso(
                values.irsFormDueDates.form_1040.irs.extensionFileDueDate
              ),
            },
            internal: {
              dueDate: inputToStartIso(
                values.irsFormDueDates.form_1040.internal.dueDate
              ),
              extendedDueDate: inputToStartIso(
                values.irsFormDueDates.form_1040.internal.extendedDueDate
              ),
              extensionRequestCutoffDate: inputToStartIso(
                values.irsFormDueDates.form_1040.internal
                  .extensionRequestCutoffDate
              ),
            },
          },
          form_1120_s: {
            irs: {
              dueDate: inputToStartIso(
                values.irsFormDueDates.form_1120_s.irs.dueDate
              ),
              extendedDueDate: inputToStartIso(
                values.irsFormDueDates.form_1120_s.irs.extendedDueDate
              ),
              extensionFileDueDate: inputToStartIso(
                values.irsFormDueDates.form_1120_s.irs.extensionFileDueDate
              ),
            },
            internal: {
              dueDate: inputToStartIso(
                values.irsFormDueDates.form_1120_s.internal.dueDate
              ),
              extendedDueDate: inputToStartIso(
                values.irsFormDueDates.form_1120_s.internal.extendedDueDate
              ),
              extensionRequestCutoffDate: inputToStartIso(
                values.irsFormDueDates.form_1120_s.internal
                  .extensionRequestCutoffDate
              ),
            },
          },
        },
        taxQuestionnaireDueDates: {
          form_1040: {
            endAt: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1040.endAt
            ),
            startAt: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1040.startAt
            ),
            cutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1040.cutoffDate
            ),
            lateJoinerOnTimeCutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1040
                .lateJoinerOnTimeCutoffDate
            ),
            lateJoinerFinalCutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1040
                .lateJoinerFinalCutoffDate
            ),
            lateJoinerAutomaticExtensionCutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1040
                .lateJoinerAutomaticExtensionCutoffDate
            ),
          },
          form_1120_s: {
            endAt: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1120_s.endAt
            ),
            startAt: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1120_s.startAt
            ),
            cutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1120_s.cutoffDate
            ),
            lateJoinerOnTimeCutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1120_s
                .lateJoinerOnTimeCutoffDate
            ),
            lateJoinerFinalCutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1120_s
                .lateJoinerFinalCutoffDate
            ),
            lateJoinerAutomaticExtensionCutoffDate: inputToStartIso(
              values.taxQuestionnaireDueDates?.form_1120_s
                .lateJoinerAutomaticExtensionCutoffDate
            ),
          },
        },
        updatedAt: new Date().toISOString(),
        modifiedBy: currentUser?.id,
      }

      let data
      if (details) {
        data = await updateSingleAnnualTaxDetail(
          details.taxYear,
          dataContents
        )(dispatch)
      } else {
        data = await createSingleAnnualTaxDetail(dataContents)(dispatch)
      }

      if (data) {
        onCancel()
      }
    },
  })

  const { submitForm, isSubmitting } = formik

  const error = hasCreateErrored || hasUpdateErrored

  const dateInputListItems = () =>
    Object.entries({
      newUserCutOffAt: 'New User Cut-off Date',
      taxSeasonKickoffStartAt: 'Tax Season Kick off start at',
      taxSeasonKickoffDueAt: 'Tax Season Kick off due at',
      ten99NECFormsStartAt: '1099 NEC forms start at',
      ten99NECFormsDueAt: '1099 NEC forms due at',
      financialOverviewDueAt: 'Financial Overview Due',
      extensionSurveyStartAt: 'Extension Survey Start',
      'irsFormDueDates.form_1040.irs.dueDate': 'IRS form 1040 due date',
      'irsFormDueDates.form_1040.irs.extendedDueDate':
        'IRS form 1040 extended due date',
      'irsFormDueDates.form_1040.irs.extensionFileDueDate':
        'IRS form 1040 extension file due date',
      'irsFormDueDates.form_1040.internal.dueDate':
        'Internal form 1040 due date',
      'irsFormDueDates.form_1040.internal.extendedDueDate':
        'Internal form 1040 extended due date',
      'irsFormDueDates.form_1040.internal.extensionRequestCutoffDate':
        'Internal form 1040 extension request cut off date',

      'irsFormDueDates.form_1120_s.irs.dueDate': 'IRS form 1120s due date',
      'irsFormDueDates.form_1120_s.irs.extendedDueDate':
        'IRS form 1120s extended due date',
      'irsFormDueDates.form_1120_s.irs.extensionFileDueDate':
        'IRS form 1120s extension file due date',
      'irsFormDueDates.form_1120_s.internal.dueDate':
        'Internal form 1120s due date',
      'irsFormDueDates.form_1120_s.internal.extendedDueDate':
        'Internal form 1120s extended due date',
      'irsFormDueDates.form_1120_s.internal.extensionRequestCutoffDate':
        'Internal form 1120s extension request cut off date',

      'taxQuestionnaireDueDates.form_1040.endAt': 'TQ form 1040 end at',
      'taxQuestionnaireDueDates.form_1040.startAt': 'TQ form 1040 start at',
      'taxQuestionnaireDueDates.form_1040.cutoffDate':
        'TQ form 1040 cut off at',
      'taxQuestionnaireDueDates.form_1040.lateJoinerOnTimeCutoffDate':
        'TQ Late joiner on time cutoff',
      'taxQuestionnaireDueDates.form_1040.lateJoinerFinalCutoffDate':
        'TQ Late joiner final cutoff',
      'taxQuestionnaireDueDates.form_1040.lateJoinerAutomaticExtensionCutoffDate':
        'TQ Late joiner extended cutoff',

      'taxQuestionnaireDueDates.form_1120_s.endAt': 'TQ form 1120s end at',
      'taxQuestionnaireDueDates.form_1120_s.startAt': 'TQ form 1120s start at',
      'taxQuestionnaireDueDates.form_1120_s.cutoffDate':
        'TQ form 1120s cut off at',
      'taxQuestionnaireDueDates.form_1120_s.lateJoinerOnTimeCutoffDate':
        'TQ Late joiner on time cutoff',
      'taxQuestionnaireDueDates.form_1120_s.lateJoinerFinalCutoffDate':
        'TQ Late joiner final cutoff',
      'taxQuestionnaireDueDates.form_1120_s.lateJoinerAutomaticExtensionCutoffDate':
        'TQ Late joiner extended cutoff',
    }).map(([name, val]) => (
      <List.Item key={`dateInputListItems-${name}`}>
        <List.Content floated="right" style={{ width: '33%' }}>
          <FormikDateInput
            dateFormat={DATE_FORMATS.INPUT}
            name={name}
            required
          />
        </List.Content>
        <List.Header>{val}</List.Header>
      </List.Item>
    ))

  return (
    <FormikProvider value={formik}>
      <List relaxed="very" divided verticalAlign="middle">
        <List.Item>
          <List.Content floated="right">
            <FormikInput
              name={getFieldName<DetailsPayload>('taxYear')}
              required
            />
          </List.Content>
          <List.Header>Tax Year, e.g. 2023</List.Header>
        </List.Item>
        {dateInputListItems()}
      </List>

      {error && (
        <Alert type="error">
          <Text>{error.message}</Text>
        </Alert>
      )}
      <Button onClick={onCancel} variant="secondary" floated="left">
        Cancel
      </Button>
      <Button
        disabled={isSubmitting}
        loading={isSubmitting}
        onClick={submitForm}
        floated="right"
      >
        Save
      </Button>
    </FormikProvider>
  )
}

export default AnnualTaxDetailsForm
