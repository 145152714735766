import { ReactNode, useEffect, useMemo } from 'react'
import { Grid, Image, Loader } from 'semantic-ui-react'
import {
  AnnualTaxFilingForm,
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
  fetchTaxFyleJobUrl,
} from '../annualTaxFilingForms.slice'
import {
  FormFilingStatusDetailedStep,
  TaxChecklistSectionStatusStep,
  selectCurrentDetailedStepForForm,
} from './statusCard.selectors'
import {
  Button,
  Card,
  Icon,
  Label,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  useFetchResponseOnInterval,
  useReselector,
} from '../../../../utils/sharedHooks'
import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { selectIsTwoFormFiler } from '../annualTaxFilingForms.selector'
import { selectSubscriptionIncludesFreePersonalFiling } from '../../../../reducers/subscription.slice'
import { selectCloseBooksSectionStepsCompleteAndTotalCount } from '../TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import {
  selectApproveAndSubmitItemCompleteAndTotalCount,
  selectDocsUploadedOrSkippedAndTotalCount,
  selectTaxQuestionnaireSectionsCompleteAndTotalCount,
} from '../TaxChecklist/taxChecklist.selectors'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../UserDocuments/userDocuments.slice'
import { FETCH_TAX_ESTIMATES_KEY } from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import { fetchAllEoyReviewStepsIfNeeded } from '../TaxChecklist/Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import {
  FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
  fetchUserEoyReviewProgress,
} from '../TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectIsFetchingForKeys } from '../../../../reducers/fetch'
import { FETCH_TAX_FILINGS_KEY } from '../annualTaxFilings.slice'
import { useNavigate } from 'react-router-dom'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import {
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  fetchUserTaxQuestionnaire,
} from '../TaxChecklist/taxChecklistQuestion.actions'
import {
  FETCH_ANNUAL_TAX_OUTCOMES_KEY,
  fetchAnnualTaxOutcomesIfNeeded,
} from '../annualTaxOutcome.slice'
import {
  FETCH_TAX_USER_DOCUMENTS_KEY,
  fetchTaxUserDocumentsIfNeeded,
} from '../taxUserDocuments.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const enum ActionLinkType {
  checklistActionLink = 'checklistActionLink',
  viewTaxCenterActionLink = 'viewTaxCenterActionLink',
  respondActionLink = 'respondActionLink',
  reviewActionLink = 'reviewActionLink',
}

type ActionLinkParams = {
  actionLink: ActionLinkType
  formId: number
}

const ExternalLink = ({ actionLink, formId }: ActionLinkParams) => {
  // Taxfyle links expire after 5 minutes, so refresh every 4 minutes
  const linkRes = useFetchResponseOnInterval(fetchTaxFyleJobUrl, 240000, formId)
  const url = linkRes?.jobUrl ?? undefined
  const linkText =
    actionLink === ActionLinkType.respondActionLink ? 'Respond' : 'Review'
  if (!url) {
    return <Loader inline size="small" active />
  }
  return (
    <div>
      <Link href={url} size="small">
        {linkText}
        <Icon icon={light('arrow-right')} style={{ marginLeft: 5 }} />
      </Link>
    </div>
  )
}
const InternalLink = ({ actionLink }: ActionLinkParams) => {
  const url =
    actionLink === ActionLinkType.checklistActionLink
      ? '/taxes/annual/tax_checklist/'
      : '/taxes/annual'
  const linkText =
    actionLink === ActionLinkType.checklistActionLink
      ? 'View Checklist'
      : 'View Tax Center'
  return (
    <div>
      <Link to={url} size="small">
        {linkText}
        <Icon icon={light('arrow-right')} style={{ marginLeft: 5 }} />
      </Link>
    </div>
  )
}

const ActionLinkForDashboard = (params: ActionLinkParams) => {
  if (
    [
      ActionLinkType.checklistActionLink,
      ActionLinkType.viewTaxCenterActionLink,
    ].includes(params.actionLink)
  ) {
    return <InternalLink {...params} />
  }
  return <ExternalLink {...params} />
}

const DetailedFormStatusItem = ({
  form,
  forDashboard,
}: {
  form: AnnualTaxFilingForm
  forDashboard?: boolean
}) => {
  const dispatch = useAppDispatch()

  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, form.year)

  const isNewScorpSubscription2023 = useReselector(
    selectSubscriptionIncludesFreePersonalFiling
  )
  const booksSection = useReselector(
    selectCloseBooksSectionStepsCompleteAndTotalCount,
    form.year
  )
  const tqSection = useReselector(
    selectTaxQuestionnaireSectionsCompleteAndTotalCount,
    form.id
  )
  const docsSection = useReselector(
    selectDocsUploadedOrSkippedAndTotalCount,
    form.id
  )
  const approveSubmitSection = useReselector(
    selectApproveAndSubmitItemCompleteAndTotalCount,
    form.year,
    form.id
  )
  const step = useReselector(
    selectCurrentDetailedStepForForm,
    form.year,
    form.id
  )

  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_TAX_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
    FETCH_USER_TAX_QUESTIONNAIRE_KEY(form.year),
    FETCH_ANNUAL_TAX_OUTCOMES_KEY,
  ])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (form.year) {
      dispatch(fetchUserEoyReviewProgress(form.year))
      dispatch(fetchUserTaxQuestionnaire(form.year))
    }
  }, [dispatch, form.year])

  useEffect(() => {
    fetchAnnualTaxOutcomesIfNeeded(form?.id)
  }, [form?.id])

  const statusDetails = useMemo((): {
    needsAction: boolean
    formTypeText: string
    stepName: string
    description: ReactNode
    actionLink: ActionLinkType
  } | null => {
    const formTypeTextPersonal = 'Personal Taxes'
    const formTypeTextBusiness = 'Business Taxes'
    const formIs1040 = form.formType.name === TAX_ENTITY_TYPES.form_1040
    const formTypeText = formIs1040
      ? formTypeTextPersonal
      : formTypeTextBusiness

    switch (step) {
      case TaxChecklistSectionStatusStep.enter_qte_payments:
        return {
          needsAction: true,
          formTypeText: formTypeTextPersonal,
          stepName: 'Enter Quarterly Tax Payments',
          description: 'Enter any missing quarterly payments.',
          actionLink: ActionLinkType.checklistActionLink,
        }
      case TaxChecklistSectionStatusStep.close_your_books:
        return {
          needsAction: true,
          formTypeText,
          stepName: 'Close Your Books',
          description: `${booksSection.completeCount}/${booksSection.totalCount} tasks complete.`,
          actionLink: ActionLinkType.checklistActionLink,
        }
      case TaxChecklistSectionStatusStep.continue_tax_questionnaire:
        return {
          needsAction: true,
          formTypeText,
          stepName: 'Continue Tax Questionnaire',
          description: `${tqSection.completeCount}/${tqSection.totalCount} tasks complete.`,
          actionLink: ActionLinkType.checklistActionLink,
        }
      case TaxChecklistSectionStatusStep.upload_documents: {
        const commonText = `You’ve uploaded ${docsSection.completeCount}/${docsSection.totalCount} documents. Continue uploading documents or let us know if you don’t have them.`
        return {
          needsAction: true,
          formTypeText,
          stepName: 'Upload Documents',
          description:
            formIs1040 && isTwoFormFiler ? (
              <>
                <Text as="bodySm">{commonText}</Text>
                <br />
                <Text as="bodySm">
                  Be on the lookout for your Schedule K-1, which you’ll get
                  after you complete your 1120-S filing. It’ll soon be available
                  under the “Taxes” tab of Your Documents page.
                </Text>
              </>
            ) : (
              commonText
            ),
          actionLink: ActionLinkType.checklistActionLink,
        }
      }
      case TaxChecklistSectionStatusStep.bookkeeper_review:
        return {
          needsAction: false,
          formTypeText,
          stepName: 'Bookkeeper Review',
          description:
            'Your books are under review. We’ll let you know when it’s time to come back and approve changes.',
          actionLink: ActionLinkType.checklistActionLink,
        }
      case TaxChecklistSectionStatusStep.approve_and_submit: {
        const needsPayment =
          !isNewScorpSubscription2023 && isTwoFormFiler && formIs1040
        const commonText = `${approveSubmitSection.completeCount}/${approveSubmitSection.totalCount} tasks complete`
        return {
          needsAction: true,
          formTypeText,
          stepName: `${needsPayment ? 'Pay, ' : ''}Approve and Submit`,
          description: needsPayment ? (
            <Text as="bodySm">
              {commonText}. Pay prep fee and approve and submit your checklist
              to your tax preparer.
            </Text>
          ) : (
            <Text as="bodySm">
              {commonText}.
              <br />
              Your books are ready! Review any changes that may have been made
              to them.
            </Text>
          ),
          actionLink: ActionLinkType.checklistActionLink,
        }
      }
      case FormFilingStatusDetailedStep.submittedAndUnclaimed:
        return {
          needsAction: false,
          formTypeText,
          stepName: 'Tax Checklist Submitted',
          description:
            'Your tax checklist and documents have been submitted to your tax preparer. They’ll reach out if they need anything from you.',
          actionLink: ActionLinkType.viewTaxCenterActionLink,
        }
      case FormFilingStatusDetailedStep.claimedForReview:
        return {
          needsAction: false,
          formTypeText,
          stepName: 'Tax Preparer Review',
          description:
            'Your tax preparer will review your documents and reach out if they need anything from you.',
          actionLink: ActionLinkType.viewTaxCenterActionLink,
        }
      case FormFilingStatusDetailedStep.unreadMessages:
        return {
          needsAction: true,
          formTypeText,
          stepName: 'Respond to Tax Preparer',
          description: 'They have questions about your information.',
          actionLink: ActionLinkType.respondActionLink,
        }
      case FormFilingStatusDetailedStep.inProgress:
        return {
          needsAction: false,
          formTypeText,
          stepName: 'Tax Draft Return in Progress',
          description: 'Your tax preparer will let you know when it’s ready.',
          actionLink: ActionLinkType.viewTaxCenterActionLink,
        }
      case FormFilingStatusDetailedStep.readyForReview:
        return {
          needsAction: true,
          formTypeText,
          stepName: 'Draft Ready for Review',
          description:
            'Review and approve your tax return if all of the information is correct.',
          actionLink: ActionLinkType.reviewActionLink,
        }
      case FormFilingStatusDetailedStep.signatureRequested:
        return {
          needsAction: true,
          formTypeText,
          stepName: 'Signature Requested',
          description:
            'Your tax filing is ready to sign. Your tax preparer will guide you through the process.',
          actionLink: ActionLinkType.reviewActionLink,
        }
      case FormFilingStatusDetailedStep.filingReturn:
        return {
          needsAction: false,
          formTypeText,
          stepName: 'Filing Return',
          description:
            'Filing your tax return with the IRS. Your preparer will let you know when the filing is complete.',
          actionLink: ActionLinkType.viewTaxCenterActionLink,
        }
      case FormFilingStatusDetailedStep.filedNeedsPayment:
        return {
          needsAction: true,
          formTypeText: formTypeTextPersonal,
          stepName: `Update ${form.year} Wrap-Up`,
          description:
            'Congrats! The IRS has accepted your 1040 tax filing. Please update Heard with whether you received a refund or made a tax payment.',
          actionLink: ActionLinkType.viewTaxCenterActionLink,
        }
      case FormFilingStatusDetailedStep.filedUploadK1:
        return {
          needsAction: true,
          formTypeText: formTypeTextBusiness,
          stepName: 'Upload Schedule K-1',
          description:
            'The IRS has accepted your filing. Look out for your Schedule K-1, which you’ll need to upload for your personal tax return. You’ll find it under the “Taxes” tab of Your Documents.',
          actionLink: ActionLinkType.viewTaxCenterActionLink,
        }
      case FormFilingStatusDetailedStep.completed: {
        const formTypeText = formIs1040
          ? formTypeTextPersonal
          : formTypeTextBusiness
        return {
          needsAction: false,
          formTypeText,
          stepName: 'Return Filed',
          description: formIs1040
            ? 'Thanks for allowing us to guide you along the way. You can always view your tax return in the “Taxes” tab of Your Documents.'
            : 'Congrats! The IRS has accepted your 1120-S tax filing. Thanks for allowing us to guide you along the way. You can always view your return in the “Taxes” tab of Your Documents.',
          actionLink: ActionLinkType.viewTaxCenterActionLink,
        }
      }
      case null:
        return null
      default:
        return step satisfies never
    }
  }, [
    approveSubmitSection,
    booksSection,
    docsSection,
    form.year,
    form.formType.name,
    isNewScorpSubscription2023,
    isTwoFormFiler,
    step,
    tqSection,
  ])

  if (!statusDetails || isLoading) {
    return null
  }
  const { needsAction, formTypeText, stepName, description, actionLink } =
    statusDetails

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        padding: forDashboard ? 16 : 0,
      }}
    >
      <Label
        color={needsAction ? 'orange' : 'neutral'}
        icon={needsAction ? regular('thumbtack') : regular('thumbs-up')}
        style={{ marginBottom: 32 }}
      >
        {needsAction ? 'Needs Action' : 'No Action Needed'}
      </Label>
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <div>
          <Text as="bodyXs" style={{ marginBottom: 4 }}>
            {formTypeText}
          </Text>
          <Text as="h3">{stepName}</Text>
        </div>
        <Text color="darkGray" as="bodySm">
          {description}
        </Text>
        {forDashboard && (
          <ActionLinkForDashboard actionLink={actionLink} formId={form.id} />
        )}
      </div>
    </Card>
  )
}

export const FilingStatusByForm = ({
  filingForm,
}: {
  filingForm: AnnualTaxFilingForm
}) => {
  const navigate = useNavigate()
  const imageSrc =
    filingForm.formType.name === TAX_ENTITY_TYPES.form_1040
      ? 'https://heard-images.s3.amazonaws.com/assets/tax-home.svg'
      : 'https://heard-images.s3.amazonaws.com/assets/tax-city.svg'
  const step = useReselector(
    selectCurrentDetailedStepForForm,
    filingForm.year,
    filingForm.id
  )
  const isChecklistStep = step !== null && step in TaxChecklistSectionStatusStep
  const needsExtension = useReselector(
    selectShowFileExtensionForYear,
    filingForm.year
  )
  const filingExtensionStatus = useReselector(
    selectFileExtensionStatusForYear,
    filingForm.year
  )
  if (
    needsExtension &&
    filingExtensionStatus !== AnnualTaxFilingStepStatus.completeLocked
  ) {
    return null
  }

  return (
    <Card type="section" padding={24}>
      <Grid stackable>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={3}>
            <Image src={imageSrc} />
          </Grid.Column>
          <Grid.Column width={11}>
            <DetailedFormStatusItem form={filingForm} />
          </Grid.Column>
          <Grid.Column width={2} style={{ padding: 0 }}>
            {isChecklistStep && (
              <Button onClick={() => navigate('/taxes/annual/tax_checklist/')}>
                View
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default DetailedFormStatusItem
