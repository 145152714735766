import { Fragment } from 'react'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Colors } from '../../../../../styles/theme'
import {
  Text,
  GridRowColumn,
  Card,
  Link,
} from '../../../../../components/BaseComponents'
import { formatCurrencyFromCentsAndRound } from '../../../../../utils/currencyHelpers'
import { BalanceSheetCategoryData } from '../../../../../actions/reportActions'
import {
  generateLineItemLink,
  SUBCATEGORY_LABELS,
  SUBCATEGORY_TOTAL_LABELS,
} from '../helpers'

interface BalanceSheetCategoryProps {
  categoryData: BalanceSheetCategoryData
  totalLabel: string
  isPdf: boolean
  balanceSheetDate: string
}

const TotalDivider = styled.hr`
  width: 100%;
  margin-inline-end: 10px;
  border: none;
  border-top: 1px solid ${Colors.black};
  margin-left: 12px;
`

export const BalanceSheetCategory = ({
  categoryData,
  totalLabel,
  isPdf,
  balanceSheetDate,
}: BalanceSheetCategoryProps) => {
  const { userId } = useParams<{ userId: string }>()

  return (
    <Grid>
      {categoryData.subcategories.map((subcategory, subIndex) => (
        <Fragment key={subcategory.label}>
          {subcategory.label !== 'Equity' && (
            <GridRowColumn style={{ marginBottom: 4, padding: 0 }}>
              <Text as="h3">
                {SUBCATEGORY_LABELS[subcategory.label] ?? subcategory.label}
              </Text>
            </GridRowColumn>
          )}
          {subcategory.accounts.map((account, idx) => {
            const linkUrl = generateLineItemLink(
              account,
              userId,
              balanceSheetDate
            )
            return (
              <Grid.Row
                key={account.label + idx}
                style={{ paddingBottom: 4, paddingTop: 4 }}
              >
                <Grid.Column width={10} floated="left">
                  {isPdf ? (
                    <Text>{account.label}</Text>
                  ) : (
                    <Link
                      to={linkUrl}
                      style={{ color: 'black', textAlign: 'left' }}
                    >
                      <Text>{account.label}</Text>
                    </Link>
                  )}
                </Grid.Column>
                <Grid.Column width={6} floated="right">
                  <Text textAlign="right">
                    {formatCurrencyFromCentsAndRound(account.sumInCents, {
                      hideDecimalsIfZero: true,
                    })}
                  </Text>
                </Grid.Column>
              </Grid.Row>
            )
          })}
          <TotalDivider />
          <Grid.Row
            key={subcategory.label}
            style={{ paddingBottom: 4, paddingTop: 4 }}
          >
            <Grid.Column width={10} floated="left">
              <Text as="h3">
                {SUBCATEGORY_TOTAL_LABELS[subcategory.label] ??
                  `Total ${subcategory.label}`}
              </Text>
            </Grid.Column>
            <Grid.Column width={6} floated="right">
              <Text as="h3" textAlign="right">
                {formatCurrencyFromCentsAndRound(subcategory.sumInCents, {
                  hideDecimalsIfZero: true,
                })}
              </Text>
            </Grid.Column>
          </Grid.Row>
          {subIndex !== categoryData.subcategories.length - 1 && (
            <GridRowColumn />
          )}
          <GridRowColumn short />
        </Fragment>
      ))}
      <GridRowColumn>
        <Card backgroundColor="blush" padding={20} type="subsection">
          <Grid>
            <Grid.Row>
              <Grid.Column width={10} floated="left">
                <Text as="h3"> {totalLabel} </Text>
              </Grid.Column>
              <Grid.Column width={6} floated="right">
                <Text as="h3" textAlign="right">
                  {formatCurrencyFromCentsAndRound(categoryData.sumInCents, {
                    hideDecimalsIfZero: true,
                  })}
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </GridRowColumn>
    </Grid>
  )
}
