import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { normalize } from 'normalizr'

import { annualTaxFilingListSchema } from '../../../schema'
import { AnnualTaxFilingForm, TaxfyleJob } from './annualTaxFilingForms.slice'
import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import { TaxFormType } from './Questionnaires/constants'
import { TAX_ENTITY_TYPES } from '../taxConstants'

export interface AnnualTaxFilingStatus {
  id: number
  annualTaxFilingId: number
  annualTaxFilingStatusTypeId: number
  createdAt: string
  updatedAt: string
  AnnualTaxFilingStatusType: {
    name: string
  }
}

export type DependentRelationship =
  | 'son'
  | 'daughter'
  | 'mother'
  | 'father'
  | 'adopted_child'
  | 'foster_child'
  | 'grandchild'
  | 'other'
  | ''

export interface DependentsDetails {
  fullname: string
  dob: string
  relationship: DependentRelationship
}

export enum ConfirmAnnualTaxFiling {
  yes = 'yes',
  no = 'no',
  unsure = 'unsure',
}

export interface AnnualTaxFiling {
  id: number
  userId: number
  status?: string | null
  statusId: number
  year: string
  expedited: boolean | null
  expeditedReason: string | null
  expeditedDueDate: string | null
  additionalTaxInformation: string | null
  priorYearTaxReturnUploaded: boolean | null
  surveyLastUpdatedAt: string | number | null
  needContractorFiling: needContractorFilingOption | null
  annualTaxFormNeeds: Array<TaxFormType> | null
  createdAt: string
  updatedAt: string
  expeditedAcknowledgeTimeConstraints: boolean | null
  expeditedAcknowledgeDocuments: boolean | null
  marriedFilingJointlyDetails: {
    fullname: string
    dob: string
  } | null
  dependentsDetails: Array<DependentsDetails> | null
  annualTaxFilingForms?: number[]
  statuses: AnnualTaxFilingStatus[]
  googleSheetUrl: string | null
  pretaxSurveySubmittedAt: string | number | null
  optedOutAt: string | null
  taxOverviewCompletedAt: string | number | null
  endOfYearReviewCompletedAt: string | null
  confirmedFormNeeds: ConfirmAnnualTaxFiling | null
  isLateJoiner: boolean | null
  extensionTaxfyleJobId?: string | null
  isLateJoinerTermsAcceptedAt: string | null
  extensionRequestedAt: string | null
  extensionStartedAt: string | null
  extensionRequestSurveyCompletedAt: string | null
  extensionForced: boolean | null
  extensionReason: string | null
  extensionTaxfyleJob: TaxfyleJob | null
  taxNpsSubmittedAt: string | null
  taxSeasonSurveyStartedAt: string | null
  paidContractorThisYear: boolean | null
  taxSeasonSurveyInitialTaxEntity: TAX_ENTITY_TYPES | null
  taxSeasonSurveyNeedHelp: boolean | null
  taxSeasonSurveySelfDescribedTaxEntity: TAX_ENTITY_TYPES | null
}

export enum needContractorFilingOption {
  yes = 'yes',
  no = 'no',
  unsure = 'unsure',
}

export interface AnnualTaxFilingState {
  [key: string]: AnnualTaxFiling
}

export enum ExtensionReason {
  lateJoiner = 'LATE_JOINER',
  taxEntityUnconfirmed = 'TAX_ENTITY_UNCONFIRMED',
  bookkeepingTasksIncomplete = 'BOOKKEEPING_TASKS_INCOMPLETE',
  booksNotLocked = 'BOOKS_NOT_LOCKED',
  userRequested = 'USER_REQUESTED',
  filedExternally = 'FILED_EXTERNALLY',
}

const annualTaxFilingsSlice = createSlice({
  name: 'annualTaxFilings',
  initialState: {} as AnnualTaxFilingState,
  reducers: {
    getAllAnnualTaxFilings: (
      state,
      action: PayloadAction<AnnualTaxFilingState>
    ) => ({ ...state, ...action.payload }),
    getSingleAnnualTaxFiling: (
      state,
      action: PayloadAction<AnnualTaxFiling>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default annualTaxFilingsSlice.reducer

// Actions
const { getAllAnnualTaxFilings, getSingleAnnualTaxFiling } =
  annualTaxFilingsSlice.actions

export const FETCH_TAX_FILINGS_KEY = 'FETCH_TAX_FILINGS_KEY'
export const fetchAnnualTaxFilingsIfNeeded = (forceFetch?: boolean) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_TAX_FILINGS_KEY,
    defaultErrorMessage: 'Error fetching all user tax filings',
    alwaysFetch: forceFetch,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AnnualTaxFiling[]>(
        '/finances/api/v1/annual_tax_filings'
      )
      const normalizedData = normalize<
        AnnualTaxFiling,
        {
          annualTaxFilings?: { [key: string]: AnnualTaxFiling }
          annualTaxFilingForms?: { [key: string]: AnnualTaxFilingForm }
        },
        string[]
      >(json.data, annualTaxFilingListSchema)

      // Forms don't come back with all of the info we need so don't save it off to the redux.  Make the forms call
      // separately if needed
      dispatch(
        getAllAnnualTaxFilings(normalizedData.entities.annualTaxFilings || {})
      )

      return json.data
    },
  })

export const CREATE_ANNUAL_TAX_FILING_KEY = 'CREATE_ANNUAL_TAX_FILING_KEY'
export const createAnnualTaxFilings = (
  data: Partial<AnnualTaxFiling> & { year: string; message?: string }
) =>
  fetchWrapper({
    fetchKey: CREATE_ANNUAL_TAX_FILING_KEY,
    defaultErrorMessage: 'Error creating annual tax filing',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<AnnualTaxFiling>(
        '/finances/api/v1/annual_tax_filings',
        data
      )
      dispatch(getSingleAnnualTaxFiling(json.data))
      return json.data
    },
  })

export const UPDATE_ANNUAL_TAX_FILING_KEY = 'UPDATE_ANNUAL_TAX_FILING_KEY'
export const updateAnnualTaxFilings = (
  id: number,
  data: Partial<AnnualTaxFiling> & { message?: string }
) =>
  fetchWrapper({
    fetchKey: UPDATE_ANNUAL_TAX_FILING_KEY,
    defaultErrorMessage: 'Error updating tax filing',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<AnnualTaxFiling>(
        `/finances/api/v1/annual_tax_filings/${id}`,
        data
      )
      dispatch(getSingleAnnualTaxFiling(json.data))
      return json.data
    },
  })

export const SUBMIT_ANNUAL_TAX_FILING_KEY = 'SUBMIT_ANNUAL_TAX_FILING_KEY'
export const submitAnnualTaxFiling = (
  id: number,
  data: Partial<AnnualTaxFiling>
) =>
  fetchWrapper({
    fetchKey: SUBMIT_ANNUAL_TAX_FILING_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<AnnualTaxFiling>(
        `/finances/api/v1/pre_tax_survey/${id}/submit`,
        data
      )
      dispatch(getSingleAnnualTaxFiling(json.data))
      return json.data
    },
  })

export const REQUEST_EXTENSION = 'REQUEST_EXTENSION'
export const requestExtension = (extensionReason: ExtensionReason) =>
  fetchWrapper({
    fetchKey: REQUEST_EXTENSION,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<AnnualTaxFiling>(
        '/finances/api/v1/annual_tax_filings/request_extension',
        { reason: extensionReason }
      )
      dispatch(getSingleAnnualTaxFiling(json.data))
      return json.data
    },
  })

/*
  POST to annual_tax_filings/:id/annual_tax_filing_statuses

  This will create a new AnnualTaxFilingStatus, then return the FILING to be updated in our redux state. This prevents us from
  needing to manage statuses independently
*/
export interface AnnualTaxFilingStatusData {
  annualTaxFilingId: number
  annualTaxFilingStatusTypeId: number
}

export const updateAnnualTaxFilingStatus = (
  id: number,
  data: AnnualTaxFilingStatusData
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating tax filing',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<AnnualTaxFiling>(
        `/finances/api/v1/annual_tax_filings/${id}/annual_tax_filing_statuses`,
        data
      )
      dispatch(getSingleAnnualTaxFiling(json.data))
      return json.data
    },
  })

export interface AnnualTaxFilingNonNormalized
  extends Omit<AnnualTaxFiling, 'annualTaxFilingForms'> {
  annualTaxFilingForms: AnnualTaxFilingForm[]
}

export interface TaxFilingUser {
  id: number
  uuid: string
  firstName: string
  lastName: string
  email: string
  createdAt: string
  annualTaxFilings?: AnnualTaxFilingNonNormalized[]
  bookkeeperId: number
  bookkeeper: {
    firstName: string
    lastName: string
  }
  ownerId: number
  owner: {
    firstName: string
    lastName: string
  }
  taxPreparerId: number
  taxPreparer: {
    firstName: string
    lastName: string
  }
  taxCoordinatorId: number
  taxCoordinator: {
    firstName: string
    lastName: string
  }
}
