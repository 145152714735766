import { ReactNode, useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  kickoffTicketTags,
  kickoffTicketTasks,
  kickoffTicketTopic,
  TaxSeasonKickoffSurveyProps,
} from './YearEndKickoffSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import {
  Card,
  FormikLabelError,
  FormikRadioButton,
  FormikTextArea,
  getFieldNames,
  GridRowColumn,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getUserDocumentsByCategoryInternalName } from '../../../UserDocuments/userDocuments.selector'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import {
  getFinancialProfile,
  selectUserName,
} from '../../../../selectors/user.selectors'
import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'
import { postCreateNewTicket } from '../../../Conversations/actions'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const EntityCard = ({
  title,
  image,
  description,
}: {
  title: string
  image: string
  description: ReactNode
}) => (
  <Card type="subsection" backgroundColor="natural">
    <Grid>
      <Grid.Row>
        <Grid.Column width={2}>
          <Image src={image} style={{ width: 80, height: 80 }} />
        </Grid.Column>
        <Grid.Column width={14} verticalAlign="middle">
          <Text as="h2">{title}</Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>
        <Card type="subsection" backgroundColor="blush">
          {description}
        </Card>
      </GridRowColumn>
    </Grid>
  </Card>
)

enum SoleConfirmation {
  yes = 'yes',
  noNowScorp = 'noNowScorp',
  none = 'none',
}

const SoleProp = ({ goToNextStep, goBack }: TaxSeasonKickoffSurveyProps) => {
  const cp261Notice = useReselector(
    getUserDocumentsByCategoryInternalName,
    UserDocumentCategoryIdentifier.cp261Notice
  )

  const verifiedScorp = Boolean(cp261Notice.length)

  const formik = useFormik({
    initialValues: {
      soleConfirmation: undefined as undefined | SoleConfirmation,
    },
    onSubmit: (values) => {
      let nextPage
      let taxSeasonSurveySelfDescribedTaxEntity

      switch (values.soleConfirmation) {
        case 'yes':
          nextPage = TAX_SEASON_KICKOFF_SURVEY.contractorPayroll
          taxSeasonSurveySelfDescribedTaxEntity = TAX_ENTITY_TYPES.form_1040
          break
        case 'noNowScorp':
          nextPage = verifiedScorp
            ? TAX_SEASON_KICKOFF_SURVEY.contractorPayroll
            : TAX_SEASON_KICKOFF_SURVEY.scorpConfirmation
          taxSeasonSurveySelfDescribedTaxEntity = TAX_ENTITY_TYPES.form_1120_s
          break
        case 'none':
        default:
          nextPage = TAX_SEASON_KICKOFF_SURVEY.solePropTaxEntityHelp
          taxSeasonSurveySelfDescribedTaxEntity = null
          break
      }

      goToNextStep({ taxSeasonSurveySelfDescribedTaxEntity }, nextPage)
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <GridRowColumn width={12}>
        <EntityCard
          image="https://heard-images.s3.amazonaws.com/assets/chair.svg"
          title="Sole proprietor"
          description={
            <Text as="bodyLg">
              You’re a sole proprietor if:
              <ul>
                <li>
                  You haven’t formally registered your business as a separate
                  legal entity
                </li>
                <li>
                  You file your business income and expenses on your personal
                  tax return
                </li>
              </ul>
            </Text>
          }
        />
      </GridRowColumn>
      <GridRowColumn width={12}>
        <FormikLabelError
          label="According to the description above, are you still a sole proprietor?"
          name={fieldNames.soleConfirmation}
          schema={makeReqStringSchema()}
        />
      </GridRowColumn>
      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="Yes, I am still a sole proprietor"
          variant="default"
          value={SoleConfirmation.yes}
          name={fieldNames.soleConfirmation}
        />
      </GridRowColumn>
      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="No, I made changes, and I am now an S corporation"
          variant="default"
          value={SoleConfirmation.noNowScorp}
          name={fieldNames.soleConfirmation}
        />
      </GridRowColumn>
      {values.soleConfirmation === SoleConfirmation.noNowScorp && (
        <GridRowColumn width={12}>
          <Card type="subsection" backgroundColor="natural">
            <Text as="h3">Congrats on becoming an S corporation!</Text>
            <br />
            {verifiedScorp ? (
              <Text>
                Our team will review your change in tax entity, and reach out if
                we have any questions.
                <br />
                In the meantime, you can proceed with the rest of this form.
              </Text>
            ) : (
              <Text>
                This means at least one of the following apply:
                <ul>
                  <li>
                    You’ve submitted a Form 2553 <b>and it has been accepted</b>{' '}
                    by the IRS/state.
                  </li>
                  <li>You’ve received a Form CP261</li>
                  <li>You’ve filed a 1120-S tax return</li>
                </ul>
                You can proceed to the next page to complete your S corporation
                verification
              </Text>
            )}
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="None of the above, or I need help"
          variant="default"
          value={SoleConfirmation.none}
          name={fieldNames.soleConfirmation}
        />
      </GridRowColumn>
      <FormFlowFooter
        onBack={() => goBack(null)}
        continueDisabled={isSubmitting || !isValid}
        onForward={submitForm}
      />
    </FormikProvider>
  )
}

enum ScorpConfirmation {
  yes = 'yes',
  no = 'no',
}

const Scorp = ({ goToNextStep, goBack }: TaxSeasonKickoffSurveyProps) => {
  const cp261Notice = useReselector(
    getUserDocumentsByCategoryInternalName,
    UserDocumentCategoryIdentifier.cp261Notice
  )

  const verifiedScorp = Boolean(cp261Notice.length)

  const formik = useFormik({
    initialValues: {
      scorpConfirmation: undefined as undefined | ScorpConfirmation,
    },
    onSubmit: (values) => {
      const isScorp = values.scorpConfirmation === ScorpConfirmation.yes

      const nextPage = isScorp
        ? verifiedScorp
          ? TAX_SEASON_KICKOFF_SURVEY.contractorPayroll
          : TAX_SEASON_KICKOFF_SURVEY.scorpConfirmation
        : TAX_SEASON_KICKOFF_SURVEY.scorpTaxEntityHelp

      goToNextStep(
        {
          taxSeasonSurveySelfDescribedTaxEntity: isScorp
            ? TAX_ENTITY_TYPES.form_1120_s
            : null,
        },
        nextPage
      )
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <GridRowColumn width={12}>
        <EntityCard
          image="https://heard-images.s3.amazonaws.com/assets/bank-green.svg"
          title="S corporation"
          description={
            <Text as="bodyLg">
              You’re an S corporation if at least one of the following apply:
              <ul>
                <li>
                  You’ve submitted a Form 2553 and it has <b>been accepted</b>{' '}
                  by the IRS/state.
                </li>
                <li>You’ve received a Form CP261</li>
                <li>
                  You’ve successfully filed a 1120-S tax return in prior years
                </li>
              </ul>
            </Text>
          }
        />
      </GridRowColumn>
      <GridRowColumn width={12}>
        <FormikLabelError
          label="Do any of the above situations apply?"
          name={fieldNames.scorpConfirmation}
          schema={makeReqStringSchema()}
        />
      </GridRowColumn>
      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="Yes, at least one of the above apply, and I am still an S corporation"
          variant="default"
          value={ScorpConfirmation.yes}
          name={fieldNames.scorpConfirmation}
        />
      </GridRowColumn>
      {values.scorpConfirmation === ScorpConfirmation.yes && (
        <GridRowColumn width={12}>
          <Card type="subsection" backgroundColor="natural">
            {verifiedScorp ? (
              <Text>
                Great! It looks like we have a copy of your Form CP261. Our team
                is working on validating it, and will let you know if we need
                anything from you. In the meantime, you can proceed with the
                rest of this form.
              </Text>
            ) : (
              <Text>
                Got it. In order to verify your status as an S corporation,
                we’ll need you to upload a Form CP261 on the next page.
              </Text>
            )}
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="None of the above or I need help"
          variant="default"
          value={SoleConfirmation.noNowScorp}
          name={fieldNames.scorpConfirmation}
        />
      </GridRowColumn>
      <FormFlowFooter
        onBack={() => goBack(null)}
        continueDisabled={isSubmitting || !isValid}
        onForward={submitForm}
      />
    </FormikProvider>
  )
}

enum CCorpConfirmation {
  yes = 'yes',
  no = 'no',
  unsure = 'unsure',
}

const CCorp = ({ goToNextStep, goBack }: TaxSeasonKickoffSurveyProps) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const userName = useReselector(selectUserName)

  const formik = useFormik({
    initialValues: {
      cCorpConfirmation: undefined as undefined | CCorpConfirmation,
      cCorpExtraInfo: '',
    },
    onSubmit: (values) => {
      let body
      let subject
      const ticketTask = kickoffTicketTasks.offboard
      const tags = [...kickoffTicketTags]

      if (values.cCorpConfirmation === CCorpConfirmation.yes) {
        subject = `Verifying your C Corp status for ${taxYear} taxes`
        body = `${userName} is currently listed as an C-corp and indicated they are still a C-corp.`
        tags.push('ccorp-offboard')
      } else if (values.cCorpConfirmation === CCorpConfirmation.no) {
        subject = `Determining your tax entity type for ${taxYear} taxes`
        body = `${userName} is currently listed as an C-corp but has indicated they are a different business entity.\nComment: ${values.cCorpExtraInfo}`
        tags.push('currently-ccorp-changed-entity')
      } else {
        subject = `Determining your tax entity type for ${taxYear} taxes`
        body = `${userName} is currently listed as a C-corp but has indicated they need help with confirming their tax entity.\nComment: ${values.cCorpExtraInfo}`
        tags.push('tax-entity-confusion-other')
      }

      dispatch(
        postCreateNewTicket({
          comment: { body, htmlBody: `<p>${body}</p>` },
          internalSubmitter: true,
          ticketTopic: kickoffTicketTopic,
          ticketTask,
          subject,
          tags,
        })
      )

      const isCcorp = values.cCorpConfirmation === CCorpConfirmation.yes

      const nextStep = isCcorp
        ? TAX_SEASON_KICKOFF_SURVEY.cCorpDisqualified
        : TAX_SEASON_KICKOFF_SURVEY.helpSubmission

      goToNextStep(
        {
          taxSeasonSurveySelfDescribedTaxEntity: isCcorp
            ? TAX_ENTITY_TYPES.form_1065
            : null,
        },
        nextStep
      )
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <GridRowColumn width={12}>
        <EntityCard
          image="https://heard-images.s3.amazonaws.com/assets/split-money.svg"
          title="C corporation"
          description={
            <Text as="bodyLg">
              A C corporation is a legal entity that is separate from its owners
              and is owned by its shareholders. You would have applied
              specifically to become a C corporation.
              <br />
              Unfortunately, if you are a C corporation, but have not elected to
              be taxed as an S-corporation, Heard is unable to service your
              accounting & tax needs.
            </Text>
          }
        />
      </GridRowColumn>
      <GridRowColumn width={12}>
        <FormikLabelError
          label="Are you still a C corporation?"
          name={fieldNames.cCorpConfirmation}
          schema={makeReqStringSchema()}
        />
      </GridRowColumn>
      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="Yes"
          variant="default"
          value={CCorpConfirmation.yes}
          name={fieldNames.cCorpConfirmation}
        />
      </GridRowColumn>

      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="No"
          variant="default"
          value={CCorpConfirmation.no}
          name={fieldNames.cCorpConfirmation}
        />
      </GridRowColumn>
      {values.cCorpConfirmation === CCorpConfirmation.no && (
        <GridRowColumn width={12}>
          <FormikTextArea
            label="Let our Support team know how we can help"
            placeholder="Please explain your situation (required)"
            name={fieldNames.cCorpExtraInfo}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
      )}
      <GridRowColumn short width={12}>
        <FormikRadioButton
          label="I don’t know, or I need help"
          variant="default"
          value={CCorpConfirmation.unsure}
          name={fieldNames.cCorpConfirmation}
        />
      </GridRowColumn>
      {values.cCorpConfirmation === CCorpConfirmation.unsure && (
        <GridRowColumn width={12}>
          <FormikTextArea
            label="Let our Support team know how we can help"
            placeholder="Please explain your situation (required)"
            name={fieldNames.cCorpExtraInfo}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
      )}
      <FormFlowFooter
        onBack={() => goBack(null)}
        continueDisabled={isSubmitting || !isValid}
        onForward={submitForm}
      />
    </FormikProvider>
  )
}

const TaxEntity = ({ goToNextStep, goBack }: TaxSeasonKickoffSurveyProps) => {
  const currentAnnualTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const fp = useReselector(getFinancialProfile)

  const content = useMemo(() => {
    const entity = fp?.taxEntityType
    switch (entity) {
      case TAX_ENTITY_TYPES.form_1120_s:
        return <Scorp goToNextStep={goToNextStep} goBack={goBack} />
      case TAX_ENTITY_TYPES.form_1040:
        return <SoleProp goToNextStep={goToNextStep} goBack={goBack} />
      case TAX_ENTITY_TYPES.form_1065:
        return <CCorp goToNextStep={goToNextStep} goBack={goBack} />
      case TAX_ENTITY_TYPES.form_1120:
      case TAX_ENTITY_TYPES.schedule_c:
      case null:
      case undefined:
        return null
      default:
        return entity satisfies never
    }
  }, [fp?.taxEntityType, goBack, goToNextStep])

  return (
    <Grid>
      <GridRowColumn width={12}>
        <Text as="display2">
          Let&apos;s get ready for {currentAnnualTaxYear} taxes
        </Text>
      </GridRowColumn>
      <GridRowColumn width={12}>
        <Text as="bodyLg">
          To support you for the upcoming tax season we need to know how your
          business is structured for taxes.
        </Text>
      </GridRowColumn>
      <GridRowColumn width={12}>
        <Text as="bodyLg">According to our records you are a:</Text>
      </GridRowColumn>
      {content}
    </Grid>
  )
}

export default TaxEntity
