import { useCallback } from 'react'

import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesForPriorYear,
} from './taxChecklist.selectors'
import { TaxChecklistResponse } from './taxChecklistQuestion.slice'
import { deleteTaxQuestionnaireResponses } from './taxChecklistQuestion.actions'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import { skipQuestionId } from './Shared/SkipQuestion'

export enum TaxListQuestionId {
  audited_last_year = 'AUDITED_LAST_YEAR',
  business_address = 'BUSINESS_ADDRESS',
  business_email = 'BUSINESS_EMAIL',
  business_calendar_type = 'BUSINESS_CALENDAR_TYPE',
  business_income_in_cents = 'BUSINESS_INCOME_IN_CENTS',
  business_inventory = 'BUSINESS_INVENTORY',
  business_mileage = 'BUSINESS_MILEAGE',
  business_name = 'BUSINESS_NAME',
  business_phone = 'BUSINESS_PHONE',
  charitable_cash_contribution_amount_in_cents = 'CHARITABLE_CONTRIBUTION_CASH_AMOUNT_IN_CENTS',
  charitable_contribution = 'CHARITABLE_CONTRIBUTION',
  charitable_non_cash_contribution_amount_in_cents = 'CHARITABLE_CONTRIBUTION_NON_CASH_AMOUNT_IN_CENTS',
  childcare_provider_address = 'CHILDCARE_PROVIDER_ADDRESS',
  childcare_provider_ein = 'CHILDCARE_PROVIDER_TIN',
  childcare_provider_expenses_amount_in_cents = 'CHILDCARE_PROVIDER_EXPENSES_AMOUNT_IN_CENTS',
  childcare_provider_name = 'CHILDCARE_PROVIDER_NAME',
  child_tax_credit = 'CHILD_TAX_CREDIT',
  crypto_made_txns = 'CRYPTO_MADE_TRANSACTIONS',
  crypto_sold = 'CRYPTO_SOLD',
  crypto_statement = 'CRYPTO_STATEMENT',
  date_of_birth = 'DATE_OF_BIRTH',
  debt_cancellation = 'DEBT_CANCELLATION',
  dependent_date_of_birth = 'DEPENDENT_DATE_OF_BIRTH',
  dependent_full_name = 'DEPENDENT_FULL_NAME',
  dependent_relationship = 'DEPENDENT_RELATIONSHIP',
  dependent_ssn = 'DEPENDENT_SSN',
  disability_income = 'RECEIVED_DISABILITY',
  eco_home_improvements = 'ECO_HOME_IMPROVEMENTS',
  education_credit_type = 'EDUCATION_CREDIT_TYPE',
  ein = 'EIN',
  engaged_in_real_estate = 'ENGAGED_IN_REAL_ESTATE',
  filing_status = 'FILING_STATUS',
  filing_status_changed = 'FILING_STATUS_CHANGED',
  first_name = 'FIRST_NAME',
  foreign_income_or_taxes = 'FOREIGN_INCOME_OR_TAXES',
  gave_large_gifts = 'GAVE_LARGE_GIFTS',
  had_business_mileage = 'HAD_BUSINESS_MILEAGE',
  had_college_students = 'HAD_COLLEGE_STUDENTS',
  has_dependents = 'HAS_DEPENDENTS',
  healthcare_account_types = 'HEALTHCARE_ACCOUNT_TYPES',
  healthcare_all_qualified_expenses = 'HEALTHCARE_ALL_EXPENSES_QUALIFIED',
  healthcare_coverage_explanation = 'HEALTHCARE_COVERAGE_EXPLANATION',
  healthcare_coverage_type = 'HEALTHCARE_COVERAGE_TYPE',
  healthcare_coverage_type_spouse = 'HEALTHCARE_COVERAGE_TYPE_SPOUSE',
  healthcare_distributions_amount_in_cents = 'HEALTHCARE_DISTRIBUTIONS_AMOUNT_IN_CENTS',
  healthcare_had_account = 'HEALTHCARE_HAD_ACCOUNT',
  home_address = 'HOME_ADDRESS',
  home_office_deduction_method = 'HOME_OFFICE_DEDUCTION_METHOD',
  home_office_direct_electricity_amount_in_cents = 'HOME_OFFICE_DIRECT_ELECTRICITY_AMOUNT_IN_CENTS',
  home_office_direct_expenses = 'HOME_OFFICE_DIRECT_EXPENSES',
  home_office_direct_insurance_amount_in_cents = 'HOME_OFFICE_DIRECT_INSURANCE_AMOUNT_IN_CENTS',
  home_office_direct_maintenance_amount_in_cents = 'HOME_OFFICE_DIRECT_MAINTENANCE_AMOUNT_IN_CENTS',
  home_office_direct_rent_amount_in_cents = 'HOME_OFFICE_DIRECT_RENT_AMOUNT_IN_CENTS',
  home_office_direct_internet_amount_in_cents = 'HOME_OFFICE_DIRECT_INTERNET_AMOUNT_IN_CENTS',
  home_office_direct_water_sewage_amount_in_cents = 'HOME_OFFICE_DIRECT_WATER_SEWAGE_AMOUNT_IN_CENTS',
  home_office_direct_other_expense_amount_in_cents = 'HOME_OFFICE_DIRECT_OTHER_EXPENSES_AMOUNT_IN_CENTS',
  home_office_indirect_electricity_amount_in_cents = 'HOME_OFFICE_INDIRECT_ELECTRICITY_AMOUNT_IN_CENTS',
  home_office_indirect_insurance_amount_in_cents = 'HOME_OFFICE_INDIRECT_INSURANCE_AMOUNT_IN_CENTS',
  home_office_indirect_maintenance_amount_in_cents = 'HOME_OFFICE_INDIRECT_MAINTENANCE_AMOUNT_IN_CENTS',
  home_office_indirect_rent_amount_in_cents = 'HOME_OFFICE_INDIRECT_RENT_AMOUNT_IN_CENTS',
  home_office_indirect_internet_amount_in_cents = 'HOME_OFFICE_INDIRECT_INTERNET_AMOUNT_IN_CENTS',
  home_office_indirect_water_sewage_amount_in_cents = 'HOME_OFFICE_INDIRECT_WATER_SEWAGE_AMOUNT_IN_CENTS',
  home_office_indirect_other_expense_amount_in_cents = 'HOME_OFFICE_INDIRECT_OTHER_EXPENSES_AMOUNT_IN_CENTS',
  home_office_sq_ft_for_business = 'HOME_OFFICE_SQ_FT_FOR_BUSINESS',
  home_office_sq_ft_total = 'HOME_OFFICE_SQ_FT_TOTAL',
  home_office_total_standard_amount_in_cents = 'HOME_OFFICE_TOTAL_STANDARD_AMOUNT_IN_CENTS',
  home_purchase = 'HOME_PURCHASE',
  home_purchase_date = 'HOME_PURCHASE_DATE',
  home_purchase_residence_type = 'HOME_PURCHASE_RESIDENCE_TYPE',
  home_sale_date = 'HOME_SALE_DATE',
  home_sale_residence_type = 'HOME_SALE_RESIDENCE_TYPE',
  household_income_in_cents = 'HOUSEHOLD_INCOME_IN_CENTS',
  household_income_withholdings_in_cents = 'HOUSEHOLD_INCOME_WITHHOLDINGS_IN_CENTS',
  invested_in_rnd = 'INVESTED_IN_RND',
  investments_bought_sold = 'INVESTMENTS_BOUGHT_OR_SOLD',
  irs_installment_plan = 'IRS_INSTALLMENT_PLAN',
  k1_income = 'RECEIVED_K1_INCOME',
  last_name = 'LAST_NAME',
  multi_state_business_states = 'MULTI_STATE_BUSINESS_STATES',
  number_fixed_assets_sold = 'NUMBER_OF_FIXED_ASSETS_SOLD',
  number_of_brokerage_statements = 'NUMBER_OF_BROKERAGE_STATEMENTS',
  number_of_crypto_statements = 'NUMBER_OF_CRYPTO_STATEMENTS',
  number_of_dependents = 'NUMBER_OF_DEPENDENTS',
  number_of_farms = 'NUMBER_OF_FARMS',
  number_of_k1s_sprop = 'NUMBER_OF_K1S',
  number_of_k1s_scorp = 'NUMBER_OF_K1S_SCORP',
  number_of_real_estate = 'NUMBER_OF_REAL_ESTATE',
  number_of_rental_properties = 'NUMBER_OF_RENTAL_PROPERTIES',
  number_states_active = 'NUMBER_STATES_ACTIVE',
  number_states_conducted_business = 'NUMBER_OF_STATES_CONDUCTED_BUSINESS',
  occupation = 'OCCUPATION',
  offer_in_compromise = 'OFFER_IN_COMPROMISE',
  own_investments = 'INVESTMENTS_OWNED',
  own_rental_property = 'RENTAL_PROPERTY_OWNED',
  owned_farm = 'OWNED_FARM',
  owned_home = 'OWNED_HOME',
  paid_contractors = 'PAID_CONTRACTORS',
  paid_dependent_expenses = 'PAID_DEPENDENT_EXPENSES',
  paid_house_employee = 'PAID_HOUSE_EMPLOYEE',
  paid_mortgage_interest = 'PAID_MORTGAGE_INTEREST',
  paid_property_taxes = 'PAID_PROPERTY_TAXES',
  personal_phone = 'PERSONAL_PHONE',
  previous_year_business_tax_return = 'PREVIOUS_YEAR_BUSINESS_TAX_RETURN',
  previous_year_personal_tax_return = 'PREVIOUS_YEAR_PERSONAL_TAX_RETURN',
  received_dividends = 'RECEIVED_DIVIDENDS',
  refinanced_home = 'REFINANCED_HOME',
  rental_property_address = 'RENTAL_PROPERTY_ADDRESS',
  rental_property_first_time = 'RENTAL_PROPERTY_FIRST_TIME',
  retirement_contributed = 'RETIREMENT_CONTRIBUTED',
  retirement_contributed_amount_in_cents = 'RETIREMENT_CONTRIBUTION_AMOUNT_IN_CENTS',
  retirement_distribution = 'RETIREMENT_DISTRIBUTION',
  retirement_needs_help = 'RETIREMENT_NEEDS_HELP',
  retirement_plan_rolled_over = 'RETIREMENT_ROLLED_OVER',
  retirement_plan_type = 'RETIREMENT_PLAN_TYPE',
  scorp_election_date = 'SCORP_ELECTION_DATE',
  scorp_number_other_businesses = 'SCORP_NUMBER_OF_OTHER_BUSINESSES',
  scorp_owned_other_businesses = 'SCORP_OWNED_OTHER_BUSINESSES',
  shareholder_info_changed = 'SHAREHOLDER_INFO_CHANGED',
  sold_fixed_assets = 'SOLD_FIXED_ASSETS',
  sold_home = 'HOME_SALE',
  spouse_date_of_birth = 'SPOUSE_DATE_OF_BIRTH',
  spouse_email = 'SPOUSE_EMAIL',
  spouse_first_name = 'SPOUSE_FIRST_NAME',
  spouse_last_name = 'SPOUSE_LAST_NAME',
  spouse_occupation = 'SPOUSE_OCCUPATION',
  spouse_phone = 'SPOUSE_PHONE',
  spouse_ssn = 'SPOUSE_SSN',
  sprop_number_other_businesses = 'SPROP_NUMBER_OF_OTHER_BUSINESSES',
  sprop_owned_other_businesses = 'SPROP_OWNED_OTHER_BUSINESSES',
  ssn = 'SSN',
  states_active = 'STATES_ACTIVE',
  states_live_and_earned = 'STATES_LIVED_AND_EARNED',
  states_spouse = 'STATES_SPOUSE',
  states_with_clients = 'STATES_WITH_CLIENTS',
  worked_from_home = 'WORKED_FROM_HOME',
}

export enum TaxListOptionId {
  american_opportunity_credit = 'EDUCATION_AMERICAN_OPPORTUNITY_CREDIT',
  primary_residence = 'PRIMARY_RESIDENCE',
  secondary_residence = 'SECONDARY_RESIDENCE',
  rental_home = 'RENTAL_HOME',
  health_insurance_employer = 'HEALTH_INSURANCE_EMPLOYER',
  health_insurance_marketplace = 'HEALTH_INSURANCE_MARKETPLACE',
  health_insurance_medicare = 'HEALTH_INSURANCE_MEDICARE',
  health_insurance_other = 'HEALTH_INSURANCE_OTHER',
  health_insurance_partial = 'HEALTH_INSURANCE_PARTIAL',
  health_insurance_none = 'HEALTH_INSURANCE_NONE',
  home_office_method_simplified = 'HOME_OFFICE_METHOD_SIMPLIFIED',
  home_office_method_standard = 'HOME_OFFICE_METHOD_STANDARD',
  hra = 'HRA',
  lifetime_learning_credit = 'EDUCATION_LIFETIME_LEARNING_CREDIT',
  mfa = 'MFA',
  hsa = 'HSA',
  fsa = 'FSA',
  son = 'SON',
  daughter = 'DAUGHTER',
  mother = 'MOTHER',
  father = 'FATHER',
  adopted_child = 'ADOPTED_CHILD',
  foster_child = 'FOSTER_CHILD',
  grandchild = 'GRANDCHILD',
  other = 'OTHER',
  single = 'SINGLE',
  married_filing_jointly = 'MARRIED_FILING_JOINTLY',
  married_filing_separately = 'MARRIED_FILING_SEPARATELY',
  head_of_household = 'HEAD_OF_HOUSEHOLD',
  qualifying_widow = 'QUALIFYING_WIDOW',
  calendar_year = 'CALENDAR_YEAR',
  fiscal_year = 'FISCAL_YEAR',
}

/*
 Question Ids for custom FE questions not mapped 
 to a value in tax_questionnaire_questions table
*/
export enum AdditionalQuestionIds {
  previous_year_business_return_names = 'PREVIOUS_YEAR_BUSINESS_RETURN_NAMES',
  previous_year_personal_return_names = 'PREVIOUS_YEAR_PERSONAL_RETURN_NAMES',
  official_government_id_filename = 'OFFICIAL_GOVERNMENT_ID_FILENAME',
  official_government_id_spouse_filename = 'OFFICIAL_GOVERNMENT_ID_SPOUSE_FILENAME',
  Q1_federal_estimated_tax_payment = 'Q1_FEDERAL_ESTIMATED_TAX_PAYMENT',
  Q2_federal_estimated_tax_payment = 'Q2_FEDERAL_ESTIMATED_TAX_PAYMENT',
  Q3_federal_estimated_tax_payment = 'Q3_FEDERAL_ESTIMATED_TAX_PAYMENT',
  Q4_federal_estimated_tax_payment = 'Q4_FEDERAL_ESTIMATED_TAX_PAYMENT',
}

export const useTaxListQuestionRes = ({
  taxYear,
  formId,
  questionId,
  isCurrency,
}: {
  formId: number
  questionId: TaxListQuestionId
  taxYear?: string
  isCurrency?: boolean
}) => {
  const dispatch = useAppDispatch()
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxYearToUse = taxYear || currentTaxYear

  const question = useReselector(
    selectTaxListQuestion,
    questionId,
    taxYearToUse
  )
  const response = useReselector(
    selectTaxListQuestionResponsesByFormId,
    questionId,
    formId
  )

  const prevResponse = useReselector(
    selectTaxListQuestionResponsesForPriorYear,
    questionId,
    formId
  )

  const currentVal = response?.[0]?.value
  const currentSkipped = response?.[0]?.needsAssistance
  const responseId = response?.[0]?.id

  const getResponseData = useCallback(
    (
      values: {
        [skipQuestionId]: TaxListQuestionId[]
      } & Record<string, string | number | boolean | string[] | undefined>
    ) => {
      const isSkipped = values[skipQuestionId]?.includes(questionId)
      const newData = values[questionId]

      const valueToSet = isCurrency
        ? typeof newData === 'number'
          ? dollarsToCents(newData)
          : undefined
        : newData

      if (valueToSet !== currentVal || (isSkipped && !currentSkipped)) {
        return {
          id: responseId,
          value: valueToSet,
          annualTaxFilingFormId: formId,
          questionId,
          needsAssistance: isSkipped || null,
        } satisfies Partial<TaxChecklistResponse>
      }

      return undefined
    },

    [currentSkipped, currentVal, formId, isCurrency, questionId, responseId]
  )

  const deleteResponseData = useCallback(
    async (newData: string | number | boolean | string[] | undefined) => {
      if (!newData && responseId) {
        return Boolean(
          await dispatch(deleteTaxQuestionnaireResponses([responseId]))
        )
      }

      return true
    },
    [dispatch, responseId]
  )

  const parsedVal = isCurrency
    ? typeof currentVal === 'number'
      ? centsToDollars(currentVal)
      : undefined
    : currentVal

  const initialResponse =
    (parsedVal === undefined && prevResponse?.[0]?.value) || parsedVal

  return {
    questionId,
    responseId,
    currentVal: parsedVal,
    label: question.question?.text,
    options: question.options,
    getResponseData,
    deleteResponseData,
    initialResponse,
  }
}
