import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Pill,
  Text,
} from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import {
  isoToUTCDateTime,
  DATE_FORMATS_LUXON,
} from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { useNavigate } from 'react-router-dom'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { getMostUrgentModule } from '../../../YearEndModuleStatus/yearEndModuleStatus.selectors'
import getNextStepStatusPill from '../../../YearEndModuleStatus/NextStepStatusPillRules'
import EOYNextStepPill from '../../../YearEndModuleStatus/components/EOYNextStepPill'

const TaxSeasonKickoffEntryCard = () => {
  const navigate = useNavigate()
  const currentUser = useReselector(getCurrentUser)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const mostUrgentEndModuleStatus = useReselector(getMostUrgentModule)

  const pretaxSurveySubmittedAt = annualTaxFiling?.pretaxSurveySubmittedAt
  const nextStepStatusPillContent = getNextStepStatusPill({
    moduleType: mostUrgentEndModuleStatus?.moduleType,
    status: mostUrgentEndModuleStatus?.status,
    substepIdentifier: mostUrgentEndModuleStatus?.substepIdentifier,
    dueDate: mostUrgentEndModuleStatus?.dueDate,
  })

  return (
    <Card
      style={{
        backgroundColor: Colors.natural,
      }}
    >
      <Grid>
        <Grid.Column width={12}>
          <GridRowColumn>
            <Text as="eyebrow" style={{ marginBottom: 5 }}>
              Welcome {currentUser?.firstName}!
            </Text>
          </GridRowColumn>
          <Text
            as="display2"
            style={{
              marginBottom: 5,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {pretaxSurveySubmittedAt
              ? 'File your annual tax return'
              : 'Get started on annual taxes'}{' '}
            <Pill color={'red'} variant="solid">
              Due{' '}
              {pretaxSurveySubmittedAt &&
              annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate
                ? isoToUTCDateTime(
                    annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate
                  ).toFormat(DATE_FORMATS_LUXON.MONTH_DAY)
                : annualTaxDetails?.taxSeasonKickoffDueAt &&
                  isoToUTCDateTime(
                    annualTaxDetails.taxSeasonKickoffDueAt
                  ).toFormat(DATE_FORMATS_LUXON.MONTH_DAY)}
            </Pill>
          </Text>

          <Text as="bodySm" style={{ lineHeight: 1.5 }}>
            {pretaxSurveySubmittedAt &&
            annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate ? (
              `Close out ${taxYear} by getting everything in order for annual taxes. To avoid penalties, ensure that you have everything completed by ${isoToUTCDateTime(
                annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate
              ).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}`
            ) : (
              <>
                Complete the Tax Season Kickoff to let us know if you&apos;d
                like to file taxes with Heard. We recommend completing this{' '}
                <b>ASAP</b>. It only takes 5 minutes!
              </>
            )}
          </Text>
          <br />
          {pretaxSurveySubmittedAt && nextStepStatusPillContent && (
            <>
              <GridRowColumn>
                <EOYNextStepPill
                  icon={nextStepStatusPillContent.icon}
                  title={nextStepStatusPillContent.title}
                  subtext={nextStepStatusPillContent.subtext}
                  backgroundColor={nextStepStatusPillContent.backgroundColor}
                  width={nextStepStatusPillContent.width}
                  navigateTo={nextStepStatusPillContent.navigateTo}
                />
              </GridRowColumn>
              <br />
              <br />
            </>
          )}
          {pretaxSurveySubmittedAt ? (
            <Button onClick={() => navigate('/taxes/annual')}>
              Go to Annual Taxes
              <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          ) : (
            <Button onClick={() => navigate('/taxes/annual/kickoff-survey')}>
              Start Tax Season Kickoff
              <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg"
            style={{
              width: '170px',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Grid.Column>
      </Grid>
    </Card>
  )
}

export default TaxSeasonKickoffEntryCard
