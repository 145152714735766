import { createSelector } from 'reselect'
import { ReduxState } from '../../../utils/typeHelpers'
import {
  OnboardingStepId,
  isGettingStartedUserOnboardingStepExpanded,
  GettingStartedUserOnboardingStepExpanded,
  GettingStartedOnboardingStep,
  GettingStartedGroup,
  GettingStartedOnboardingStepId,
  OnboardingStepPhase,
} from './onboarding.reducer'
import { partition } from 'lodash'
import { DateTime } from 'luxon'

export const getUserOnboardingSteps = (state: ReduxState) =>
  state.onboarding.byId

export const getAllOnboardingSteps = (state: ReduxState) =>
  state.onboarding.allOnboardingSteps

export const getUserOnboardingStepByOnboardingStepId = createSelector(
  getUserOnboardingSteps,
  (_: unknown, onboardingStepId?: OnboardingStepId) => onboardingStepId,
  (onboardingSteps, onboardingStepId) => {
    return Object.values(onboardingSteps).find(
      (step) => step.onboardingStepId === onboardingStepId
    )
  }
)

export const getUserStepIdByOnboardingStepId = createSelector(
  getUserOnboardingStepByOnboardingStepId,
  (step) => step?.id
)

export const selectUserOnboardingStepsWithOnboardingSteps = createSelector(
  getUserOnboardingSteps,
  getAllOnboardingSteps,
  (userOnboardingSteps, allOnboardingSteps) => {
    return Object.values(userOnboardingSteps).map((userOnboardingStep) => ({
      ...userOnboardingStep,
      onboardingStep: allOnboardingSteps[userOnboardingStep.onboardingStepId],
    }))
  }
)

export const selectOnboardingStepCompletedOrSkipped = createSelector(
  selectUserOnboardingStepsWithOnboardingSteps,
  (_: unknown, onboardingStepId: OnboardingStepId) => onboardingStepId,
  (userOnboardingSteps, onboardingStepId) => {
    const userOnboardingStep = userOnboardingSteps.find(
      (step) => step.onboardingStep.identifier === onboardingStepId
    )
    // If the user doesn't have the onboarding step, we consider it completed
    if (!userOnboardingStep) {
      return true
    }
    return Boolean(
      userOnboardingStep.completedAt || userOnboardingStep.skippedAt
    )
  }
)

export const selectCompletedAtForOnboardingStep = createSelector(
  selectUserOnboardingStepsWithOnboardingSteps,
  (_: unknown, onboardingStepId: OnboardingStepId) => onboardingStepId,
  (userOnboardingSteps, onboardingStepId) => {
    const userOnboardingStep = userOnboardingSteps.find(
      (step) => step.onboardingStep.identifier === onboardingStepId
    )
    return userOnboardingStep?.completedAt
  }
)

export const selectHasUserCompletedOnboardingPhase = createSelector(
  getUserOnboardingSteps,
  getAllOnboardingSteps,
  (_: unknown, phase: OnboardingStepPhase) => phase,
  (userOnboardingSteps, allOnboardingSteps, phase) => {
    const onboardingStepsForPhase = Object.values(allOnboardingSteps).filter(
      (step) => step.phase === phase
    )
    const userOnboardingStepsForPhase = Object.values(
      userOnboardingSteps
    ).filter((userOnboardingStep) =>
      onboardingStepsForPhase
        .map((step) => step.identifier)
        .includes(userOnboardingStep.onboardingStepId)
    )
    // If the user doesn't have any onboarding items for the page, we consider the phase completed
    if (userOnboardingStepsForPhase.length === 0) {
      return true
    }
    return userOnboardingStepsForPhase.every(
      (step) => step.completedAt || step.skippedAt
    )
  }
)

export const selectLastStartedAtStepForOnboardingPhase = createSelector(
  selectUserOnboardingStepsWithOnboardingSteps,
  (_: unknown, phase: OnboardingStepPhase) => phase,
  (userOnboardingSteps, phase) => {
    const onboardingStepsForPhase = userOnboardingSteps.filter(
      (step) => step.onboardingStep.phase === phase
    )
    const lastStartedAtStep = onboardingStepsForPhase
      .filter((step) => step.startedAt)
      .sort((a, b) => {
        if (!a.startedAt || !b.startedAt) {
          return 0
        }
        return (
          DateTime.fromISO(b.startedAt).toMillis() -
          DateTime.fromISO(a.startedAt).toMillis()
        )
      })[0]
    return lastStartedAtStep
  }
)
// Getting Started Steps
const gettingStartedGroupMapping: Record<
  GettingStartedOnboardingStepId,
  GettingStartedGroup
> = {
  [GettingStartedOnboardingStep.joinGroupOnboardingSession]:
    GettingStartedGroup.getHelp,
  [GettingStartedOnboardingStep.uploadBankStatements]:
    GettingStartedGroup.bookkeeping,
  [GettingStartedOnboardingStep.setupAutomaticStatementUpload]:
    GettingStartedGroup.bookkeeping,
  [GettingStartedOnboardingStep.clarifyUncategorizedTransactions]:
    GettingStartedGroup.bookkeeping,
  [GettingStartedOnboardingStep.connectBusinessBankAccount]:
    GettingStartedGroup.accountSetup,
  [GettingStartedOnboardingStep.yearEndWrapUpSurvey]: GettingStartedGroup.taxes,
}

export const selectGettingStartedUserOnboardingStepsWithGroups = createSelector(
  selectUserOnboardingStepsWithOnboardingSteps,
  (userOnboardingSteps) =>
    userOnboardingSteps
      .filter<GettingStartedUserOnboardingStepExpanded>(
        isGettingStartedUserOnboardingStepExpanded
      )
      .map((step) => {
        return {
          ...step,
          group: gettingStartedGroupMapping[step.onboardingStep.identifier],
        }
      })
)

export const selectPartitionedGettingStartedUserOnboardingStepData =
  createSelector(
    selectGettingStartedUserOnboardingStepsWithGroups,
    (_: unknown, priorityList: Array<GettingStartedOnboardingStepId>) =>
      priorityList,
    (userOnboardingSteps, priorityList) => {
      const [stepsCompletedOrSkipped, stepsNotSkippedOrCompleted] = partition(
        userOnboardingSteps,
        (step) => step.completedAt || step.skippedAt
      )
      const stepsCompletedOrSkippedIds = stepsCompletedOrSkipped.map(
        (step) => step.onboardingStep.identifier
      )
      // Get the top 5 steps that are not skipped or completed
      // priorityList has a list off all possible steps (more than 5) in the order of priority.
      const topFiveIncompleteStepIds = priorityList
        .filter((priorityListStepId) =>
          stepsNotSkippedOrCompleted.find(
            (step) => step.onboardingStep.identifier === priorityListStepId
          )
        )
        .slice(0, 5) // Take the first 5 steps that are not skipped or completed

      // Get count of remaining steps that are not skipped or completed
      const upcomingStepsCount =
        stepsNotSkippedOrCompleted.length - topFiveIncompleteStepIds.length

      return {
        onboardingStepsWithGroups: userOnboardingSteps,
        stepsCompletedOrSkippedIds,
        topFiveIncompleteStepIds,
        upcomingStepsCount,
      }
    }
  )
