import { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { uniq } from 'lodash'
import './styles.scss'

import {
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import { AddTransactionCard } from '../../../../components/Transactions/AddTransactionCard'
import { TransactionCategoryIdentifier } from '../../../../reducers/admin/allTransactions.slice'
import { StepHeaders } from '../../../../components/StepHeader'
import type { Colors } from '../../../../styles/theme'

const filterCategories = [
  TransactionCategoryIdentifier.client_discounts_refunds,
  TransactionCategoryIdentifier.consulting_income,
  TransactionCategoryIdentifier.other_income,
  TransactionCategoryIdentifier.retail_income,
  TransactionCategoryIdentifier.speaking_income,
  TransactionCategoryIdentifier.supervision_income,
  TransactionCategoryIdentifier.teaching_income,
  TransactionCategoryIdentifier.uncategorized_income,
]

export const BusinessIncomeInPersonal = () => {
  const [showAddTransactionModal, setShowAddTransactionModal] = useState(false)
  const [addedTransactionIds, setAddedTransactionIds] = useState<number[]>([])
  const [editingTransactionId, setEditingTransactionId] = useState<number>()
  const [transcationModalColor, setTranscationModalColor] =
    useState<keyof typeof Colors>('natural')
  const scrollRef = useRef<HTMLSpanElement>(null)
  const scrollToRef = useCallback(
    () => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }),
    []
  )
  const [hasBusinessIncomeToAdd, setHasBusinessIncomeToAdd] = useState(false)

  useEffect(() => {
    if (showAddTransactionModal) {
      scrollToRef()
    }
  }, [scrollToRef, showAddTransactionModal])

  useEffect(() => {
    if (!hasBusinessIncomeToAdd) {
      setShowAddTransactionModal(false)
    }
  }, [hasBusinessIncomeToAdd])

  useEffect(() => {
    if (!showAddTransactionModal) {
      setEditingTransactionId(undefined)
    }
  }, [showAddTransactionModal])

  const addTransactionToList = (transactionId: number) => {
    setAddedTransactionIds(uniq([...addedTransactionIds, transactionId]))
  }

  const editTransaction = (transactionId: number) => {
    setShowAddTransactionModal(true)
    setEditingTransactionId(transactionId)
    setTranscationModalColor('stone')
  }

  const deleteTransaction = (transactionId: number) => {
    setAddedTransactionIds(
      addedTransactionIds.filter((tId) => tId !== transactionId)
    )
  }

  return (
    <Grid>
      <GridRowColumn centerContent>
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/bookkeeping.svg"
          imageAlt="bookkeeping"
          imageWidth={180}
          imageHeight={180}
          title="Do you have business income from these categories in your personal bank accounts?"
          customColor="green"
        >
          <Grid>
            <Grid.Row className="list-column">
              <Grid.Column width={8}>
                <List bulleted className="flex-column">
                  <List.Item>Client Discounts/Refunds</List.Item>
                  <List.Item>Consulting Income</List.Item>
                  <List.Item>Retail Income</List.Item>
                  <List.Item>Other Income</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={8}>
                <List bulleted className="flex-column">
                  <List.Item>Speaking Income</List.Item>
                  <List.Item>Supervision Income</List.Item>
                  <List.Item>Teaching Income</List.Item>
                  <List.Item>Uncategorized Income</List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn className="align-left">
              <Text>
                We ask this to ensure that we account for any income that may
                have mistakenly gone to a personal account.
              </Text>
            </GridRowColumn>
          </Grid>
        </StepHeaders>
      </GridRowColumn>

      <Grid.Row className="buttons-grid">
        <Grid.Column width={6} />
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessIncomeToAdd(true)}
            variant="selector"
            fullWidth
            active={hasBusinessIncomeToAdd}
          >
            Yes
          </Button>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessIncomeToAdd(false)}
            variant="selector"
            fullWidth
            active={!hasBusinessIncomeToAdd}
          >
            No
          </Button>
        </Grid.Column>
      </Grid.Row>
      {hasBusinessIncomeToAdd && (
        <>
          <GridRowColumn>
            <Text textAlign="center">
              Please add transactions that haven’t already been recorded in
              Heard.
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={6} width={4}>
            <Button
              variant="secondary"
              fullWidth
              onClick={() => {
                setEditingTransactionId(undefined)
                setTranscationModalColor('natural')
                setShowAddTransactionModal(true)
              }}
            >
              <Icon icon={regular('plus')} style={{ marginRight: 10 }} />
              Add Transaction
            </Button>
          </GridRowColumn>
          <Grid.Row />
          {showAddTransactionModal && (
            <>
              <GridRowColumn spacer={2} width={12}>
                <span ref={scrollRef} />
                <AddTransactionCard
                  onClose={() => setShowAddTransactionModal(false)}
                  onSave={addTransactionToList}
                  transactionId={editingTransactionId}
                  categoryType="Income"
                  categoryFilter={filterCategories}
                  backgroundColor={transcationModalColor}
                />
              </GridRowColumn>
              <Grid.Row />
            </>
          )}

          {addedTransactionIds.length > 0 && <TransactionHeader />}
          <GridRowColumn short className="transaction-grid">
            {addedTransactionIds.map((id) => (
              <TransactionCard
                key={id}
                transactionId={id}
                variant="edit"
                editAction={editTransaction}
                deleteAction={deleteTransaction}
                backgroundColor="stone"
              />
            ))}
          </GridRowColumn>
        </>
      )}
    </Grid>
  )
}

export default BusinessIncomeInPersonal
