import { useCallback, useState } from 'react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Button,
  GridRowColumn,
  Icon,
  Link,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxDetails } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
  formatDateWithOrdinal,
} from '../../../../utils/dateHelpers'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../annualTaxFilingForms.selector'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { getIsFetching } from '../../../../reducers/fetch'
import {
  getAnnualTaxFilingForYearSelector,
  selectExtensionSurveyComplete,
  selectFilingStatusForForm,
  selectHasIncompleteExtension,
  selectShowLateFilingAlert,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import { LateFilingAlert } from '../components/LateFilingAlert'
import {
  ExtensionReason,
  REQUEST_EXTENSION,
  requestExtension,
} from '../annualTaxFilings.slice'

// NOTE: This component can currently live in places where the tax checklist hasn't been completed
const ExtensionAlert = ({
  is1040,
  inChecklist = false,
}: {
  is1040: boolean
  inChecklist?: boolean
}) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const now = DateTime.now()

  const year = annualTaxDetails?.taxYear || now.year.toString()
  const hasIncompleteExtension = useReselector(
    selectHasIncompleteExtension,
    year
  )
  const extensionSurveyComplete = useReselector(
    selectExtensionSurveyComplete,
    year
  )

  const isUpdating = useReselector(getIsFetching, REQUEST_EXTENSION)
  const form1040 = useReselector(
    select1040FormForYear,
    annualTaxDetails?.taxYear
  )
  const form1120s = useReselector(
    select1120sFormForYear,
    annualTaxDetails?.taxYear
  )
  const filing = useReselector(getAnnualTaxFilingForYearSelector, year)

  const form = is1040 ? form1040 : form1120s
  const extensionRequested = Boolean(filing?.extensionRequestedAt)
  const isTwoFormFiler = Boolean(form1040 && form1120s)
  const filingStatus = useReselector(selectFilingStatusForForm, form?.id)

  const irsDueDate = is1040
    ? annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate
    : annualTaxDetails?.irsFormDueDates.form_1120_s.irs.dueDate
  const irsDueDateTime = irsDueDate
    ? convertUtcToLocalDate(irsDueDate)
    : undefined
  const requestExtensionCutoff = irsDueDateTime?.minus({ days: 4 })

  const extendedDueDate = is1040
    ? annualTaxDetails?.irsFormDueDates.form_1040.irs.extendedDueDate
    : annualTaxDetails?.irsFormDueDates.form_1120_s.irs.extendedDueDate
  const cutoffDateTime = extendedDueDate
    ? convertUtcToLocalDate(extendedDueDate)
    : undefined

  // INTERNAL FORM_1120_S DUE
  const showAlertStart = annualTaxDetails?.extensionSurveyStartAt
    ? DateTime.fromISO(annualTaxDetails.extensionSurveyStartAt)
    : undefined

  const showLateFilingAlert = useReselector(selectShowLateFilingAlert)

  const updateNeedsExtension = useCallback(async () => {
    await dispatch(requestExtension(ExtensionReason.userRequested))
    setModalOpen(false)
  }, [dispatch])

  if (
    // Form doesn't exist
    !form ||
    // Not time to show yet
    !showAlertStart ||
    now < showAlertStart ||
    // After cutoff date
    !cutoffDateTime ||
    now > cutoffDateTime ||
    // Extension already completed
    extensionSurveyComplete
  ) {
    return null
  }

  if (
    !extensionRequested &&
    requestExtensionCutoff?.isValid &&
    now <= requestExtensionCutoff?.endOf('day')
  ) {
    return (
      <GridRowColumn>
        <Alert
          type="warn"
          title="We recommend filing an extension for your tax return"
        >
          Filing an extension protects you from “failure to file” penalties and
          interest, and buys you more time to gather all of your tax
          information. The last day to submit our Extension Request Survey is{' '}
          {requestExtensionCutoff?.isValid &&
            formatDateWithOrdinal(
              requestExtensionCutoff,
              DATE_FORMATS_LUXON.MONTH_DAY_LONG
            )}
          . If you don’t submit the survey by{' '}
          {requestExtensionCutoff?.isValid &&
            formatDateWithOrdinal(
              requestExtensionCutoff,
              DATE_FORMATS_LUXON.MONTH_DAY_LONG
            )}
          , Heard cannot file an extension for you.{' '}
          <Button variant="link" onClick={() => setModalOpen(true)}>
            Request Extension{' '}
            <Icon
              style={{ marginLeft: 8 }}
              size="1x"
              icon={regular('arrow-right')}
            />
          </Button>
        </Alert>
        <Modal closeIcon onClose={() => setModalOpen(false)} open={modalOpen}>
          <Modal.Header>Request Extension</Modal.Header>
          <Modal.Content>
            <Text as="bodyLg">
              Filing an extension is a common practice to ensure there’s enough
              time to file an accurate, quality tax return for you and protect
              you from “failure to file” penalties and interest.
              <br />
              <br />
              By selecting <b>Request Extension</b>, you’ve read and agreed to
              the following:
              <br />
              <ol style={{ paddingLeft: 30 }}>
                <li>
                  Once you’ve made this request, you won’t be able to cancel or
                  change it. You’ll first complete the Extension Request Survey
                  before continuing to fill out your Tax Checklist.
                </li>
                <br />
                <li>
                  You’ll need to submit the survey by{' '}
                  {requestExtensionCutoff?.toFormat(
                    DATE_FORMATS_LUXON.MONTH_DAY_LONG
                  )}{' '}
                  to give your tax preparer enough time to file your extension
                  by the IRS{' '}
                  {irsDueDateTime?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY_LONG)}{' '}
                  deadline.
                </li>
                <br />
                <li>
                  When you file an extension, you’ll still be responsible for
                  paying an estimated tax bill to the IRS and to your state by{' '}
                  {irsDueDateTime?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY_LONG)}.
                  Your tax preparer will provide these estimates for you.
                </li>
              </ol>
            </Text>
          </Modal.Content>
          <Modal.Actions>
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={updateNeedsExtension}
              loading={isUpdating}
              disabled={isUpdating}
            >
              Request Extension
            </Button>
          </Modal.Actions>
        </Modal>
      </GridRowColumn>
    )
  }

  // If it's too late for a new extension
  if (requestExtensionCutoff && now <= requestExtensionCutoff.endOf('day')) {
    // Form is already extended but they haven't completed the survey
    if (hasIncompleteExtension) {
      return (
        <GridRowColumn>
          <Alert type="warn" title="Finish your Extension Request Survey">
            Filing an extension protects you from “failure to file” penalties
            and interest, and buys you more time to gather all of your tax
            information. Please submit the survey by April 11th, otherwise Heard
            cannot file an extension for you.
          </Alert>
        </GridRowColumn>
      )
    }
  } else if (
    !hasIncompleteExtension &&
    filingStatus !== AnnualTaxFilingStepStatus.completeLocked
  ) {
    if (isTwoFormFiler && !inChecklist) {
      // Prevents 2 errors from showing at the same time in the tax center
      if (!is1040) {
        return null
      }
      return showLateFilingAlert ? (
        <LateFilingAlert />
      ) : (
        <GridRowColumn>
          <Alert type="warn" title="Complete Your Tax Checklist(s) ASAP">
            If you haven’t filed an extension for your business and/or personal
            tax return, you may have to pay “failure to file” penalties and
            interest. As a result, the longer you take to finish your checklist,
            the more interest will accrue on top of your penalties.{' '}
            <Link
              href="https://www.irs.gov/payments/failure-to-file-penalty"
              newPage
            >
              Learn more
            </Link>
          </Alert>
        </GridRowColumn>
      )
    }
    return showLateFilingAlert ? (
      <LateFilingAlert />
    ) : (
      <GridRowColumn>
        <Alert
          type="warn"
          title={`Complete Your ${is1040 ? 'Personal' : 'Business'} Tax Checklist ASAP`}
        >
          If you haven’t filed an extension for your{' '}
          {is1040 ? 'personal' : 'business'} tax return, you may have to pay
          “failure to file” penalties and interest. As a result, the longer you
          take to finish your checklist, the more interest will accrue on top of
          your penalties.{' '}
          <Link
            href="https://www.irs.gov/payments/failure-to-file-penalty"
            newPage
          >
            Learn more
          </Link>
        </Alert>
      </GridRowColumn>
    )
  }

  return null
}

export default ExtensionAlert
