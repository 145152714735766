import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
  Pill,
} from '../../../components/BaseComponents'
import { track } from '@amplitude/analytics-browser'
import { Colors } from '../../../styles/theme'
import { useNavigate } from 'react-router-dom'
import { useReselector } from '../../../utils/sharedHooks'
import { getCurrentUser } from '../../../selectors/user.selectors'
import {
  FinancialAdvisoryFeatureFlagValues,
  FinancialAdvisoryProfilesStatus,
} from '../../FinancialAdvisory/financialAdvisory.slice'
import { useStringFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import {
  fetchAdvisoryPrices,
  PROD_ADVISORY_PRICING,
} from '../../../constants/pricingConstants'
import { selectFinancialAdvisoryProfile } from '../../FinancialAdvisory/financialAdvisory.selectors'
import { userHasFinancialAdvisoryAccess } from '../../FinancialAdvisory/service'
import { useCallback, useState } from 'react'
import {
  StripeLineItem,
  fetchCheckoutSession,
} from '../../../actions/settings/billingActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

const AdvisoryLaunchCard = () => {
  const navigate = useNavigate()
  const currentUser = useReselector(getCurrentUser)
  const ffValue = useStringFlagValue(
    FEATURE_FLAG_KEYS.financialAdvisoryPilot,
    FinancialAdvisoryFeatureFlagValues.noPilot
  )
  const handleLearnMoreButtonClick = () => {
    track('clicked financial advisory phase 2 dashboard card button', {
      user_id: currentUser?.id,
      //  Tracks user phase
      user_phase: ffValue,
    })
    navigate('/advisory/overview')
  }
  // Only show Dashboard if user has financialAdvisoryProfile (means they have access) and user has not paid
  return (
    <Card backgroundColor="forest" className={'mobile-squared-corners'}>
      <Grid>
        <GridRowColumn>
          <Pill color="neutral">★ New Add On</Pill>
          <br />
          <Text as="h2" color={'white'}>
            Introducing: Financial Advisory{' '}
          </Text>
        </GridRowColumn>
        <GridRowColumn
          columnStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/financial_advice.svg"
            style={{ height: 150 }}
            alt="financial advisory"
          />
        </GridRowColumn>
        <GridRowColumn>
          <Text color="white" as="bodyMd">
            Managing your finances as a therapist can be challenging, but it
            doesn’t have to be.
            <br />
            <br />
            Our Financial Advisory Add On provides you with expert, personalized
            support to help you achieve both your personal <b>and</b> business
            financial goals with confidence — all for just $
            {PROD_ADVISORY_PRICING.monthly.amount} per month.
          </Text>
          <br />
          <Text color="white" as="bodyMd">
            Ready to get started? Schedule your free 20 minute Introductory
            Advisory Call with a Certified Financial Planner (CFP®) to learn
            more.
          </Text>
          <br />

          <Button
            style={{ backgroundColor: Colors.white, color: Colors.black }}
            onClick={handleLearnMoreButtonClick}
          >
            Learn More
          </Button>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

const AdvisoryPurchaseCard = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const currentUser = useReselector(getCurrentUser)
  const advisoryPrices = fetchAdvisoryPrices()
  const annualPlan = advisoryPrices.annual.priceId
  const monthlyPlan = advisoryPrices.monthly.priceId

  /**
   * Navs to Payment
   */
  const navToPayment = useCallback(
    async (selectedPriceId: string) => {
      setLoading(true)
      if (!currentUser?.id || !selectedPriceId) {
        return
      }

      track('click nav to advisory purchase — dashboard card ', {
        user_id: currentUser.id,
      })

      const lineItems: StripeLineItem[] = [
        {
          priceId: selectedPriceId,
          quantity: 1,
        },
      ]
      const metadata: Record<string, string> = {}

      let subscriptionCheckoutUrl: string | null = null
      const res = await fetchCheckoutSession({
        userId: currentUser.id,
        lineItems,
        success_url: {
          url: '/advisory/overview?paymentComplete=true',
        },
        // Navigate back to current page with query params
        cancel_url: {
          url: `${location.pathname}${location.search}`,
        },
        freeTrialEnabled: undefined,
        freeTrialSearchParamValue: null,
        metadata,
      })(dispatch)
      if (res) {
        subscriptionCheckoutUrl = res.url
      }

      if (subscriptionCheckoutUrl !== null) {
        setLoading(false)

        window.location.href = subscriptionCheckoutUrl
      }
    },
    [currentUser?.id, dispatch]
  )

  return (
    <Card backgroundColor="moss" className={'mobile-squared-corners'}>
      <Grid>
        <GridRowColumn>
          <Pill color="green">Almost Complete</Pill>
          <br />
          <Text as="h2">Add On Financial Advisory</Text>
        </GridRowColumn>
        <GridRowColumn
          columnStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/advisory_screen.svg"
            style={{ width: '100%' }}
            alt="financial advisory"
          />
        </GridRowColumn>
        <GridRowColumn>
          <Text as="bodyMd">
            We hoped you enjoyed your free introductory call with a Certified
            Financial Planner! To unlock expert financial guidance for your
            private practice, add the Advisory add on and receive your 100%
            personalized, comprehensive financial plan.
          </Text>
        </GridRowColumn>
        <GridRowColumn centered textAlign="center">
          <Button
            variant="primary"
            fullWidth
            loading={loading}
            disabled={loading}
            onClick={() => navToPayment(annualPlan)}
          >
            Get Started with Annual (Save 15%)
          </Button>
          <br />
          <Button
            variant="secondary"
            fullWidth
            loading={loading}
            disabled={loading}
            onClick={() => navToPayment(monthlyPlan)}
          >
            Get Started with Monthly
          </Button>
          <br />
          <Text as="bodyMd" color="darkGray">
            $29 per month, or $300 per year
          </Text>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

export const AdvisoryDashboardCard = () => {
  const faProfile = useReselector(selectFinancialAdvisoryProfile)

  const hasAdvisoryAccess = userHasFinancialAdvisoryAccess()
  // Only show Dashboard if user has financialAdvisoryProfile (means they have access) and user has not paid
  /**
   * 1. CTA to Try Advisory
   *
   * This shows when users have Access to fianncial advisory, but have not scheduled a call or tried it out
   */
  if (
    hasAdvisoryAccess &&
    faProfile?.status === FinancialAdvisoryProfilesStatus.profile_generated
  ) {
    return <AdvisoryLaunchCard />
  } else if (
    /**
     * 2. Post Introductory 20 Minute Call
     *
     * This shows when users have Access to financial advisory, and have attended their intro call, but have not purchased Advisory
     */
    hasAdvisoryAccess &&
    faProfile?.status ===
      FinancialAdvisoryProfilesStatus.intro_call_completed &&
    faProfile?.subscriptionStatus !== 'paid'
  ) {
    return <AdvisoryPurchaseCard />
    /**
     * Otherwise, do not show card
     */
  } else {
    return null
  }
}

export default AdvisoryDashboardCard
