import { Grid, Image } from 'semantic-ui-react'
import {
  Accordion,
  Card,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ReactNode, useCallback } from 'react'
import { useFlyout } from '../../../../utils/sharedHooks'
import SideBarEntityCard from './SideBarEntityCard'

export enum TAX_SEASON_KICKOFF_SURVEY {
  taxEntity = 'taxEntity',
  scorpConfirmation = 'scorpConfirmation',
  solePropTaxEntityHelp = 'solePropTaxEntityHelp',
  scorpTaxEntityHelp = 'scorpTaxEntityHelp',
  helpSubmission = 'helpSubmission',
  contractorPayroll = 'contractorPayroll',
  taxNeeds = 'taxNeeds',
  review = 'review',
  cCorpDisqualified = 'cCorpDisqualified',
}

export type QuestionItem = { index: number; title: string; content: ReactNode }

export type SideBarContent = {
  title: string
  tip: string
  questionItems: QuestionItem[]
} | null

export type SideBarConfig = Record<TAX_SEASON_KICKOFF_SURVEY, SideBarContent>

const defaultTaxEntitySideBarContent = {
  title: 'Tax entity tips',
  tip: 'Your text entity is not always the same as your legal business entity type.',
  questionItems: [
    {
      index: 0,
      title:
        'What is a tax entity, and how is it different from a business entity?',
      content: (
        <Text>
          A tax entity refers to how a business is classified by the IRS or
          other tax authorities for tax purposes.
          <br />
          <br />
          Your tax entity is not necessarily tied to its legal entity type; a
          business may be treated one way legally but elect a different
          classification for tax purposes.
          <br />
          <br />
          <Link
            newPage
            href="https://support.joinheard.com/hc/en-us/articles/4476985482519-What-is-the-difference-Between-a-Business-Entity-and-a-Tax-Entity"
            as="secondaryLink"
          >
            Learn more
          </Link>{' '}
          about the differences between tax and legal entities
        </Text>
      ),
    },
    {
      index: 1,
      title: 'What are the different entity types?',
      content: (
        <>
          <SideBarEntityCard
            image="https://heard-images.s3.amazonaws.com/assets/chair.svg"
            title="Sole proprietor"
            subtitle="Business & personal finances are tied together"
            description={
              <Text as="bodyLg">
                You’re a sole proprietor if:
                <ul>
                  <li>
                    You haven’t formally registered your business as a separate
                    legal entity
                  </li>
                  <li>
                    You file your business income and expenses on your personal
                    tax return
                  </li>
                </ul>
              </Text>
            }
          />
          <SideBarEntityCard
            image="https://heard-images.s3.amazonaws.com/assets/bank-green.svg"
            title="S corporation"
            subtitle="Tax optimized business entity"
            description={
              <Text as="bodyLg">
                You’re an S corporation if at least one of the following apply:
                <ul>
                  <li>
                    You’ve submitted a Form 2553 and it has <b>been accepted</b>{' '}
                    by the IRS/state.
                  </li>
                  <li>You’ve received a Form CP261</li>
                  <li>
                    You’ve successfully filed a 1120-S tax return in prior years
                  </li>
                </ul>
              </Text>
            }
          />
          <SideBarEntityCard
            image="https://heard-images.s3.amazonaws.com/assets/split-money.svg"
            title="C corporation"
            subtitle="Business entity that is separate from owner"
            description={
              <Text as="bodyLg">
                A C corporation is a legal entity that is separate from its
                owners and is owned by its shareholders. You would have applied
                specifically to become a C corporation.
                <br />
                <br />
                Unfortunately, if you are a C corporation, but have not elected
                to be taxed as an S-corporation, Heard is unable to service your
                accounting & tax needs.
              </Text>
            }
          />
        </>
      ),
    },
  ],
}

export const tskSideBarConfig: SideBarConfig = {
  [TAX_SEASON_KICKOFF_SURVEY.taxEntity]: defaultTaxEntitySideBarContent,
  [TAX_SEASON_KICKOFF_SURVEY.scorpConfirmation]: {
    title: 'Verifying S corporation tips',
    tip: 'The IRS doesn’t allow organizations like Heard to obtain a copy of your S corporation approval letter (Form CP261) on your behalf. If you need another copy of this, the IRS requires that you call them directly at 800-829-4933.',
    questionItems: [
      {
        index: 0,
        title: 'What is a S corporation approval letter (Form CP261)?',
        content: (
          <Text>
            The IRS issues S corporation approval letter (Form CP261) to
            business owners within 60 days of accepting their S corporation
            election. It verifies that the IRS recognizes your S corporation.
            <br />
            <Link
              newPage
              href="https://support.joinheard.com/hc/en-us/articles/21742106917015-S-Corp-Elections-Form-CP261-LTR385C"
              as="secondaryLink"
            >
              Learn more
            </Link>
          </Text>
        ),
      },
      {
        index: 1,
        title:
          'What does a S corporation approval letter (Form CP261) look like?',
        content: (
          <>
            <Link
              href="https://heard-images.s3.amazonaws.com/assets/example_cp261.svg"
              newPage
              as="secondaryLink"
            >
              View Full Size
            </Link>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/example_cp261.svg"
              style={{ width: 200 }}
            />
          </>
        ),
      },
      {
        index: 2,
        title: 'Why do I need to upload this document?',
        content: (
          <Text>
            Even though you’ve applied to be an S-corporation, the IRS may not
            have approved your application. An approval letter ensures that the
            IRS actually considers you as an S corporation. Until this is
            confirmed, you may be at risk of having your tax return rejected.
            <br />
            <br />
            Additionally, the approval letter lets us know when your S
            corporation election date is, which we need to generate an accurate
            balance sheet and profit & loss statement. These are necessary to
            file your business tax return.
          </Text>
        ),
      },
      {
        index: 3,
        title:
          'What if I don’t have a S corporation approval letter (Form CP261)?',
        content: (
          <Text>
            If you haven’t yet received this document, it could be due to the
            following reasons:
            <ul>
              <li>
                If you have recently submitted your election request, it can
                take the IRS up to 60 days to send confirmation to you
              </li>
              <li>
                Your election request may have been delayed, rejected by the
                IRS, or lost in transit
              </li>
            </ul>
            <br />
            If you aren’t able to find your CP261, you can call the IRS at
            800-829-4933 to obtain another copy (Form 385C).
            <br />
            <br />
            <b>
              You’ll want to do this right away, since it can take up to 4 to 6
              weeks after requesting a copy to receive it.
            </b>
          </Text>
        ),
      },
    ],
  },
  [TAX_SEASON_KICKOFF_SURVEY.solePropTaxEntityHelp]:
    defaultTaxEntitySideBarContent,
  [TAX_SEASON_KICKOFF_SURVEY.scorpTaxEntityHelp]:
    defaultTaxEntitySideBarContent,
  [TAX_SEASON_KICKOFF_SURVEY.helpSubmission]: defaultTaxEntitySideBarContent,
  [TAX_SEASON_KICKOFF_SURVEY.contractorPayroll]: {
    title: '1099-NEC tips',
    tip: 'If you used a payroll provider to pay your contractor, they’ll file your 1099-NEC for you.',
    questionItems: [
      {
        index: 0,
        title: 'What is a 1099-NEC and why would I need to file one?',
        content: (
          <Text>
            If you’ve paid a contractor{' '}
            <b>at least $600 with cash, checks or bank transfers in 2024</b>,
            you’ll need to file a 1099-NEC so your contractor can use it to
            report their earnings to the IRS.
            <br />
            If you paid a contractor through a payment or credit card processor
            (i.e. Square, Stripe, Visa, Mastercard), they’ll file the 1099 for
            you.
            <br />
            If you paid a contractor through a payroll provider ( e.g. Gusto),
            the provider will file the 1099 for you.
            <br />
            The IRS deadline to file 1099-NECs is <b>January 31.</b> We highly
            encourage you to file your 1099s by this date to avoid potential
            penalties.
          </Text>
        ),
      },
      {
        index: 1,
        title: 'What does a 1099-NEC look like?',
        content: (
          <>
            <Link
              href="https://heard-images.s3.amazonaws.com/assets/example_1099-NEC.svg"
              newPage
              as="secondaryLink"
            >
              View Full Size
            </Link>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/example_1099-NEC.svg"
              style={{ width: 200 }}
            />
          </>
        ),
      },
    ],
  },
  [TAX_SEASON_KICKOFF_SURVEY.taxNeeds]: null,
  [TAX_SEASON_KICKOFF_SURVEY.review]: null,
  [TAX_SEASON_KICKOFF_SURVEY.cCorpDisqualified]: defaultTaxEntitySideBarContent,
}

export const useTipsSideBar = ({
  currentScreenSideBarConfig,
}: {
  currentScreenSideBarConfig: SideBarContent
}) => {
  const { open: flyoutOpen, openFlyout, closeFlyout } = useFlyout()

  const openTipsSidebar = useCallback(() => {
    openFlyout({
      content: (
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <Text as="h2">{currentScreenSideBarConfig?.title}</Text>
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            <Card style={{ backgroundColor: Colors.stone40 }}>
              <Text as="eyebrow">
                <Icon icon={light('ribbon')} style={{ marginRight: 5 }} />
                Did you know?
              </Text>
              <br />
              <Text as="h3">{currentScreenSideBarConfig?.tip}</Text>
            </Card>
          </GridRowColumn>
          <GridRowColumn>
            {currentScreenSideBarConfig?.questionItems.map((item) => (
              <Accordion
                key={item.index}
                title={item.title}
                content={item.content}
                variant="text"
                style={{ marginBottom: 16 }}
              />
            ))}
          </GridRowColumn>
        </Grid>
      ),
      direction: 'right',
    })
  }, [openFlyout, currentScreenSideBarConfig])

  return {
    flyoutOpen,
    openTipsSidebar,
    closeTipsSidebar: closeFlyout,
  }
}

export default useTipsSideBar
