import { TransactionOrSplit } from '../../reducers/admin/allTransactions.slice'
import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { Button, Text } from '../BaseComponents'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import { DateTime } from 'luxon'
import { formatCurrencyFromCents } from '../../utils/currencyHelpers'
import TransactionNoteModal from '../../features/Transactions/TransactionNoteModal'
import './TransactionRow.scss'

export const TransactionRow = ({
  transaction,
}: {
  transaction: TransactionOrSplit
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { date, description, notes, amountInCents } = transaction

  return (
    <Grid.Row verticalAlign="middle" className="transaction-row-body">
      <Grid.Column width={3}>
        <Text as="bodyLg">
          {DateTime.fromISO(date)
            .toUTC()
            .toFormat(DATE_FORMATS_LUXON.DISPLAY_ABBR)}
        </Text>
      </Grid.Column>
      <Grid.Column width={8} className="note-body">
        <Text as="bodyLg">{description}</Text>
        <Text as="bodySm">{notes}</Text>
      </Grid.Column>
      <Grid.Column width={5}>
        <div className="currency-note-grid">
          <Text as="bodyLg" textAlign="right">
            {formatCurrencyFromCents(amountInCents)}
          </Text>
          <Button
            variant="link"
            onClick={() => setModalOpen(true)}
            className="note-button"
          >
            {notes ? 'Edit Note' : 'Add Note'}
          </Button>
        </div>
      </Grid.Column>
      {modalOpen && (
        <TransactionNoteModal
          transaction={transaction}
          open={modalOpen}
          close={() => setModalOpen(false)}
        />
      )}
    </Grid.Row>
  )
}
