import {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Accordion as SemanticAccordion,
  StrictAccordionProps,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Colors } from '../../styles/theme'
import Text from './Text'
import Icon from './Icon'
import { omit } from 'lodash'
import {
  useAnalyticsTrack,
  Attributes as AnalyticsAttributes,
} from '../../features/Amplitude'
import Tooltip from '../../features/Taxes/QuarterlyTaxEstimates/components/Tooltip'

interface AccordionProps extends StrictAccordionProps {
  title: ReactNode
  titleStyle?: CSSProperties
  content: ReactNode | ((open: boolean) => ReactElement<{ open?: boolean }>)
  variant?: 'default' | 'text' | 'old'
  contentStyle?: CSSProperties
  backgroundColor?: keyof typeof Colors
  iconColor?: keyof typeof Colors
  style?: CSSProperties
  initialOpen?: boolean
  disabled?: boolean
  trackOnOpenClick?: {
    screen: string
    title: string
    rest?: AnalyticsAttributes
  }
  onOpenClick?: (open: boolean) => void
  tooltip?: {
    title: string
    body: string
  }
}

const Accordion = styled(
  ({
    className,
    title,
    titleStyle,
    content,
    contentStyle,
    disabled,
    // This is set to old for now for backwards compat but we should move forward with default
    variant = 'old',
    // Changing this value will change the accordion's open/close state
    initialOpen = false,
    iconColor,
    trackOnOpenClick,
    onOpenClick,
    tooltip,
    ...rest
  }: AccordionProps) => {
    const [open, setOpen] = useState(initialOpen)
    const track = useAnalyticsTrack()

    useEffect(() => {
      setOpen(initialOpen)
    }, [initialOpen])

    const onClick = useCallback(() => {
      const newOpen = !open
      if (onOpenClick) {
        onOpenClick(newOpen)
      }
      setOpen(newOpen)
      if (newOpen && trackOnOpenClick) {
        track('clicked accordion open', {
          screen: trackOnOpenClick.screen,
          title: trackOnOpenClick.title,
          ...trackOnOpenClick.rest,
        })
      }
    }, [onOpenClick, open, track, trackOnOpenClick])

    const contentToDisplay = useMemo(() => {
      if (typeof content === 'function') {
        return content(open)
      } else if (typeof content === 'string' && variant === 'default') {
        return <Text>{content}</Text>
      }
      return content
    }, [content, open, variant])

    return (
      <SemanticAccordion
        className={`${className} ${variant}`}
        {...omit(rest, 'backgroundColor')}
      >
        <SemanticAccordion.Title
          active={open}
          onClick={onClick}
          style={titleStyle}
        >
          {typeof title === 'string' ? (
            <Text as="h3" color="green">
              {title}
            </Text>
          ) : (
            title
          )}
          {['old', 'default'].includes(variant) && !disabled && (
            <Icon
              icon={open ? regular('minus') : regular('plus')}
              className="expand-icon"
              color={iconColor}
            />
          )}
          {variant === 'text' && !disabled && (
            <Icon
              icon={open ? regular('chevron-up') : regular('chevron-down')}
              className="expand-icon"
              size="1x"
              color={iconColor}
            />
          )}
          {tooltip && (
            <Tooltip
              labelComponent={
                <Icon
                  name="question circle outline"
                  icon={regular('question-circle')}
                  color={'darkGray'}
                />
              }
              popup={{
                title: tooltip.title,
                body: tooltip.body || '',
              }}
              style={{ marginLeft: 10 }}
            />
          )}
        </SemanticAccordion.Title>
        <SemanticAccordion.Content active={open} style={contentStyle}>
          {contentToDisplay}
        </SemanticAccordion.Content>
      </SemanticAccordion>
    )
  }
)(({ backgroundColor, disabled }) => ({
  '&&&&&&': {
    width: '100%',

    '.title': {
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      pointerEvents: disabled ? 'none' : undefined,
      opacity: disabled ? 0.5 : 1,
    },
  },

  '&&&&&&.text': {
    '>.title': {
      color: Colors.green,
      padding: 0,

      '>.expand-icon': {
        paddingLeft: 6,
      },
    },

    '>.content': {
      paddingTop: 16,
    },
  },

  '&&&&&&.default': {
    backgroundColor: backgroundColor ? Colors[backgroundColor] : undefined,
    borderTop: `solid 1px ${Colors.lightGray}`,

    // If this is the last accordion in list add bottom border
    '&:last-of-type': {
      borderBottom: `solid 1px ${Colors.lightGray}`,
    },

    '>.title': {
      // Leave space for icon
      paddingRight: 18,
      height: 68,

      '>.expand-icon': {
        position: 'absolute',
        right: 0,
      },
    },

    '>.content': {
      padding: '0 24px 24px 0',
    },
  },

  '&&&&&&.old': {
    backgroundColor: backgroundColor ? Colors[backgroundColor] : Colors.stone40,

    '>.title': {
      paddingLeft: 24,
      // Leave space for icon
      paddingRight: 42,
      height: 68,

      '>.expand-icon': {
        position: 'absolute',
        right: 24,
      },
    },

    '>.content': {
      padding: 24,
    },
  },
}))

export default Accordion
