import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'
import { fetchWrapper } from '../../reducers/fetch'

export enum YearEndModuleType {
  verifyTaxEntity = 'verify_tax_entity',
  file1099Nec = 'file_1099_nec',
  eoyBookkeeping = 'eoy_bookkeeping',
  eoyBookkeepingFollowup = 'eoy_bookkeeping_followup',
  taxQuestionnaire = 'tax_questionnaire',
  fileExtensionRequest = 'file_extension_request',
  submitExtensionRequest = 'submit_extension_request',
  file1120S = 'file_1120_s',
  file1040 = 'file_1040',
}

export enum YearEndModuleStatusOptions {
  upNext = 'up_next',
  earlyStart = 'early_start',
  inProgress = 'in_progress',
  overdue = 'overdue',
  upcoming = 'upcoming',
  waitingOnHeard = 'waiting_on_heard',
  actionRequired = 'action_required',
  complete = 'complete',
}

export const EOYBookkeepingSubstep = {
  disconnectedAccounts: 'eoy-bk-disconnected-accounts',
  uploadMissingStatements: 'eoy-bk-upload-missing-statements',
  unclarifiedTransactions: 'eoy-bk-unclarified-transactions',
  otherIncome: 'eoy-bk-other-income',
  otherExpenses: 'eoy-bk-other-expenses',
  personalAccountBusinessIncome: 'eoy-bk-personal-account-business-income',
  personalAccountBusinessExpense: 'eoy-bk-personal-account-business-expense',
  ownerInvestments: 'eoy-bk-owner-investments',
  ownerDistributions: 'eoy-bk-owner-distributions',
  yearEndDocs: 'eoy-bk-year-end-docs',
  initialSubmissionReview: 'eoy-bk-initial-submission-review',
} as const
export type EOYBookkeepingSubstepId =
  (typeof EOYBookkeepingSubstep)[keyof typeof EOYBookkeepingSubstep]

export const EOYBookkeepingFollowupSubstep = {
  initialBookkeeperReview: 'eoy-bk-initial-bookkeeper-review',
  userFollowup: 'eoy-bk-user-followup',
  followupBookkeeperReview: 'eoy-bk-followup-bookkeeper-review',
  finalUserApproval: 'eoy-bk-final-user-approval',
  balanceSheetGeneration: 'eoy-bk-balance-sheet-generation',
} as const
export type EOYBookkeepingFollowupSubstepId =
  (typeof EOYBookkeepingFollowupSubstep)[keyof typeof EOYBookkeepingFollowupSubstep]

export const FormFilingJobSubstep = {
  notClaimed: 'job-not-yet-claimed',
  startedUserCTA: 'job-started-user-cta',
  inProgress: 'job-in-progress',
  inProgressNeedsUserAction: 'job-in-progress-needs-user-response',
  draftReadyForReview: 'job-draft-ready-for-review',
  draftApproved: 'job-draft-approved',
  signatureRequested: 'job-signature-requested',
  signedAndApproved: 'job-signed-and-approved',
} as const
export type FormFilingJobSubstepId =
  (typeof FormFilingJobSubstep)[keyof typeof FormFilingJobSubstep]

export const ExtensionRequestJobSubstep = {
  infoGathering: 'job-info-gathering',
  unclaimedJob: 'job-unclaimed',
  inProgress: 'job-in-progress',
  inProgressNeedsUserAction: 'job-in-progress-needs-user-response',
} as const
type ExtensionRequestJobSubstepId =
  (typeof ExtensionRequestJobSubstep)[keyof typeof ExtensionRequestJobSubstep]

export const TQSubstep = {
  checkYourDetails: 'tq-check-your-details',
  tqBigChanges: 'tq-big-changes',
  tqDeductionsCredits: 'tq-deductions-credits',
  tqIncomeLiabilities: 'tq-income-liabilities',
  tqUploadDocuments: 'tq-upload-documents',
  tqAboutYourBusiness: 'tq-about-your-business',
  tqMissingQtePayments: 'tq-missing-qte-payments',
} as const
export type TQSubstepId = (typeof TQSubstep)[keyof typeof TQSubstep]

export const YearEndModuleSubsteps = {
  ...EOYBookkeepingSubstep,
  ...EOYBookkeepingFollowupSubstep,
  ...FormFilingJobSubstep,
  ...ExtensionRequestJobSubstep,
  ...TQSubstep,
} as const
export type YearEndModuleSubstepId =
  | EOYBookkeepingSubstepId
  | EOYBookkeepingFollowupSubstepId
  | FormFilingJobSubstepId
  | ExtensionRequestJobSubstepId
  | TQSubstepId

export const WaitingOnTaxPreparerSubstep: YearEndModuleSubstepId[] = [
  FormFilingJobSubstep.notClaimed,
  FormFilingJobSubstep.inProgress,
  FormFilingJobSubstep.draftApproved,
  FormFilingJobSubstep.signedAndApproved,
  ExtensionRequestJobSubstep.inProgress,
  ExtensionRequestJobSubstep.unclaimedJob,
]

export type YearEndModuleStatus = {
  id: number
  userId: number
  year: string
  moduleType: YearEndModuleType
  status: YearEndModuleStatusOptions
  substepIdentifier: YearEndModuleSubstepId | null
  startedAt: string | null
  completedAt: string | null
  dueDate: string | null
  order: number
}

export interface YearEndModuleStatusResponse {
  yearEndModuleStatuses: YearEndModuleStatus[]
  mostUrgentModule: YearEndModuleType | null
}

export interface YearEndModuleStatusUpsertParams {
  moduleType: YearEndModuleType
  status?: YearEndModuleStatusOptions
  substepIdentifier?: YearEndModuleSubstepId | null
  startedAt?: string | null
  completedAt?: string | null
}

const initialState = {
  byModuleType: {} as { [key: string]: YearEndModuleStatus },
  allModules: [] as YearEndModuleStatus[],
  mostUrgentModule: null as YearEndModuleType | null,
}

export interface YearEndModuleStatusesState {
  byModuleType: { [key: string]: YearEndModuleStatus }
  allModules: YearEndModuleStatus[]
  mostUrgentModule: YearEndModuleType | null
}

const yearEndModuleStatusSlice = createSlice({
  name: 'yearEndModuleStatuses',
  initialState,
  reducers: {
    receiveAllYearEndModuleStatusesForUser: (
      state,
      action: PayloadAction<YearEndModuleStatusResponse>
    ) => {
      state.byModuleType = keyBy(
        action.payload.yearEndModuleStatuses,
        'moduleType'
      )
      state.allModules = action.payload.yearEndModuleStatuses
      state.mostUrgentModule = action.payload.mostUrgentModule
    },
  },
})

export default yearEndModuleStatusSlice.reducer
export const { receiveAllYearEndModuleStatusesForUser } =
  yearEndModuleStatusSlice.actions

export const FETCH_USER_YEAR_END_MODULE_STATUSES_KEY =
  'FETCH_USER_YEAR_END_MODULE_STATUSES_KEY'
export const fetchUserYearEndModuleStatuses = () =>
  fetchWrapper({
    fetchKey: FETCH_USER_YEAR_END_MODULE_STATUSES_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.get<YearEndModuleStatusResponse>(
        '/finances/api/v1/year_end_status'
      )
      dispatch(receiveAllYearEndModuleStatusesForUser(res.data))
      return res.data
    },
  })

export const UPSERT_USER_YEAR_END_MODULE_STATUS_KEY = (
  moduleType: YearEndModuleType
) => `UPSERT_USER_YEAR_END_MODULE_STATUS_KEY_${moduleType}`
export const upsertUserYearEndModuleStatus = (
  data: YearEndModuleStatusUpsertParams
) =>
  fetchWrapper({
    fetchKey: UPSERT_USER_YEAR_END_MODULE_STATUS_KEY(data.moduleType),
    fetchFunction: async (dispatch) => {
      const res = await axios.post<YearEndModuleStatusResponse>(
        '/finances/api/v1/year_end_status',
        data
      )

      dispatch(receiveAllYearEndModuleStatusesForUser(res.data))
      return res.data
    },
  })
