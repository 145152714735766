import { FormikProvider, useFormik } from 'formik'
import { Form1120sProps } from '.'
import { useParams } from 'react-router-dom'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId, useTaxListQuestionRes } from '../service'
import { selectTaxListQuestionResponsesByFormId } from '../taxChecklist.selectors'
import { Container, Divider, Grid } from 'semantic-ui-react'
import {
  FormikDropdown,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Icon,
  Popup,
  Text,
  getFieldName,
  makeReqBoolSchema,
  makeStringArraySchema,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import DocumentAddedAlert from '../Shared/SpecialtyAlerts'
import { TaxChecklistResponse } from '../taxChecklistQuestion.slice'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { NeedHelpTip } from '../NeedHelpTip'
import SkipQuestion, {
  skipQuestionId,
  useSkipQuestion,
} from '../Shared/SkipQuestion'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import { Colors } from '../../../../../styles/theme'
import UploadDocumentSubSection from '../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'

export const updateDetailsQuestionsIds = [
  TaxListQuestionId.multi_state_business_states,
  TaxListQuestionId.shareholder_info_changed,
]

const UpdateDetailsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1120sProps) => {
  const { formId } = useParams()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const skippedQuestions = useSkipQuestion([
    TaxListQuestionId.shareholder_info_changed,
  ])

  const multipStateBusinessStatesQR = useTaxListQuestionRes({
    formId: Number(formId),
    questionId: TaxListQuestionId.multi_state_business_states,
  })

  const shareHolderInfoChangedQR = useTaxListQuestionRes({
    formId: Number(formId),
    questionId: TaxListQuestionId.shareholder_info_changed,
  })

  // Derived TF responses
  const responseNumberStatesConductedBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_states_conducted_business,
    Number(formId)
  )

  const dropdownOptions = multipStateBusinessStatesQR.options
    ?.map((o) => ({
      text: o.text,
      value: o.id,
      key: o.id,
    }))
    .sort((a, b) => a.text.localeCompare(b.text))

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.multi_state_business_states]:
        multipStateBusinessStatesQR.initialResponse,
      [TaxListQuestionId.shareholder_info_changed]:
        shareHolderInfoChangedQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseUpdates: Partial<TaxChecklistResponse>[] = []
      const multiStateResponse =
        multipStateBusinessStatesQR.getResponseData(values)
      if (multiStateResponse) {
        responseUpdates.push(multiStateResponse)
      }
      const shareholderRes = shareHolderInfoChangedQR.getResponseData(values)
      if (shareholderRes) {
        responseUpdates.push(shareholderRes)
      }
      if (
        Array.isArray(values[TaxListQuestionId.multi_state_business_states]) &&
        values[TaxListQuestionId.multi_state_business_states].length !==
          responseNumberStatesConductedBiz?.[0]?.value
      ) {
        responseUpdates.push({
          id: responseNumberStatesConductedBiz?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_states_conducted_business,
          value: values[TaxListQuestionId.multi_state_business_states].length,
        })
      }
      await goToNextStep(responseUpdates, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.checkDetails1120s],
      })
    },
  })

  const { values, isSubmitting, isValid, submitForm } = formik
  return (
    <>
      <FormikProvider value={formik}>
        <Container
          style={{
            marginTop: 56,
            display: 'flex',
            gap: 32,
            flexDirection: 'column',
          }}
          text
          textAlign="left"
        >
          <Grid>
            <GridRowColumn>
              <NeedHelpTip />
            </GridRowColumn>
            <GridRowColumn>
              <Text as="display2" textAlign="center">
                Update Details for {taxYear}
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                Please update the following information for {taxYear}. These
                answers will appear on your tax return.
              </Text>
            </GridRowColumn>
            <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
              <FormikDropdown
                search
                name={getFieldName<typeof values>(
                  TaxListQuestionId.multi_state_business_states
                )}
                label={multipStateBusinessStatesQR.label}
                options={dropdownOptions}
                schema={makeStringArraySchema()}
                multiple
                clearable
                fullWidth
                afterLabel={
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text as="h3">
                            Registering business in another state
                          </Text>
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Text>
                            Select all states in which you’ve seen clients.
                          </Text>
                        </GridRowColumn>
                      </Grid>
                    }
                    trigger={
                      <Icon
                        size="1x"
                        style={{ marginLeft: 4 }}
                        icon={regular('question-circle')}
                        color="mediumGray"
                      />
                    }
                  />
                }
              />
            </GridRowColumn>
            <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
              <FormikLabelError
                name={getFieldName<typeof values>(
                  TaxListQuestionId.shareholder_info_changed
                )}
                label={shareHolderInfoChangedQR.label}
                schema={makeReqBoolSchema()}
              />
            </GridRowColumn>
            <Grid.Row className="short" style={{ justifyContent: 'center' }}>
              <Grid.Column width={6} style={{ paddingRight: 0 }}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>(
                    TaxListQuestionId.shareholder_info_changed
                  )}
                  value
                >
                  Yes
                </FormikRadioToggleButton>
              </Grid.Column>
              <Grid.Column width={6}>
                <FormikRadioToggleButton
                  fullWidth
                  name={getFieldName<typeof values>(
                    TaxListQuestionId.shareholder_info_changed
                  )}
                  value={false}
                >
                  No
                </FormikRadioToggleButton>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn
              columnStyle={{ backgroundColor: Colors.stone40 }}
              {...makeGridConfig([12, 14], true)}
              short
            >
              <SkipQuestion
                questionId={TaxListQuestionId.shareholder_info_changed}
              />
            </GridRowColumn>
            {values[TaxListQuestionId.shareholder_info_changed] && (
              <GridRowColumn width={12} style={{ justifyContent: 'center' }}>
                <DocumentAddedAlert>
                  You can upload documentation for this below, or at the end of
                  the questionnaire.
                </DocumentAddedAlert>
              </GridRowColumn>
            )}
            <GridRowColumn />
          </Grid>
          <Divider />
          <UploadDocumentSubSection
            formId={formId}
            categories={[
              values[TaxListQuestionId.shareholder_info_changed]
                ? UserDocumentCategoryIdentifier.businessIncorporation
                : undefined,
            ]}
          />
        </Container>
      </FormikProvider>
      <Grid>
        <FormFlowFooter
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </>
  )
}

export default UpdateDetailsPanel
