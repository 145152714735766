import { useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import TaxCenterPromptCard, {
  PromptCardStatus,
  TaxPromptCardButton,
  TaxPromptCardInfo,
} from '../components/TaxCenterPromptCard'
import {
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getAnnualTaxFilingForYearSelector,
  selectExtensionStartDate,
  selectFileExtensionStatusForYear,
  selectIsBefore1120sDeadline,
  selectShowFileExtensionForYear,
} from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import { TaxfyleJobStatus } from '../annualTaxFilingForms.slice'
import { selectTaxDetailsByYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { isoToUTCDateTime } from '../../../../utils/dateHelpers'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../annualTaxFilingForms.selector'

const ExtensionSurveyCard = ({ year }: { year: string }) => {
  const prevTaxYear = Number(year) - 1
  const filing = useReselector(getAnnualTaxFilingForYearSelector, year)
  const taxDetails = useReselector(selectTaxDetailsByYear, year)
  const form1040 = useReselector(select1040FormForYear, year)
  const form1120s = useReselector(select1120sFormForYear, year)
  const isBefore1120sDeadline = useReselector(selectIsBefore1120sDeadline)
  const needsExtended1040 = Boolean(form1040)
  const needsExtended1120S = Boolean(form1120s && isBefore1120sDeadline)
  const hasTwoExtendedForms = needsExtended1040 && needsExtended1120S

  const getDueDate = useMemo(() => {
    if (!taxDetails) {
      return undefined
    }
    if (needsExtended1120S) {
      return isoToUTCDateTime(
        taxDetails.irsFormDueDates.form_1120_s.internal
          .extensionRequestCutoffDate
      )
    }
    return isoToUTCDateTime(
      taxDetails.irsFormDueDates.form_1040.internal.extensionRequestCutoffDate
    )
  }, [needsExtended1120S, taxDetails])

  const extensionSubmittedButNotComplete =
    filing?.extensionTaxfyleJob &&
    filing.extensionTaxfyleJob?.jobStatus !== TaxfyleJobStatus.completed

  const actionLink = useMemo(() => {
    if (hasTwoExtendedForms) {
      if (isBefore1120sDeadline) {
        return '/taxes/annual/extension_request/combined/'
      }
      return '/taxes/annual/extension_request/personal/'
    } else if (needsExtended1120S) {
      return '/taxes/annual/extension_request/business/'
    } else {
      return '/taxes/annual/extension_request/personal/'
    }
  }, [hasTwoExtendedForms, needsExtended1120S, isBefore1120sDeadline])

  const message = useMemo(() => {
    if (hasTwoExtendedForms || needsExtended1040) {
      const taxReturnType = hasTwoExtendedForms
        ? 'tax return'
        : 'Personal Tax Return (Form 1040)'
      return `During onboarding, you indicated that you’ll need to file an extension for your ${taxReturnType}. We’ll need to collect some details to file an extension on your behalf.`
    }

    const currentYear = DateTime.now().year
    return `Since you joined us in ${currentYear}, you’ll need to file an extension for your Business Tax Return (Form 1120-S). We’ll collect some details to file an extension on your behalf.`
  }, [hasTwoExtendedForms, needsExtended1040])

  const startDate = useReselector(selectExtensionStartDate)
  const extensionStatus = useReselector(selectFileExtensionStatusForYear, year)
  const showExtensionSurvey = useReselector(
    selectShowFileExtensionForYear,
    year
  )

  const status = useMemo(() => {
    switch (extensionStatus) {
      case AnnualTaxFilingStepStatus.completeLocked:
        return PromptCardStatus.DONE
      case AnnualTaxFilingStepStatus.inProgress:
        return PromptCardStatus.IN_PROGRESS
      case AnnualTaxFilingStepStatus.upcoming:
      case AnnualTaxFilingStepStatus.comingSoon:
        return PromptCardStatus.COMING_SOON
      case AnnualTaxFilingStepStatus.notStarted:
        return PromptCardStatus.ACTION_REQUIRED
      default:
        return extensionStatus satisfies never
    }
  }, [extensionStatus])

  const title = useMemo(() => {
    if (status === PromptCardStatus.DONE) {
      if (hasTwoExtendedForms) {
        return 'You’ve completed your tax return extension'
      }
      return needsExtended1040
        ? 'You’ve completed your personal tax return extension'
        : 'You’ve completed your business tax return extension'
    } else if (extensionSubmittedButNotComplete) {
      return 'Waiting on tax preparer upload'
    } else if (hasTwoExtendedForms) {
      return 'Extension Request For Taxes'
    } else if (needsExtended1040) {
      return 'Extension Request Form for Personal Tax Return (1040)'
    }

    return 'Extension Request Form for Business Tax Return (1120-S)'
  }, [
    extensionSubmittedButNotComplete,
    hasTwoExtendedForms,
    needsExtended1040,
    status,
  ])

  const taxPromptCardInfo = useMemo(() => {
    let docInfo

    if (hasTwoExtendedForms) {
      docInfo = 'Tax Returns'
    } else if (needsExtended1040) {
      docInfo = 'Personal Tax Return (Form 1040)'
    } else {
      docInfo = 'Business Tax Return (1120-S)'
    }

    return (
      <TaxPromptCardInfo
        timeToComplete={`${hasTwoExtendedForms || needsExtended1040 ? 10 : 5} minutes`}
        docInfo={`${prevTaxYear} ${docInfo}`}
        startDate={startDate}
        dueDate={getDueDate}
      />
    )
  }, [
    getDueDate,
    hasTwoExtendedForms,
    needsExtended1040,
    prevTaxYear,
    startDate,
  ])

  const comingSoonOrGetStarted = useMemo(
    () => (
      <Grid>
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/extension.svg"
            style={{ width: 452, height: 260 }}
          />
        </GridRowColumn>
        <GridRowColumn spacer={3} width={10}>
          <Text textAlign="center">{message}</Text>
        </GridRowColumn>
        <TaxPromptCardButton status={status} actionLink={actionLink} />
        {taxPromptCardInfo}
      </Grid>
    ),
    [actionLink, message, status, taxPromptCardInfo]
  )

  const progressCard = useMemo(() => {
    // There are 2 in progress states - one if the user has gone through the extension flow and is waiting for it to
    // be complete and the other if they have started it
    if (extensionSubmittedButNotComplete) {
      return (
        <Text as="bodyLg">
          Thanks! Once your tax preparer uploads your extension request form(s),
          you&apos;ll be able to begin the Tax Checklist.
        </Text>
      )
    } else {
      return (
        <Grid>
          <GridRowColumn centerContent>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/extension.svg"
              style={{ width: 184, height: 107 }}
            />
          </GridRowColumn>
          <TaxPromptCardButton status={status} actionLink={actionLink} />
          {taxPromptCardInfo}
        </Grid>
      )
    }
  }, [actionLink, extensionSubmittedButNotComplete, status, taxPromptCardInfo])

  if (!showExtensionSurvey) {
    return null
  }

  return (
    <TaxCenterPromptCard
      body={{
        [PromptCardStatus.COMING_SOON]: comingSoonOrGetStarted,
        [PromptCardStatus.ACTION_REQUIRED]: comingSoonOrGetStarted,
        [PromptCardStatus.IN_PROGRESS]: progressCard,
        [PromptCardStatus.DONE]: (
          <Text as="bodyLg">
            You can no longer edit your Extension Request responses, but you can
            view them anytime.{' '}
            <Link
              to={`/taxes/annual/extension_request/complete/${filing?.year}`}
              size="large"
            >
              View Extension Request
            </Link>
          </Text>
        ),
      }}
      icon={regular('calendar-clock')}
      status={status}
      title={title}
    />
  )
}

export default ExtensionSurveyCard
