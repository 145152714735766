import { TaxSeasonKickoffSurveyProps } from '../YearEndKickoffSurvey'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { Grid } from 'semantic-ui-react'
import {
  Accordion,
  Card,
  FormikCheckbox,
  FormikLabelError,
  FormikRadioButton,
  GridRowColumn,
  Text,
  getFieldNames,
  makeReqBoolSchema,
  makeReqStringSchema,
} from '../../../../../components/BaseComponents'
import { FormikProvider, useFormik } from 'formik'
import { TaxFormType } from '../../Questionnaires/constants'
import { TAX_ENTITY_TYPES } from '../../../taxConstants'
import { DateTime } from 'luxon'
import { selectSubscriptionIncludesFreePersonalFiling } from '../../../../../reducers/subscription.slice'
import { useReselector } from '../../../../../utils/sharedHooks'
import { getUser } from '../../../../../reducers/auth/user.selectors'
import {
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../../annualTaxFilings.selector'
import { TAX_SEASON_KICKOFF_SURVEY } from '../TskTipsSideBar'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../../utils/dateHelpers'

const FIELD_NAMES = {
  form1040Selected: 'form1040Selected',
  form1120sSelected: 'form1120sSelected',
} as const

type FormFieldName = (typeof FIELD_NAMES)[keyof typeof FIELD_NAMES]

export interface TaxFormValues {
  form1040Selected: boolean | undefined
  form1120sSelected: boolean | undefined
}

interface TaxFormSelectionProps {
  fieldName: FormFieldName
  label: string
  values: TaxFormValues
  fieldNames: Record<string, string>
  currentAnnualTaxYear?: string
  isScorp1040paid: boolean
  isSoleProp: boolean
  form1040DueDate: string
  form1120sDueDate: string
  taxSeasonKickOffDueAt: string
}

export const TaxInfoAccordion = ({
  title,
  content,
}: {
  title: string
  content: React.ReactNode
}) => <Accordion title={title} variant="text" content={content} />

export const OptOutMessage = ({
  fieldName,
  fieldNames,
  isSoleProp,
  form1040DueDate,
  form1120sDueDate,
  taxSeasonKickOffDueAt,
}: {
  fieldName: FormFieldName
  fieldNames: Record<string, string>
  isSoleProp: boolean
  form1040DueDate: string
  form1120sDueDate: string
  taxSeasonKickOffDueAt: string
}) => (
  <GridRowColumn short width={12}>
    <Card type="subsection" backgroundColor="natural">
      <Text as="bodySm">
        {isSoleProp ? (
          <>
            If you change your mind, you can opt back in through the Annual Tax
            Center by {form1040DueDate}.
            <br />
            <br />
            If you opt back in after {taxSeasonKickOffDueAt}, you&apos;ll need
            to file an extension request.
          </>
        ) : (
          <>
            {fieldName === fieldNames.form1120sSelected && (
              <>
                Note: If you do not file your Business tax filing (Form 1120-S)
                with us, we will not be able to file your Personal tax filing
                (Form 1040) either.
                <br />
                <br />
                If you change your mind, you can always opt back in by{' '}
                {form1120sDueDate}.
              </>
            )}
            {fieldName === fieldNames.form1040Selected && (
              <>
                Note: Even though you have decided not to file your Personal tax
                filing (Form 1040) with us, keep in mind that you will still
                need to work with a different tax preparer to file this with the
                IRS.
                <br />
                <br />
                Opt back in through the Annual Tax Center by {
                  form1120sDueDate
                }{' '}
                before you complete your business tax questionnaire.
              </>
            )}
          </>
        )}
      </Text>
    </Card>
  </GridRowColumn>
)

export const TaxFormSelection = ({
  fieldName,
  label,
  values,
  fieldNames,
  currentAnnualTaxYear,
  isScorp1040paid,
  isSoleProp,
  form1040DueDate,
  form1120sDueDate,
  taxSeasonKickOffDueAt,
}: TaxFormSelectionProps) => (
  <>
    <GridRowColumn>
      <FormikLabelError
        name={fieldName}
        label={`Would you like Heard to prepare your ${currentAnnualTaxYear} ${label} for you?`}
        schema={makeReqStringSchema()}
      />
    </GridRowColumn>
    <GridRowColumn short>
      <Text as="bodyXs">This is included in your plan at no extra cost.</Text>
    </GridRowColumn>
    <br />
    <GridRowColumn short>
      <FormikRadioButton name={fieldName} label="Yes" value variant="default" />
    </GridRowColumn>
    <br />
    {fieldName === FIELD_NAMES.form1040Selected &&
      values.form1040Selected &&
      isScorp1040paid && (
        <GridRowColumn short width={12}>
          <Card type="subsection" backgroundColor="natural">
            <Text as="bodySm">
              Thanks for letting us know. We will collect payment from you after
              you have completed your tax checklist.
            </Text>
          </Card>
          <br />
        </GridRowColumn>
      )}
    <GridRowColumn short>
      <FormikRadioButton
        name={fieldName}
        label={`No, I do not want Heard to prepare my ${currentAnnualTaxYear} ${label}`}
        value={false}
        variant="default"
      />
    </GridRowColumn>
    <br />
    {values[fieldName] === false && (
      <OptOutMessage
        fieldName={fieldName}
        fieldNames={fieldNames}
        isSoleProp={isSoleProp}
        form1040DueDate={form1040DueDate}
        form1120sDueDate={form1120sDueDate}
        taxSeasonKickOffDueAt={taxSeasonKickOffDueAt}
      />
    )}
  </>
)

const TaxNeeds = ({ goToNextStep, goBack }: TaxSeasonKickoffSurveyProps) => {
  const currentUser = useReselector(getUser)
  const currentAnnualTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentAnnualTaxYear
  )
  const annualTaxDetails = useReselector(
    selectTaxDetailsByYear,
    currentAnnualTaxYear
  )

  const formatISODate = (isoDate: string | undefined): string => {
    return isoDate
      ? isoToUTCDateTime(isoDate).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)
      : ''
  }

  const form1040DueDate = formatISODate(
    annualTaxDetails?.taxQuestionnaireDueDates?.form_1040.endAt
  )
  const form1120sDueDate = formatISODate(
    annualTaxDetails?.taxQuestionnaireDueDates?.form_1120_s.endAt
  )
  const taxSeasonKickOffDueAt = formatISODate(
    annualTaxDetails?.taxSeasonKickoffDueAt
  )

  const taxEntityType = currentUser.financialProfile?.taxEntityType

  const isSCorporation =
    taxEntityType === TAX_ENTITY_TYPES.form_1120_s ||
    taxEntityType === TAX_ENTITY_TYPES.form_1120
  const isSoleProp = taxEntityType === TAX_ENTITY_TYPES.form_1040
  const isCCorporation = taxEntityType === TAX_ENTITY_TYPES.schedule_c
  const isNewSubscription = useReselector(
    selectSubscriptionIncludesFreePersonalFiling
  )
  const isScorp1040paid = isSCorporation && !isNewSubscription

  const formik = useFormik({
    initialValues: {
      form1040Selected:
        annualTaxFiling?.annualTaxFormNeeds?.includes(TaxFormType.form1040) ||
        undefined,
      form1120sSelected:
        annualTaxFiling?.annualTaxFormNeeds?.includes(TaxFormType.form1120s) ||
        undefined,
      confirmed: false,
    },
    onSubmit: (values) => {
      const selectedForms = []
      const now = DateTime.now().toISO()

      if (isSoleProp) {
        if (values.form1040Selected) {
          selectedForms.push(TaxFormType.form1040)
        } else {
          goToNextStep(
            {
              annualTaxFormNeeds: null,
              optedOutAt: now,
            },
            TAX_SEASON_KICKOFF_SURVEY.review
          )
          return
        }
      } else {
        if (values.form1120sSelected) {
          selectedForms.push(TaxFormType.form1120s)
          if (values.form1040Selected) {
            selectedForms.push(TaxFormType.form1040)
          }
        } else {
          goToNextStep(
            {
              annualTaxFormNeeds: null,
              optedOutAt: now,
            },
            TAX_SEASON_KICKOFF_SURVEY.review
          )
          return
        }
      }

      goToNextStep(
        {
          annualTaxFormNeeds: selectedForms,
        },
        TAX_SEASON_KICKOFF_SURVEY.review
      )
    },
  })

  const { submitForm, isSubmitting, isValid, values } = formik
  const fieldNames = getFieldNames(formik)
  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn width={12}>
          <Text as="display2" textAlign="center">
            Taxes at Heard
          </Text>
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={2} />
          <Grid.Column width={10}>
            <Text as="bodyLg">
              {isCCorporation
                ? 'If you elected to become an S corporation mid-year, we can only file your 1120-S, but not your Form 1120 Corporation Income Tax Return for your C Corporation.'
                : 'Heard prepares taxes for your therapy business. However, we cannot support additional non-therapy businesses (with the exception of rental properties) owned by you or your spouse. We also do not support amended returns.'}
            </Text>
            <br />
            <TaxInfoAccordion
              title={
                isCCorporation
                  ? 'Learn more'
                  : 'What should I do if I (or my spouse) own other businesses?'
              }
              content={
                <>
                  <Text as="display3">
                    {isCCorporation ? 'Form 1120' : 'Non-therapy businesses'}
                  </Text>
                  <Text>
                    You will need to engage the services of an outside service
                    to prepare your taxes for any other businesses that you or
                    your spouse own.
                    <br />
                    <br />
                    You will receive Schedule K-1&apos;s or Schedule C&apos;s to
                    report income, which you can then upload to Heard.
                  </Text>
                </>
              }
            />
            <br />
            {isSoleProp && (
              <TaxFormSelection
                fieldName={FIELD_NAMES.form1040Selected}
                label="Personal tax filing (Form 1040)"
                values={values}
                fieldNames={fieldNames}
                currentAnnualTaxYear={currentAnnualTaxYear}
                isScorp1040paid={isScorp1040paid}
                isSoleProp={isSoleProp}
                form1040DueDate={form1040DueDate}
                form1120sDueDate={form1120sDueDate}
                taxSeasonKickOffDueAt={taxSeasonKickOffDueAt}
              />
            )}
            {(isSCorporation || isCCorporation) && (
              <>
                <TaxFormSelection
                  fieldName={FIELD_NAMES.form1120sSelected}
                  label="Business tax filing (Form 1120-S)"
                  values={values}
                  fieldNames={fieldNames}
                  currentAnnualTaxYear={currentAnnualTaxYear}
                  isScorp1040paid={isScorp1040paid}
                  isSoleProp={isSoleProp}
                  form1040DueDate={form1040DueDate}
                  form1120sDueDate={form1120sDueDate}
                  taxSeasonKickOffDueAt={taxSeasonKickOffDueAt}
                />
                {values.form1120sSelected === true && (
                  <>
                    <br />
                    <TaxFormSelection
                      fieldName={FIELD_NAMES.form1040Selected}
                      label="Personal tax filing (Form 1040)"
                      values={values}
                      fieldNames={fieldNames}
                      currentAnnualTaxYear={currentAnnualTaxYear}
                      isScorp1040paid={isScorp1040paid}
                      isSoleProp={isSoleProp}
                      form1040DueDate={form1040DueDate}
                      form1120sDueDate={form1120sDueDate}
                      taxSeasonKickOffDueAt={taxSeasonKickOffDueAt}
                    />
                  </>
                )}
              </>
            )}
            {((isSoleProp && values.form1040Selected) ||
              values.form1120sSelected) && (
              <>
                <br />
                <GridRowColumn short width={12}>
                  <Card type="subsection" backgroundColor="stone40">
                    <GridRowColumn>
                      <FormikCheckbox
                        name={fieldNames.confirmed}
                        label="I confirm that I have read and understand the scope of Heard's tax preparation services."
                        schema={makeReqBoolSchema({ trueOnly: true })}
                      />
                    </GridRowColumn>
                  </Card>
                </GridRowColumn>
              </>
            )}
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        <FormFlowFooter
          onBack={() => goBack(TAX_SEASON_KICKOFF_SURVEY.contractorPayroll)}
          onForward={submitForm}
          continueDisabled={!isValid || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default TaxNeeds
