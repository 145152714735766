import { useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { useField, useFormikContext } from 'formik'
import moment from 'moment'
import { mapValues, omit } from 'lodash'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { AnyObject, AnySchema, StringSchema, ArraySchema } from 'yup'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'

import LocationSearchInput, {
  LocationSearchInputProps,
} from '../shared/LocationSearchInput'
import Input, {
  InputError,
  InputProps,
  LabelDescription,
  LabelDescriptionProps,
} from './Input'
import Dropdown, {
  DefaultDropdownProps,
  DropdownOption,
  DropdownValue,
  valsToDropdownOptions,
} from './Dropdown'
import Checkbox, { CheckboxProps } from './Checkbox'
import DatePicker, { DatePickerProps } from './DatePicker'
import Radio, { RadioProps } from './Radio'
import Button, { ButtonProps } from './Button'
import { inOperator } from '../../utils/typeHelpers'
import StyledDraftEditor, {
  StyledEditorDraftProps,
} from '../../features/Conversations/ConversationThread/StyledDraftEditor'
import { convertDraftToCommentBody } from '../../features/Conversations/helpers'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import Card from './Card'
import Text from './Text'
import TextArea, { TextAreaProps } from './TextArea'
import TransactionCategoryDropdown, {
  TransactionCategoryDropdownProps,
} from '../shared/TransactionCategoryDropdown'

// used to display error state without an error string
export const NOT_DISPLAY_ERR = 'NOT_DISPLAY_ERR'

// Default StringSchema does not have null as a type
type FormikStringSchema = StringSchema<
  string | null | undefined,
  AnyObject,
  undefined,
  ''
>

type FormikArraySchema = ArraySchema<
  (string | undefined)[] | undefined,
  AnyObject,
  '',
  ''
>

const validateFieldLevel =
  (schema: AnySchema) => async (val: string | undefined) => {
    let error: string | undefined
    try {
      await schema.validate(val)
    } catch (err: unknown) {
      if (
        err &&
        typeof err === 'object' &&
        inOperator('errors', err) &&
        Array.isArray(err.errors) &&
        typeof err.errors[0] === 'string'
      ) {
        error = err.errors[0]
      }
    }
    return error
  }

const useFieldHelpers = ({
  name,
  schema,
}: {
  name: string
  schema?: AnySchema
}) => {
  const [initialError, setInitialError] = useState<string>()
  const [field, meta, helpers] = useField({
    name,
    validate: schema ? validateFieldLevel(schema) : undefined,
  })

  const { validateForm, setFieldError } = useFormikContext()

  useEffect(() => {
    const checkInitialError = async () => {
      if (schema) {
        setInitialError(await validateFieldLevel(schema)(meta.value))
      }
    }
    checkInitialError()
    // We only care to use the value on mount here.  Note this isn't the same as `meta.initialValue` because this field
    // may have changed if the user is toggling conditionally rendered fields.  It's too expensive to change this for
    // every value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema])

  const hasSchema = Boolean(schema)

  // We often conditionally render form fields and set the schema directly on the fields.  If a field is added, formik
  // validation doesn't trigger on first render so it must be manually triggered
  // To make matters worse we need to allow the form to render fully before validation can be done or the field error
  // may be removed
  useEffect(() => {
    // Skip this hack if we aren't doing local validation
    if (hasSchema) {
      setTimeout(() => setFieldError(name, initialError), 50)
    }
  }, [hasSchema, initialError, name, setFieldError])

  // Dismount has a similar issue with on mount validation except in this case the field or other field with the same
  // name may or may not be rendering.  It's not good enough in this case to clear the field error so just validate the
  // whole form
  useEffect(() => {
    if (!hasSchema) {
      return undefined
    }

    return () => {
      setTimeout(validateForm, 50)
    }
  }, [hasSchema, name, validateForm])

  // meta.error can be an array of objects if the field is an array of objects.  The error parsing becomes complex so just show a default error
  const error =
    (meta.touched &&
      (Array.isArray(meta.error) ? 'Please check below values' : meta.error)) ||
    undefined

  return { field, meta, helpers, error }
}

// Be sure to wrap form with FormikProvider or this won't work
export const FormikInput = (
  props: Omit<InputProps, 'onChange' | 'value'> & {
    name: string
    onChangeCallback?: (_?: string) => void
    schema?: FormikStringSchema
  }
) => {
  const { onChangeCallback, name, label, schema } = props
  const { field, helpers, error } = useFieldHelpers({ name, schema })

  useEffect(() => {
    if (onChangeCallback) {
      onChangeCallback(field.value)
    }
  }, [field.value, onChangeCallback])

  return (
    <Input
      placeholder={typeof label === 'string' ? label : undefined}
      {...omit(props, ['onChangeCallback', 'validate'])}
      {...field}
      value={field.value ?? ''}
      onChange={(value: string) => helpers.setValue(value)}
      error={error}
    />
  )
}

export const FormikDropdown = (
  props: Omit<
    DefaultDropdownProps,
    'onChange' | 'value' | 'options' | 'multiple' | 'clearable'
  > & {
    options?: DropdownOption<DropdownValue>[]
    optionValues?: DropdownValue[]
    name: string
    onChange?: (val: DropdownValue | DropdownValue[] | undefined) => void
    schema?: FormikStringSchema | FormikArraySchema
    // These are pulled out because DefaultDropdownProps doesn't include them by default.
    // Override typing doesn't matter because formik doesn't use it
    multiple?: boolean
    clearable?: boolean
  }
) => {
  const { name, optionValues, options, label, schema, onChange } = props
  const { field, helpers, error } = useFieldHelpers({ name, schema })

  // optionValues is a shortcut to options if text and value will be the same
  const compiledOptions = useMemo(() => {
    if (optionValues) {
      return valsToDropdownOptions(optionValues)
    }

    return options
  }, [optionValues, options])

  // options or optionsValues are required to be sent
  if (!compiledOptions) {
    return null
  }

  return (
    <Dropdown
      placeholder={(typeof label === 'string' && label) || undefined}
      {...omit(props, 'optionValues')}
      {...field}
      onBlur={() => helpers.setTouched(true)}
      options={compiledOptions}
      // This isn't perfectly typed but
      // 1) formik doesn't type check what is sent
      // 2) the parent caller shouldn't strongly depend on the typing
      onChange={(val: DropdownValue | DropdownValue[] | undefined) => {
        onChange?.(val)
        return helpers.setValue(val)
      }}
      error={error}
    />
  )
}

export const FormikTransactionCategoryDropdown = (
  props: Omit<TransactionCategoryDropdownProps, 'value' | 'onChange'> & {
    name: string
    schema?: FormikStringSchema
  }
) => {
  const { name, schema, ...rest } = props

  const { field, helpers, error } = useFieldHelpers({ name, schema })

  return (
    <TransactionCategoryDropdown
      {...field}
      {...rest}
      multiple={false}
      error={error}
      onChange={(val) => {
        return helpers.setValue(val)
      }}
    />
  )
}

export const FormikCheckbox = (
  props: Omit<CheckboxProps, 'name' | 'checked'> & {
    name: string
    schema?: AnySchema
  }
) => {
  const { name, schema } = props
  const { field, helpers, error } = useFieldHelpers({ name, schema })

  return (
    <Checkbox
      {...props}
      {...omit(field, 'value')}
      checked={field.value}
      onChange={(checked: boolean) => {
        // Call in case additional callback sent in
        props.onChange?.(checked)

        // Formik handler
        return helpers.setValue(checked)
      }}
      error={error}
    />
  )
}

export const FormikCheckboxMulti = (
  props: Omit<CheckboxProps, 'name' | 'checked'> & {
    name: string
    schema?: FormikArraySchema
  }
) => {
  const { name, schema } = props
  const { field, helpers, error } = useFieldHelpers({ name, schema })

  return (
    <Checkbox
      {...props}
      {...omit(field, 'value')}
      checked={Array.isArray(field.value) && field.value.includes(props.value)}
      onChange={(checked: boolean) => {
        // Call in case additional callback sent in
        props.onChange?.(checked)
        const newValue = Array.isArray(field.value) ? [...field.value] : []
        // Formik handler
        if (checked) {
          newValue.push(props.value)
        } else {
          newValue.splice(newValue.indexOf(props.value), 1)
        }
        return helpers.setValue(newValue)
      }}
      error={error}
    />
  )
}

export const FormikDateInput = (
  props: Omit<DatePickerProps, 'onChange' | 'value'> & {
    name: string
    schema?: FormikStringSchema
    onChange?: () => void
  }
) => {
  const { name, schema } = props
  // Normally "meta.touched" is good enough for determine if we should display an error but if the user is currently
  // interacting with the calendar the input loses focus and an error will display
  const [calendarHasClosed, setCalendarHasClosed] = useState(false)
  const [calendarHasOpened, setCalendarHasOpened] = useState(false)

  const { field, helpers, error } = useFieldHelpers({ name, schema })

  return (
    <DatePicker
      {...props}
      {...field}
      value={field.value ?? ''}
      error={(!calendarHasOpened || calendarHasClosed) && error}
      onChange={(value: string) => {
        props.onChange?.()
        return helpers.setValue(value)
      }}
      onCalendarClose={() => setCalendarHasClosed(true)}
      onCalendarOpen={() => setCalendarHasOpened(true)}
    />
  )
}

export const FormikRadioButton = (
  props: Omit<RadioProps, 'name' | 'checked' | 'onChange'> & { name: string }
) => {
  const { field, helpers, error } = useFieldHelpers({ name: props.name })

  return (
    <Radio
      {...props}
      {...field}
      checked={field.value === props.value}
      onChange={() => helpers.setValue(props.value)}
      error={error}
    />
  )
}

export const FormikRadioToggleButton = (
  props: Omit<ButtonProps, 'variant' | 'active' | 'onClick'> & {
    name: string
    value: string | boolean
    schema?: FormikStringSchema
    onClick?: () => void
    deselectValue?: string | boolean | null
  }
) => {
  const { field, helpers } = useFieldHelpers({
    name: props.name,
    schema: props.schema,
  })

  const selected = field.value === props.value

  return (
    <Button
      {...props}
      onClick={async () => {
        await helpers.setValue(
          //in order to allow the button to act as a true toggle, set the value to the deselected value if one is provided
          props.deselectValue !== undefined && selected
            ? props.deselectValue
            : props.value
        )
        props.onClick?.()
      }}
      variant={selected ? 'primary' : 'secondary'}
    />
  )
}

export const FormikLocationSearchInput = ({
  name,
  street2Name = 'street2',
  cityName = 'city',
  stateName = 'state',
  zipName = 'zip',
  schema,
  ...props
}: Omit<LocationSearchInputProps, 'value' | 'onChange'> & {
  name: string
  street2Name?: string
  cityName?: string
  stateName?: string
  zipName?: string
  schema?: FormikStringSchema
}) => {
  const { setFieldValue } = useFormikContext()
  const { field, helpers, error } = useFieldHelpers({ name, schema })

  return (
    <LocationSearchInput
      {...props}
      {...field}
      onChange={(address: string) => helpers.setValue(address)}
      onChangeFullAddress={({
        singleLineAddressString,
        parsedAddress: {
          addressLine1,
          addressLine2,
          zipCode,
          placeName,
          stateAbbreviation,
        },
      }) => {
        if (props.singleLineAddressOnly) {
          setFieldValue(name, singleLineAddressString)
        }

        if (props.streetAddressOnly) {
          setFieldValue(name, addressLine1)
          setFieldValue(street2Name, addressLine2)
        }

        setFieldValue(cityName, placeName)
        setFieldValue(stateName, stateAbbreviation)
        setFieldValue(zipName, zipCode)
      }}
      error={error}
    />
  )
}

const stringToEditorState = (html: string) => {
  const blocksFromHTML = convertFromHTML(html)
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )

  return EditorState.createWithContent(content)
}

export const FormikDraftInput = ({
  bodyName,
  htmlBodyName,
  ...rest
}: {
  bodyName: string
  htmlBodyName: string
} & StyledEditorDraftProps) => {
  // Values typing is really bad but it's safe as long as htmlBodyName is valid
  const { setFieldValue, setFieldTouched, initialValues } =
    useFormikContext<Record<string, string>>()

  const initialBody = initialValues[htmlBodyName]

  const [editorState, setEditorState] = useState(
    stringToEditorState(initialBody)
  )

  useEffect(() => {
    setEditorState(stringToEditorState(initialBody))
  }, [htmlBodyName, initialBody])

  return (
    <StyledDraftEditor
      {...rest}
      editorState={editorState}
      onEditorStateChange={(newEditorState) => {
        const { body, htmlBody } = convertDraftToCommentBody(editorState)
        setFieldValue(bodyName, body)
        setFieldValue(htmlBodyName, htmlBody)
        setEditorState(newEditorState)
      }}
      onBlur={() => {
        setFieldTouched(bodyName)
        setFieldTouched(htmlBodyName)
      }}
    />
  )
}

export const FormikLabelError = ({
  name,
  schema,
  ...rest
}: LabelDescriptionProps & {
  name: string
  schema?: AnySchema
}) => {
  const { error } = useFieldHelpers({ name, schema })

  return (
    <>
      <LabelDescription {...rest} />
      <InputError error={error || undefined} />
    </>
  )
}

export const FormikHiddenInput = ({
  name,
  value,
}: {
  name: string
  value?: string | number
}) => {
  const { setFieldValue } = useFormikContext()

  // There is a performance hit here because `useEffect` will trigger with every change of the form
  // This helper is nice though because there's less manual setting of form values
  useEffect(() => {
    setFieldValue(name, value)
  }, [name, setFieldValue, value])

  return <input type="hidden" value={value} name={name} />
}

export const FormikTextArea = (
  props: Omit<TextAreaProps, 'onChange' | 'value'> & {
    name: string
    schema?: FormikStringSchema
  }
) => {
  const { name, label, schema } = props
  const { field, helpers, error } = useFieldHelpers({ name, schema })

  return (
    <TextArea
      placeholder={typeof label === 'string' && label ? label : undefined}
      {...omit(props, ['onChangeCallback', 'validate'])}
      {...field}
      value={field.value ?? ''}
      onChange={(value: string) => helpers.setValue(value)}
      error={error}
    />
  )
}

export const FormikDebugger = () => {
  const { values, isValid, errors } = useFormikContext()

  return (
    <Card backgroundColor="lightRed">
      <Text as="h2" color="red">
        FORMIK DEBUGGER
      </Text>
      <Text color="red">Values: {JSON.stringify(values)}</Text>
      <Text color="red">Is valid: {isValid.toString()}</Text>
      <Text color="red">Errors: {JSON.stringify(errors)}</Text>
    </Card>
  )
}

export function getFieldName<T>(key: keyof T) {
  return key
}

// Maps field names to a key - value pair.  This is to help avoid non type safe strings in our field names
// Usage const fieldNames = getFieldNames(formik)
// fieldNames.description // valid (this is in formik values)
// fieldName.foo // error (this is not in formik values)
export const getFieldNames = <T extends { [key: string]: unknown }>({
  values,
}: {
  values: T
}) => mapValues(values, (_, key) => key)

const getArticle = (value: string, article?: string) =>
  article ?? (/^[AEIOUaeiou]/g.test(value) ? 'an' : 'a')

interface StringSchemaProps {
  field?: string
  end?: string
  article?: string
  required?: boolean
}

export const makeReqStringSchema = (
  { field, end, article, required = true }: StringSchemaProps = {
    field: undefined,
    end: undefined,
    article: undefined,
    required: true,
  }
) => {
  const schema = yup.string().nullable()

  return required
    ? schema.required(
        field
          ? `Please enter ${getArticle(field, article)} ${field}${
              end ? ` ${end}` : ''
            }`
          : 'Required field'
      )
    : schema
}

export const makeReqEmailSchema = (props?: StringSchemaProps) =>
  makeReqStringSchema(props).email('Please enter a valid email')

export const makeDateSchema = (
  props?: StringSchemaProps & {
    format?: string
    error?: string
    rangeError?: string
    strict?: boolean
    maxDate?: string
    minDate?: string
  }
) => {
  const {
    field,
    end,
    article,
    format = DATE_FORMATS.INPUT,
    error = 'Please enter a valid date',
    rangeError = 'Date is outside of the valid date range',
    strict = false,
    required = true,
    maxDate,
    minDate,
  } = props || {}

  const message = field ? `Please enter a valid date for ${field}` : error
  let schema = yup.string().nullable()

  schema = schema.test('date', message, (val) => {
    if (!required && !val) return true
    if (!val) return false
    return moment(val, format, strict).isValid()
  })

  if (minDate || maxDate) {
    schema = schema.test('date', rangeError, (val) => {
      if (val) {
        const selectedDate = moment(val, format)

        return (
          (!minDate || selectedDate.isSameOrAfter(minDate)) &&
          (!maxDate || selectedDate.isSameOrBefore(maxDate))
        )
      }

      return true
    })
  }

  return required
    ? schema.concat(makeReqStringSchema({ field, end, article }))
    : schema
}

export interface NumberSchema extends StringSchemaProps {
  allowedDecimals?: number
  numDigits?: number
  allowLeadingZero?: boolean
  allowNegative?: boolean
  maxNumber?: number
  minNumber?: number
  greaterThanZero?: boolean
  transactionNotZero?: boolean
}

export const makeNumberSchema = ({
  field,
  allowedDecimals = 0,
  end,
  article,
  numDigits,
  allowLeadingZero,
  allowNegative,
  maxNumber,
  minNumber,
  required = true,
  greaterThanZero,
  transactionNotZero,
}: NumberSchema) => {
  const startReg = allowNegative ? '^-?' : '^'
  const mainNumberReg = allowLeadingZero ? '\\d*' : '(?:0|[1-9]\\d*)'
  const regexp = new RegExp(
    `${startReg}${mainNumberReg}(?:\\.\\d{0,${allowedDecimals}})?$`,
    'g'
  )
  let schema = makeReqStringSchema({ field, end, article, required })
    .concat(
      yup.string().nullable().matches(regexp, 'Please enter a valid value')
    )
    .test('required', 'Please enter a valid value', (val) =>
      required ? Boolean(val) : true
    )

  if (numDigits) {
    schema = schema.test(
      'len',
      `Must be exactly ${numDigits} digits`,
      (val) => val?.toString().length === numDigits
    )
  }

  if (typeof maxNumber === 'number') {
    schema = schema.test(
      'maxNumber',
      `Must be less than or equal to ${maxNumber}`,
      (val) => Number(val) <= maxNumber
    )
  }
  if (typeof minNumber === 'number') {
    schema = schema.test(
      'maxNumber',
      `Must be greater than or equal to ${minNumber}`,
      (val) => Number(val) >= minNumber
    )
  }
  if (greaterThanZero) {
    schema = schema.test(
      'greaterThanZero',
      'This amount must be greater than 0.',
      (val) => Number(val) > 0
    )
  }
  if (transactionNotZero) {
    schema = schema.test(
      'transactionNotZero',
      `${field} may not be 0.00.`,
      (val) => Number(val) !== 0
    )
  }

  return schema
}

export const makeSsnSchema = ({
  field = 'social security number',
  article,
  required = true,
}: {
  field?: string
  article?: string
  required?: boolean
}) => {
  const regexp = new RegExp(/^\d{3}-?\d{2}-?\d{4}$/, 'g')
  return yup
    .string()
    .nullable()
    .length(11, 'Must be exactly 9 digits')
    .matches(regexp, 'Please enter a valid value')
    .test(
      'required',
      `Please enter ${getArticle(field, article)} ${field}`,
      (val) => (required ? Boolean(val) : true)
    )
}

export const makeStringArraySchema = ({
  min = 1,
  field = 'option',
  article = 'an',
  required = true,
}: {
  min?: number
  field?: string
  article?: string
  required?: boolean
} = {}) => {
  return yup
    .array()
    .of(yup.string())
    .min(min)
    .test(
      'required',
      `Please select ${getArticle(field, article)} ${field}`,
      (val) => (required ? Boolean(val) : true)
    )
}

export const makeReqBoolSchema = (props?: {
  error?: string
  trueOnly?: boolean
}) => {
  const { error, trueOnly } = props || {}
  const shownError =
    error || (trueOnly ? 'Please confirm' : 'Please select a value')

  let schema = yup.boolean().nullable().required(shownError)

  if (trueOnly) {
    schema = schema.oneOf([true], shownError)
  }

  return schema
}

export const makeReqPhoneNumberSchema = (
  { required = true }: { required: boolean } = { required: true }
) =>
  yup
    .string()
    .nullable()
    .test('phone', 'Must be a valid phone number', (val) => {
      if (!required && !val) {
        return true
      }
      return isValidPhoneNumber(val || '')
    })

export const makePasswordSchema = () =>
  yup
    .string()
    .required('Please enter a password')
    .min(8, 'Password should have at least 8 characters')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password should have at least one symbol')
    .matches(/^\S*$/, 'Password should not have spaces')

// Some phone inputs need the 10 digit number version instead of the one with country code (ie +18005555555) will turn to 8005555555
export const parseNationalPhoneNumber = (phoneInput?: string) =>
  parsePhoneNumber(phoneInput || '')?.nationalNumber

export const einRegex = new RegExp(/^\d{2}-?\d{7}$/, 'g')

export const makeEinSchema = ({
  field = 'ein',
  article,
  required = true,
}: {
  field?: string
  article?: string
  required?: boolean
}) =>
  yup
    .string()
    .nullable()
    .matches(einRegex, {
      message: 'Must be exactly 9 digits',
      excludeEmptyString: !required,
    })
    .test(
      'required',
      `Please enter ${getArticle(field, article)} ${field}`,
      (val) => (required ? Boolean(val) : true)
    )
