import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { orderBy, partition, sortBy } from 'lodash'
import { Grid } from 'semantic-ui-react'
import {
  UserTaxCalculation,
  UserTaxCalculationStatuses,
  UserTaxCalculationTabNames,
} from '../userTaxCalculation.slice'
import CalculationTableRow from './CalculationTableRow'
import TaxCalculationStatistics from './TaxCalculationStatistics'
import { selectQuarterlyTaxEstimateDetailsByYearAndQuarter } from '../../QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { DATE_FORMATS, isoToUTCDateTime } from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  Checkbox,
  GridRowColumn,
  Input,
  Pagination,
  Table,
} from '../../../../components/BaseComponents'
import { useTableSort } from '../../../../utils/sortHelpers'
import { Colors } from '../../../../styles/theme'
import {
  getAllUsersById,
  selectIsTaxCoordinator,
} from '../../../../selectors/user.selectors'
import { quarterUsesAbound } from '../service'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
interface Props {
  calculations: UserTaxCalculation[]
  calendarYear: string
  calendarQuarter: string
  activeTab: UserTaxCalculationTabNames
}

const ItalicAuxiliaryText = ({ content }: { content: string }) => (
  <span
    style={{
      fontStyle: 'italic',
      fontWeight: 'normal',
      float: 'right',
      overflowWrap: 'break-word',
    }}
  >
    {content}
  </span>
)

const whiteBg = { backgroundColor: Colors.white }

enum SORT_BY {
  default = 'default',
  userName = 'userName',
  taxProfile = 'taxProfile',
}

const isReady = (calc: UserTaxCalculation, newUserCutOffAt: string) => {
  const reviewedSinceNewUserCutOff =
    calc.taxProfileLastReviewedAt &&
    isoToUTCDateTime(calc.taxProfileLastReviewedAt) >=
      isoToUTCDateTime(newUserCutOffAt).startOf('day')
  return (
    reviewedSinceNewUserCutOff &&
    calc.booksClosedForPreviousMonths &&
    calc.hasPaidAllTaxEstimates
  )
}

const nameFilter = (c: UserTaxCalculation, userSearch: string) => {
  return `${c.firstName} ${c.lastName}`
    .toLowerCase()
    .includes(userSearch.toLowerCase())
}

const CalculationTable = ({
  calculations,
  calendarQuarter,
  calendarYear,
  activeTab,
}: Props) => {
  const [userSearch, setUserSearch] = useState('')
  const [readyToCalculateFilter, setReadyToCalculateFilter] = useState(true)

  const users = useReselector(getAllUsersById)
  const isTaxCoordinator = useReselector(selectIsTaxCoordinator)

  const usesAbound = quarterUsesAbound({ calendarYear, calendarQuarter })

  const quarterDetails = useReselector(
    selectQuarterlyTaxEstimateDetailsByYearAndQuarter,
    calendarYear,
    calendarQuarter
  )

  const bookkeepingPeriod = `${moment
    .utc(quarterDetails?.bookkeepingPeriodStartsAt)
    .format(DATE_FORMATS.DISPLAY_SHORT)} - ${moment
    .utc(quarterDetails?.bookkeepingPeriodEndsAt)
    .format(DATE_FORMATS.DISPLAY_SHORT)}`

  const firstCalculatedAt = useMemo(
    () => orderBy(calculations, ['calculatedAt'], ['asc'])[0],
    [calculations]
  )

  const calculationToOccurOn = useMemo(
    () =>
      activeTab === UserTaxCalculationTabNames.singleState ||
      UserTaxCalculationTabNames.all
        ? firstCalculatedAt
          ? firstCalculatedAt.calculatedAt
            ? `performed on ${moment(firstCalculatedAt.calculatedAt).format(
                DATE_FORMATS.DISPLAY_SHORT
              )}`
            : `occurs on ${moment
                .utc(quarterDetails?.calculationStartsAt)
                .format(DATE_FORMATS.DISPLAY_SHORT)}`
          : 'no calculations exist'
        : activeTab === UserTaxCalculationTabNames.edgeCases ||
            activeTab === UserTaxCalculationTabNames.multiState
          ? 'to be manually calculated'
          : '',
    [firstCalculatedAt, activeTab, quarterDetails?.calculationStartsAt]
  )

  const calculationStartDate = moment.utc(quarterDetails?.calculationStartsAt)
  const paymentDueDate = moment.utc(quarterDetails?.irsPaymentDueAt)
  const filteredValues = useMemo(() => {
    if (!calculations) return []
    if (userSearch && readyToCalculateFilter) {
      return calculations.filter(
        (c) =>
          nameFilter(c, userSearch) &&
          isReady(c, quarterDetails?.newUserCutOffAt || '')
      )
    } else if (userSearch) {
      return calculations.filter((c) => nameFilter(c, userSearch))
    }
    if (readyToCalculateFilter) {
      return calculations.filter((c) =>
        isReady(c, quarterDetails?.newUserCutOffAt || '')
      )
    }
    return calculations
  }, [
    calculations,
    quarterDetails?.newUserCutOffAt,
    readyToCalculateFilter,
    userSearch,
  ])

  const sortFunction = useCallback(
    (
      values: UserTaxCalculation[],
      sortByValue: string,
      sortDir?: 'ascending' | 'descending'
    ) => {
      const direction = sortDir === 'ascending' ? 'asc' : 'desc'
      if (sortByValue === SORT_BY.userName) {
        return sortBy(values, (val) => `${val.firstName} ${val.lastName}`)
      } else if (sortByValue === SORT_BY.taxProfile && users) {
        const [notNullTaxProfileRev, nullTaxProfileRev] = partition(
          values,
          (val) => val.taxProfileLastReviewedAt
        )
        if (direction === 'asc') {
          return [
            ...sortBy(
              notNullTaxProfileRev,
              (val) => val.taxProfileLastReviewedAt
            ),
            ...nullTaxProfileRev,
          ]
        }
        return [
          ...nullTaxProfileRev,
          ...sortBy(
            notNullTaxProfileRev,
            (val) => val.taxProfileLastReviewedAt
          ),
        ]
      }
      return orderBy(values, ['plInputsUpdatedAt', 'calculatedAt'], ['asc'])
    },
    [users]
  )

  const { sortedValues, getHeaderCellProps, paginationProps } = useTableSort({
    values: filteredValues,
    defaultSortBy: SORT_BY.default,
    pageSize: 25,
    sortFunction,
  })

  const isInEditableTimeFrame = useMemo(
    () =>
      moment().isBetween(calculationStartDate, paymentDueDate) ||
      (isTaxCoordinator &&
        (activeTab === UserTaxCalculationTabNames.multiState ||
          activeTab === UserTaxCalculationTabNames.edgeCases) &&
        moment().isBefore(paymentDueDate)),
    [calculationStartDate, paymentDueDate, activeTab, isTaxCoordinator]
  )

  const shouldShowCalculations =
    activeTab !== UserTaxCalculationTabNames.edgeCases &&
    activeTab !== UserTaxCalculationTabNames.multiState

  const allowEditReleasedUserTaxEstimates = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.allowEditReleasedUserTaxEstimates,
    false
  )

  return (
    <>
      <TaxCalculationStatistics
        quarterNotificationDate={quarterDetails?.clientNotifiedAt}
        calculations={calculations}
        newUserCutOffAt={quarterDetails?.newUserCutOffAt}
      />
      <Grid>
        <GridRowColumn>
          <Input
            label="User search"
            value={userSearch}
            onChange={setUserSearch}
            clearable
          />
        </GridRowColumn>
        <GridRowColumn>
          <Checkbox
            label="Use 'Ready to Calculate' Filter"
            checked={readyToCalculateFilter}
            value={readyToCalculateFilter}
            onChange={setReadyToCalculateFilter}
          />
        </GridRowColumn>
      </Grid>
      <Table
        style={{
          ...whiteBg,
          margin: '0 auto',
          marginBottom: '1em',
          marginTop: '1em',
          width: '98%',
        }}
        basic
        celled
        striped
        sortable
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={3}
              style={whiteBg}
              {...getHeaderCellProps(SORT_BY.userName)}
            >
              Customer (sortable)
              <ItalicAuxiliaryText content="(by first name)" />
            </Table.HeaderCell>
            {shouldShowCalculations && (
              <Table.HeaderCell style={whiteBg}>
                Calculation Inputs
                <ItalicAuxiliaryText
                  content={'High level details for single state'}
                />
              </Table.HeaderCell>
            )}
            {shouldShowCalculations && usesAbound && (
              <Table.HeaderCell style={whiteBg}>
                Calculation Outputs
                <ItalicAuxiliaryText content={calculationToOccurOn} />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell style={whiteBg}>
              Final Estimates
              <ItalicAuxiliaryText content={'To be shared with customer'} />
            </Table.HeaderCell>
            <Table.HeaderCell
              style={whiteBg}
              {...getHeaderCellProps(SORT_BY.taxProfile)}
            >
              Checklist completion status (sortable)
              <ItalicAuxiliaryText content="(by profile last reviewed, nulls always last)" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2} style={whiteBg}>
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedValues.map((c) => (
            <CalculationTableRow
              shouldShowCalculations={shouldShowCalculations}
              key={`calc-${c.id}`}
              id={c.id}
              bookkeepingPeriod={bookkeepingPeriod}
              // Normally, editing a row's calculations shouldn't be allowed if Today is before calculation date or after payment due date or The customer has already been notified
              // However, if allowEditReleasedUserTaxEstimates is true, always allow editing. This should only be used in case of emergency
              calculationEditHidden={
                !isInEditableTimeFrame ||
                (allowEditReleasedUserTaxEstimates
                  ? false
                  : c.status === UserTaxCalculationStatuses.savedAndNotified)
              }
              newUserCutOffAt={quarterDetails?.newUserCutOffAt}
            />
          ))}
        </Table.Body>
      </Table>
      <Pagination {...paginationProps} />
    </>
  )
}

export default CalculationTable
