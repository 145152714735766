import { Grid, Image } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { TaxSeasonKickoffSurveyProps } from './YearEndKickoffSurvey'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'
import { useEffect } from 'react'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { useSaveAnnualTaxFilingFormData } from '../helpers'

const CCorpDisqualified = ({ goBack }: TaxSeasonKickoffSurveyProps) => {
  const navigate = useNavigate()
  const currentAnnualTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentAnnualTaxYear
  )
  const saveTaxFiling = useSaveAnnualTaxFilingFormData(currentAnnualTaxYear)

  useEffect(() => {
    if (annualTaxFiling?.id) {
      saveTaxFiling({ taxSeasonSurveyNeedHelp: true })
    }
  }, [annualTaxFiling?.id, saveTaxFiling])
  return (
    <Grid>
      <GridRowColumn width={12}>
        <Card type="subsection" backgroundColor="stone40">
          <Grid>
            <GridRowColumn centerContent>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/seedling.svg"
                style={{ width: 300, height: 300 }}
              />
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)}>
              <Text as="h1" textAlign="center">
                About C corporations
              </Text>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)}>
              <Text as="h3" textAlign="center">
                We are unable to support what your practice needs.
              </Text>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)}>
              <Text>
                We are currently only able to support C corporation business
                entities that are taxed as S corporations for the entire tax
                year.
                <br />
                <br />A member of our team will reach out to you shortly to
                discuss next steps.
              </Text>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)} centerContent>
              <Button onClick={() => navigate('/taxes/annual')}>
                Go to Home
              </Button>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)} centerContent>
              <Button
                variant="secondaryLink"
                onClick={() => goBack(TAX_SEASON_KICKOFF_SURVEY.taxEntity)}
              >
                Go back
              </Button>
            </GridRowColumn>
          </Grid>
        </Card>
      </GridRowColumn>
    </Grid>
  )
}

export default CCorpDisqualified
