import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Text from '../../../../components/BaseComponents/Text'
import { Alert, Icon, Link } from '../../../../components/BaseComponents'
import { LandingPage } from '../../../../components/LandingPage'
import './styles.scss'

export const DocumentsIntro = () => {
  return (
    <LandingPage
      imageSrc="https://heard-images.s3.amazonaws.com/assets/checklist.svg"
      imageAlt="books"
      imageWidth={300}
      imageHeight={300}
      kickerText={
        <>
          <Icon icon={regular('timer')} />
          <Text as="h2" style={{ marginTop: '16px' }}>
            20 minutes
          </Text>
        </>
      }
      title="Gather your year-end documents"
    >
      <Text style={{ marginTop: '16px' }}>
        Next, we’ll use your books to determine which documents we need to close
        your books for the year.
      </Text>

      <div className="review-items">
        <Text as="h3" style={{ marginTop: '16px' }}>
          We’ll go over:
        </Text>

        <ul>
          <li>Payroll</li>
          <li>Contractor payments</li>
          <li>Processing fees</li>
          <li>Receipts for assets &gt;$2,500</li>
        </ul>
      </div>

      <Alert
        style={{ marginTop: '32px' }}
        title="Why does Heard request year-end documents?"
        customIcon={<Icon icon={solid('info-circle')} />}
        contentStyle={{ textAlign: 'left' }}
      >
        <ul className="why-request-items">
          <li>
            <Text>
              More accurate books for annual tax filing. Allows us to find tax
              savings
            </Text>
          </li>
          <li>
            <Text>by identifying and confirming all relevant deductions.</Text>
          </li>
        </ul>

        <div>
          <Link
            newPage
            href="https://support.joinheard.com/hc/en-us/articles/19807834411287-Why-does-Heard-request-year-end-documents"
          >
            Learn more -&gt;
          </Link>
        </div>
      </Alert>
    </LandingPage>
  )
}
