import { useState } from 'react'
import {
  thin,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Button,
  Card,
  Checkbox,
  Icon,
  Link,
  Popup,
  Text,
} from '../../BaseComponents'
import { StackedIcon } from '../../BaseComponents/Icon'
import { UserDocument } from '../../../features/UserDocuments/userDocuments.slice'
import styles from './styles.module.scss'
import { Divider } from 'semantic-ui-react'

interface IDocumentUploadCardProps {
  name: string
  bookkeeperNote?: string
  description: string
  documents?: Pick<UserDocument, 'id' | 'awsFileName'>[]
  hasFileUploadError?: boolean
  linkText?: string
  requiredDocCount?: number
  setChecked?: () => void
  status?: 'none' | 'disabled' | 'success'
}

const icons = {
  disabled: {
    icon: solid('xmark'),
    backgroundColor: 'gray',
  },
  success: {
    icon: solid('check'),
    backgroundColor: 'green',
  },
} as const

const bgColor = {
  none: 'natural',
  disabled: 'stone40',
  success: 'lightGreen',
  error: 'lightRed',
} as const

export const DocumentUploadCard = ({
  name,
  description,
  bookkeeperNote,
  documents = [],
  hasFileUploadError = false,
  linkText = 'View instructions',
  requiredDocCount = 0,
  setChecked,
  status = 'none',
}: IDocumentUploadCardProps) => {
  const [isNotRelevantChecked, setIsNotRelevantChecked] = useState(false)

  const handleSetChecked = () => {
    setIsNotRelevantChecked((prev) => !prev)

    if (setChecked) {
      setChecked()
    }
  }

  return (
    <Card backgroundColor={bgColor[status]}>
      <div className={styles.card}>
        <div>
          {status === 'none' ? (
            <Icon
              color="gray"
              icon={thin('circle')}
              size="3x"
              title={`${status}-status-icon`}
            />
          ) : (
            <StackedIcon
              title={`${status}-status-icon`}
              color="white"
              size="1x"
              backgroundIcon={solid('circle')}
              backgroundSize={'3x'}
              {...icons[status]}
            />
          )}
        </div>

        <div className={styles.centerColumn}>
          <Text as="h3">{name}</Text>
          <Text className={styles.description}>{description}</Text>
          <div className={styles.instructions}>
            <Link to={'/#'}>{linkText}</Link>
            <Icon
              icon={regular('square-arrow-up-right')}
              className={styles.instructionsIcon}
            />
          </div>

          {bookkeeperNote && (
            <>
              <Text as="h3" color="red" className={styles.reuploadDoc}>
                <strong>Please reupload this document.</strong>
              </Text>

              <Text color="red">Bookkeeper Note: {bookkeeperNote}</Text>
            </>
          )}

          {documents.length > 0 && (
            <>
              <Divider />

              {documents?.map((doc) => (
                <div key={doc.awsFileName} className={styles.document}>
                  <Text className={styles.docName} color="green">
                    {doc.awsFileName}
                  </Text>

                  <Icon
                    icon={regular('trash-alt')}
                    size="lg"
                    className={styles.docDeleteBtn}
                  />
                </div>
              ))}

              {requiredDocCount > 1 && (
                <Text className={styles.docCount}>
                  Uploaded {documents.length} of {requiredDocCount} documents
                </Text>
              )}
            </>
          )}
        </div>

        <div className={styles.rightColumn}>
          <Button className={styles.uploadBtn} variant="secondary" fullWidth>
            <Icon
              icon={regular('upload')}
              size="lg"
              className={styles.uploadIcon}
            />
            Upload
          </Button>

          {hasFileUploadError && (
            <Text color="red" className={styles.error}>
              <strong>Error: </strong>Your document couldn’t be uploaded. Please
              try again later.
            </Text>
          )}

          {documents?.length === 0 && setChecked && (
            <Popup
              hoverable
              content={
                <div className={styles.popup}>
                  <Text as="h3" className={styles.header}>
                    <strong>Not relevant</strong>
                  </Text>

                  <Text className={styles.topText}>
                    Check this box only if you’re sure this document doesn’t
                    apply to you.
                  </Text>

                  <Text className={styles.bottomText}>
                    <strong>We recommend double-checking first</strong>
                  </Text>

                  <Text>
                    This could delay your end-of-year bookkeeping and annual tax
                    filing if we find we still need the document.
                  </Text>
                </div>
              }
              trigger={
                <Checkbox
                  id={name}
                  label="Not relevant"
                  onChange={handleSetChecked}
                  checked={isNotRelevantChecked}
                />
              }
            />
          )}
        </div>
      </div>
    </Card>
  )
}
