import axios from 'axios'
import { keyBy } from 'lodash'
import { Moment } from 'moment'

import {
  receiveUserDocuments,
  destroyUserDocument,
  receiveSingleUserDocument,
  UserDocument,
} from '../../features/UserDocuments/userDocuments.slice'
import { fetchWrapper } from '../../reducers/fetch'
import { StatementData } from '../../components/FileUpload/StatementUploadSection'
import {
  TaxUserDocument,
  destroySingleTaxUserDocument,
} from '../../features/Taxes/AnnualTaxes/taxUserDocuments.slice'

export const adminCreateUserDocument = (data: {
  awsFileName: string
  fileDescription: string
  userId: number
  transactionId: number | null | undefined
  documentType: string
  statementMonth: Moment | null
  statementFinancialAccountId: number | null
  userDocumentCategoryId: number | null
  statement?: StatementData
  year?: string
  providerId?: number | null
}) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error creating the user document.',
    defaultValue: null,
    fetchFunction: (dispatch) =>
      axios
        .post<{
          financialProfileId: number
          documents: UserDocument
          taxUserDocument?: TaxUserDocument
        }>('/finances/api/v1/admin/user_documents', {
          ...data,
          statementMonth: data.statementMonth?.format('YYYY-MM'),
        })
        .then((json) => {
          dispatch(
            receiveSingleUserDocument({
              userDocument: json.data.documents,
              taxUserDocument: json.data.taxUserDocument,
            })
          )
          return json.data.documents
        }),
  })

export const REQUEST_ADMIN_USER_DOCUMENTS_KEY = (userId: number | string) =>
  `REQUEST_ADMIN_USER_DOCUMENTS_KEY_${userId}`
export const fetchAdminUsersDocuments = (userId: number | string) =>
  fetchWrapper({
    fetchKey: REQUEST_ADMIN_USER_DOCUMENTS_KEY(userId),
    defaultErrorMessage: 'There was an error fetching all user documents.',
    fetchFunction: (dispatch) =>
      axios
        .get<{
          documents: UserDocument[]
          financialProfileId: number
        }>(`/finances/api/v1/admin/user_documents/${userId}`)
        .then((json) => {
          dispatch(receiveUserDocuments(keyBy(json.data.documents, 'id')))
          return json.data
        }),
  })

export const adminDestroyUserDocument = (documentId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error creating the user document.',
    fetchFunction: (dispatch) =>
      axios
        .delete<{
          userDocumentId: number
          financialProfileId: number
          taxDocumentId: number | null
          deletedTaxUserDocumentId: number | null
        }>(`/finances/api/v1/admin/user_documents/${documentId}`)
        .then((json) => {
          dispatch(
            destroyUserDocument({
              deletedId: json.data.userDocumentId,
              deletedTaxDocumentId: json.data.taxDocumentId,
              deletedTaxUserDocumentId: json.data.deletedTaxUserDocumentId,
            })
          )
          return json.data
        }),
  })

export const adminUpdateUserDocument = (
  documentId: number,
  data: Partial<UserDocument> & { year?: string | null }
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.put<{
        document: UserDocument
        financialProfileId: number
        taxUserDocument: TaxUserDocument
        deletedTaxUserDocumentId?: number
      }>(`/finances/api/v1/admin/user_documents/${documentId}`, data)

      dispatch(
        receiveSingleUserDocument({
          userDocument: json.data.document,
          taxUserDocument: json.data.taxUserDocument,
        })
      )
      if (json.data.deletedTaxUserDocumentId) {
        dispatch(
          destroySingleTaxUserDocument(json.data.deletedTaxUserDocumentId)
        )
      }

      return json.data
    },
  })

export const adminUpdateStatement = (
  documentId: number,
  data: Partial<UserDocument> & {
    statementFinancialAccounts?: {
      accountsToCreate: number[]
      accountsToDelete: number[]
    }
  }
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const json = await axios.put<{
        document: UserDocument
        financialProfileId: number
      }>(`/finances/api/v1/admin/user_documents/statement/${documentId}`, data)

      dispatch(receiveSingleUserDocument({ userDocument: json.data.document }))

      return json.data
    },
  })
