import { Grid, Image } from 'semantic-ui-react'
import {
  Alert,
  Button,
  GridRowColumn,
  Pill,
  Text,
} from '../../../components/BaseComponents'
import Card from '../../../components/BaseComponents/Card'
import {
  createUpriseAccount,
  FinancialAdvisoryProfile,
  FinancialAdvisoryProfilesStatus,
  putFinancialAdvisoryProfile,
} from '../financialAdvisory.slice'
import { useEffect, useState } from 'react'
import { track } from '@amplitude/analytics-browser'
import { useAnalyticsView } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { UpriseIntroCard } from './IntroCallCTAPanel'

const PostPaymentPanel = ({
  faProfile,
}: {
  faProfile: FinancialAdvisoryProfile
}) => {
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const submitUpriseOnboarding = async () => {
    setLoading(true)
    setError('')
    try {
      track('started uprise onboarding — post purchase', {
        fa_profile_id: faProfile.id,
        user_id: faProfile.userId,
      })
      const response = await createUpriseAccount()(dispatch)
      await putFinancialAdvisoryProfile(faProfile.id, {
        status: FinancialAdvisoryProfilesStatus.onboarding,
      })(dispatch)
      if (!response) {
        setError('Something went wrong! Please refresh and try again.')
      }
    } catch {
      setError('An unexpected error occurred. Please try again later.')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    pageView('advisory: post payment panel')
  }, [pageView])

  return (
    <>
      {error && (
        <Alert type="error">
          It looks like we encountered an error while trying to onboard you!
          Please try again.
        </Alert>
      )}
      {faProfile.subscriptionStatus === 'paid' && (
        <Alert type="info">Thank you for purchasing the Advisory Add On!</Alert>
      )}
      <Card type="subsection" backgroundColor="natural">
        <Grid stackable doubling centered>
          <GridRowColumn />
          <Grid.Row>
            <Grid.Column width={10}>
              <Grid style={{ margin: '0 5px' }}>
                <GridRowColumn>
                  <Pill>Almost There</Pill>
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="h1">
                    Onboard to receive your 100% personalized financial plan
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="bodyMd">
                    We are so excited to support you with your financial goals!
                  </Text>
                  <br />
                  <Text as="bodyMd">
                    Your next step is to onboard and share additional details of
                    your financial situation. This includes information about
                    your personal financial goals and additional color around
                    your holistic financial picture (assets, personal accounts,
                    investments etc.), which would enable your advisor to build
                    a comprehensive plan.
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Button
                    variant="primary"
                    loading={loading}
                    onClick={() => submitUpriseOnboarding()}
                  >
                    Start Onboarding
                  </Button>
                </GridRowColumn>
              </Grid>
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle">
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisory_screen.svg"
                alt="heard financial advisory"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <br />
      <UpriseIntroCard />
      <br />
    </>
  )
}

export default PostPaymentPanel
