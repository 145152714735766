import * as Sentry from '@sentry/react'
import { createSelector } from 'reselect'
import { Dictionary, groupBy, isArray } from 'lodash'

import { ReduxState } from '../../../../utils/typeHelpers'
import { getAllFetchState } from '../../../../reducers/fetch'
import { FETCH_USER_TAX_QUESTIONNAIRE_KEY } from './taxChecklistQuestion.actions'
import { TaxListOptionId, TaxListQuestionId } from './service'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  TaxChecklistQuestion,
  TaxChecklistQuestionOption,
  TaxChecklistQuestionsState,
  TaxChecklistResponse,
} from './taxChecklistQuestion.slice'
import {
  getAnnualTaxFilingForms,
  selectAnnualTaxFilingFormById,
  selectFilingFormsForCurrentTaxYear,
  selectFilingFormsForYear,
} from '../annualTaxFilingForms.selector'
import { ANNUAL_TAX_FILING_FORM_TYPES } from '../Questionnaires/constants'
import { selectUserDocumentList } from '../../../UserDocuments/userDocuments.selector'
import {
  getUserDocCategoryByInternalNameFromCategoryList,
  selectUserDocumentCategoryList,
} from '../../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import {
  getUserEoyReviewProgress,
  getUserEoyReviewProgressArray,
} from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { getAllEoyReviewSteps } from './Shared/ReviewStepsandProgresses/endOfYearReviewSteps.selector'
import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { selectSubscriptionIncludesFreePersonalFiling } from '../../../../reducers/subscription.slice'
import {
  COMPLETE_PAYMENT,
  ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
  REVIEW_INCOME_AND_EXPENSES_FINAL,
  REVIEW_INCOME_AND_EXPENSES_INITIAL,
  SubStepIdentifiers,
  TAX_QUESTIONNAIRE_STEPS_1040,
  TAX_QUESTIONNAIRE_STEPS_1120S,
  UPDATE_YOUR_BOOKS,
} from './Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectFilingStatusFor1120s } from '../annualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from '../constants'
import { selectUserBooksLockedForCurrentTaxYear } from '../../../../reducers/auth/user.selectors'
import { UserEoyReviewProgressState } from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { AllEoyReviewStepState } from './Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { AnnualTaxFilingForm } from '../annualTaxFilingForms.slice'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { UserDocumentCategory } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { selectTaxUserDocuments } from '../taxUserDocuments.selector'

export const selectTaxListQuestionState = (state: ReduxState) =>
  state.taxChecklist

export const selectTaxListQuestion = createSelector(
  selectTaxListQuestionState,
  getAllFetchState,
  selectCurrentAnnualTaxYear,
  (_: unknown, questionId: TaxListQuestionId) => questionId,
  (_: unknown, __: unknown, taxYear: string | undefined) => taxYear,
  (tqQuestions, fetchState, currentTaxYear, questionId, taxYear) => {
    const question = tqQuestions.questions[questionId]

    if (!question) {
      if (
        fetchState[FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear || currentTaxYear)]
          ?.receivedAt
      ) {
        Sentry.captureMessage('Could not find tax list question', {
          extra: { questionId },
        })
      }

      return {
        question: null,
        options: [] as TaxChecklistQuestionOption[],
        responses: [] as TaxChecklistResponse[],
      }
    }

    return {
      question,
      options: question.options.map((id) => tqQuestions.options[id]),
      responses: question.responses.map((id) => tqQuestions.responses[id]),
    }
  }
)

export const selectTaxListQuestionsByIds = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionIds: TaxListQuestionId[]) => questionIds,
  (allQuestions, questionIds) =>
    questionIds.map((questionId) => {
      const question = allQuestions.questions[questionId]
      if (!question) {
        return {
          question: null,
          options: [] as TaxChecklistQuestionOption[],
          responses: [] as TaxChecklistResponse[],
        }
      }

      return {
        question,
        options: question?.options?.map((id) => allQuestions.options[id]),
        responses: question?.responses?.map((id) => allQuestions.responses[id]),
      }
    })
)

export const getTaxListQuestionResponseByFormId = (
  allQuestions: TaxChecklistQuestionsState,
  questionId: TaxListQuestionId,
  formId?: number | string
) => {
  const question = allQuestions.questions[questionId]

  if (!question) {
    return []
  }

  return Object.values(allQuestions.responses).filter(
    (response) =>
      question.responses.includes(response.id) &&
      response.annualTaxFilingFormId === Number(formId)
  )
}

export const selectTaxListQuestionResponsesForPriorYear = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionId: TaxListQuestionId) => questionId,
  (_: unknown, __: unknown, formId?: number | string) => formId,
  getAnnualTaxFilingForms,
  (allQuestions, questionId, formId, formMapping) => {
    const matchingForm =
      formId && formMapping[Number(formId)] ? formMapping[Number(formId)] : null

    if (!matchingForm) {
      return null
    }

    const prevYear = (Number(matchingForm.year) - 1).toString()

    const previousYearForm = Object.values(formMapping).find(
      (form) =>
        form.year === prevYear && form.formTypeId === matchingForm.formTypeId
    )

    if (!previousYearForm) {
      return null
    }

    return getTaxListQuestionResponseByFormId(
      allQuestions,
      questionId,
      previousYearForm.id
    )
  }
)

export const selectTaxListQuestionResponsesByFormId = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionId: TaxListQuestionId) => questionId,
  (_: unknown, __: unknown, formId?: number | string) => formId,
  getTaxListQuestionResponseByFormId
)

export const selectTaxListQuestionResponseByFormIdAndDependentId =
  createSelector(
    selectTaxListQuestionState,
    (_: unknown, questionId: TaxListQuestionId) => questionId,
    (_: unknown, __: unknown, formId: number) => formId,
    (_: unknown, __: unknown, ___: unknown, dependentId?: number) =>
      dependentId,
    (allQuestions, questionId, formId, dependentId) => {
      const question = allQuestions.questions[questionId]

      if (!question) {
        return null
      }

      return Object.values(allQuestions.responses).find(
        (response) =>
          question.responses.includes(response.id) &&
          response.annualTaxFilingFormId === formId &&
          response.userDependentId === dependentId
      )
    }
  )

export const selectTaxListQuestionResponseByFormIdAndChildcareProviderId =
  createSelector(
    selectTaxListQuestionState,
    (_: unknown, questionId: TaxListQuestionId) => questionId,
    (_: unknown, __: unknown, formId: number) => formId,
    (_: unknown, __: unknown, ___: unknown, childcareProviderId?: number) =>
      childcareProviderId,
    (allQuestions, questionId, formId, childcareProviderId) => {
      const question = allQuestions.questions[questionId]

      if (!question) {
        return null
      }

      return Object.values(allQuestions.responses).find(
        (response) =>
          question.responses.includes(response.id) &&
          response.annualTaxFilingFormId === formId &&
          response.childcareProviderId === childcareProviderId
      )
    }
  )

export const selectTaxListQuestionResponsesByQuestionIds = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionIds: TaxListQuestionId[]) => questionIds,
  (_: unknown, __: unknown, formId: number) => formId,
  (allQuestions, questionIds, formId) => {
    const responses = Object.values(allQuestions.responses).filter(
      (response) =>
        questionIds.includes(response.questionId) &&
        response.annualTaxFilingFormId === formId
    )

    return responses
  }
)

export type ResponseGroup =
  | 'userDependentId'
  | 'childcareProviderId'
  | 'groupId'

export const selectTaxListQuestionResponseGroup = createSelector(
  selectTaxListQuestionsByIds,
  (_: unknown, __: unknown, formIds?: number[]) => formIds,
  (_: unknown, __: unknown, ___: unknown, group?: ResponseGroup) => group,
  (
    questions,
    formIds,
    group
  ): Dictionary<{
    [key: string | number]: {
      question: TaxChecklistQuestion
      options: TaxChecklistQuestionOption[]
      responses: TaxChecklistResponse[]
    }
  }> => {
    const flattenedResponses = questions
      .map((q) =>
        q.responses.filter(({ annualTaxFilingFormId }) =>
          formIds?.includes(annualTaxFilingFormId)
        )
      )
      .flat()
    const groupedResponses = groupBy(flattenedResponses, group)

    const groups = Object.keys(groupedResponses).map((key, index) => ({
      [key.includes('object') ? index : key]: groupedResponses[key].map(
        (r) => ({
          question: questions.find((q) => q.question?.id === r.questionId)
            ?.question,
          options:
            questions.find((q) => q.question?.id === r.questionId)?.options ??
            [],
          responses: [r],
        })
      ),
    }))
    return Object.assign({}, ...groups)
  }
)

/**
 * Returns true if every response in the question list exists and is false
 */
export const everyResponseInQuestionListIsFalse = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionIds: TaxListQuestionId[]) => questionIds,
  (_: unknown, __: unknown, formId: number) => formId,
  (allQuestions, questionIds, formId) =>
    questionIds.every((questionId) => {
      const question = allQuestions.questions[questionId]

      if (!question) {
        return false
      }

      const response = Object.values(allQuestions.responses).find(
        (response) =>
          question.responses.includes(response.id) &&
          response.annualTaxFilingFormId === formId
      )
      return response?.value === false
    })
)

export const selectTaxListQuestionOptionByOptionId = createSelector(
  selectTaxListQuestion,
  (_: unknown, __: unknown, ___: unknown, optionId: string) => optionId,
  (question, optionId) =>
    question.options.find((option) => option.id.toString() === optionId)
)

export const selectAdminTaxList = createSelector(
  selectTaxListQuestionState,
  selectCurrentAnnualTaxYear,
  (tqQuestions): TaxChecklistQuestion[] => Object.values(tqQuestions.questions)
)

export const selectIsMarriedFilingJointly = createSelector(
  selectTaxListQuestion,
  (question) =>
    question.responses?.[0]?.value === TaxListOptionId.married_filing_jointly
)

export const selectIsMarriedFilingJointlyOrQualifyingWidow = createSelector(
  selectTaxListQuestion,
  (question) =>
    question.responses?.[0]?.value === TaxListOptionId.married_filing_jointly ||
    question.responses?.[0]?.value === TaxListOptionId.qualifying_widow
)

export const selectSpouseResponses = createSelector(
  selectTaxListQuestionState,
  (questionData) =>
    Object.values(questionData.responses).filter((r) =>
      new RegExp(/spouse/i).test(r.questionId)
    )
)

const selectUserNeedsK1 = createSelector(
  selectAnnualTaxFilingFormById,
  selectTaxListQuestionState,
  getAnnualTaxFilingForms,
  (form, tlQState, filingForms) => {
    if (!form) {
      return false
    }

    const incomeFromK1Responses = getTaxListQuestionResponseByFormId(
      tlQState,
      TaxListQuestionId.k1_income,
      form.id
    )

    return (
      incomeFromK1Responses?.[0]?.value ||
      Object.values(filingForms).filter((f) => f.year === form?.year).length > 1
    )
  }
)

export const selectRequiredDocCatsForForm = createSelector(
  selectAnnualTaxFilingFormById,
  selectTaxListQuestionState,
  selectUserNeedsK1,
  (form, tlQState, userNeedsK1) => {
    if (!form) {
      return { required: [], optional: [] }
    }

    const { id, formType } = form

    if (formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040) {
      const healthCareCoverageResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.healthcare_coverage_type,
        id
      )
      const healthCareCoverageSpouseResponse =
        getTaxListQuestionResponseByFormId(
          tlQState,
          TaxListQuestionId.healthcare_coverage_type_spouse,
          id
        )

      const heathCareAccountTypesResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.healthcare_account_types,
        id
      )

      const spouseFirstNameResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.spouse_first_name,
        id
      )

      const homeSaleResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.sold_home,
        id
      )

      const homePurchaseResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.home_purchase,
        id
      )

      const propertyTaxResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.paid_property_taxes,
        id
      )

      const refinanceHomeResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.refinanced_home,
        id
      )

      const homePurchaseResidenceResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.home_purchase_residence_type,
        id
      )

      const offerInCompromiseResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.offer_in_compromise,
        id
      )

      const numberPropertiesResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.number_of_rental_properties,
        id
      )

      const hadBusinessMileageResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.had_business_mileage,
        id
      )

      const hadCollegeStudentResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.had_college_students,
        id
      )

      const hadCharitableContributionResponse =
        getTaxListQuestionResponseByFormId(
          tlQState,
          TaxListQuestionId.charitable_contribution,
          id
        )

      const investmentsBoughtSoldResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.investments_bought_sold,
        id
      )

      const retirementPlanTypeResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.retirement_plan_type,
        id
      )

      const homeSaleResidenceResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.home_sale_residence_type,
        id
      )

      const paidMortgageInterestResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.paid_mortgage_interest,
        id
      )

      const cryptoStatementResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.crypto_statement,
        id
      )

      const disabilityIncomeResponses = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.disability_income,
        id
      )

      return {
        required: [
          UserDocumentCategoryIdentifier.officialGovernmentId,
          ...(spouseFirstNameResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.spouseOfficialGovernmentId]
            : []),

          ...(userNeedsK1 ? [UserDocumentCategoryIdentifier.scheduleK1] : []),
        ],
        optional: [
          ...((isArray(healthCareCoverageResponse?.[0]?.value) &&
            healthCareCoverageResponse?.[0]?.value.includes(
              TaxListOptionId.health_insurance_employer
            )) ||
          (isArray(healthCareCoverageSpouseResponse?.[0]?.value) &&
            healthCareCoverageSpouseResponse?.[0]?.value.includes(
              TaxListOptionId.health_insurance_employer
            ))
            ? [UserDocumentCategoryIdentifier.form1095c]
            : []),

          ...((isArray(healthCareCoverageResponse?.[0]?.value) &&
            healthCareCoverageResponse?.[0]?.value.includes(
              TaxListOptionId.health_insurance_other
            )) ||
          (isArray(healthCareCoverageSpouseResponse?.[0]?.value) &&
            healthCareCoverageSpouseResponse?.[0]?.value.includes(
              TaxListOptionId.health_insurance_other
            ))
            ? [UserDocumentCategoryIdentifier.form1095b]
            : []),

          ...((isArray(healthCareCoverageResponse?.[0]?.value) &&
            healthCareCoverageResponse?.[0]?.value.includes(
              TaxListOptionId.health_insurance_marketplace
            )) ||
          (isArray(healthCareCoverageSpouseResponse?.[0]?.value) &&
            healthCareCoverageSpouseResponse?.[0]?.value.includes(
              TaxListOptionId.health_insurance_marketplace
            ))
            ? [UserDocumentCategoryIdentifier.form1095a]
            : []),

          ...(paidMortgageInterestResponse?.[0]?.value ||
          refinanceHomeResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.form1098]
            : []),

          ...(hadCollegeStudentResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.form1098t]
            : []),

          ...(investmentsBoughtSoldResponse?.[0]?.value ||
          cryptoStatementResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.form1099b]
            : []),

          ...(retirementPlanTypeResponse?.[0]?.value
            ? [
                UserDocumentCategoryIdentifier.form1099r,
                UserDocumentCategoryIdentifier.form5498,
              ]
            : []),

          ...(isArray(heathCareAccountTypesResponse?.[0]?.value) &&
          heathCareAccountTypesResponse?.[0]?.value.find(
            (val) => val === TaxListOptionId.hsa
          )
            ? [UserDocumentCategoryIdentifier.form1099sa]
            : []),

          ...(homeSaleResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.homeSaleDocuments]
            : []),

          ...(homePurchaseResidenceResponse?.[0]?.value ||
          homeSaleResidenceResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.homeDepreciationSchedule]
            : []),

          ...(offerInCompromiseResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.offerInCompromise]
            : []),

          ...(propertyTaxResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.propertyTaxDocuments]
            : []),

          ...(numberPropertiesResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.rentalDocuments]
            : []),

          ...(homePurchaseResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.homePurchaseDocuments]
            : []),

          ...(hadBusinessMileageResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.carRegistration]
            : []),

          ...(hadCharitableContributionResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.charity]
            : []),

          ...(disabilityIncomeResponses?.[0]?.value
            ? [UserDocumentCategoryIdentifier.form1099ssa]
            : []),

          UserDocumentCategoryIdentifier.formW2,
          ...(hadBusinessMileageResponse?.[0]?.value
            ? [UserDocumentCategoryIdentifier.mileageTracker]
            : []),
          UserDocumentCategoryIdentifier.form1099nec,
          UserDocumentCategoryIdentifier.form1099misc,
          UserDocumentCategoryIdentifier.form1099div,
          UserDocumentCategoryIdentifier.form1099k,
          UserDocumentCategoryIdentifier.form1099g,
          UserDocumentCategoryIdentifier.form1099int,
          UserDocumentCategoryIdentifier.form1098e,
          UserDocumentCategoryIdentifier.finalReturnForm1040,
        ],
      }
    }

    return {
      required: [
        UserDocumentCategoryIdentifier.officialGovernmentId,
        UserDocumentCategoryIdentifier.formW3,
        UserDocumentCategoryIdentifier.form941,
        UserDocumentCategoryIdentifier.businessIncorporation,
        UserDocumentCategoryIdentifier.form2553,
        UserDocumentCategoryIdentifier.cp261Notice,
        UserDocumentCategoryIdentifier.einDocuments,
      ],
      optional: [
        UserDocumentCategoryIdentifier.form1099div,
        UserDocumentCategoryIdentifier.form1099int,
        UserDocumentCategoryIdentifier.form1099k,
        UserDocumentCategoryIdentifier.form1099nec,
        UserDocumentCategoryIdentifier.finalReturnForm1120s,
      ],
    }
  }
)

export const selectDocumentProgressForForm = createSelector(
  selectAnnualTaxFilingFormById,
  getAllEoyReviewSteps,
  getUserEoyReviewProgress,
  (form, allEoyReviewSteps, progressesById) => {
    const is1040 = form?.formType?.name === TAX_ENTITY_TYPES.form_1040
    const identifier = is1040
      ? SubStepIdentifiers.uploadDocuments1040
      : SubStepIdentifiers.uploadDocuments1120s

    const step = allEoyReviewSteps[identifier]
      ? allEoyReviewSteps[identifier]
      : null

    return Object.values(progressesById).find(
      (progress) => progress.endOfYearReviewStepId === step?.id
    )
  }
)

export const selectOtherTaxDocuments = createSelector(
  selectRequiredDocCatsForForm,
  selectUserDocumentList,
  selectTaxUserDocuments,
  selectUserDocumentCategoryList,
  (_: unknown, __: unknown, taxYear: string) => taxYear,
  ({ required, optional }, documents, taxUserDocuments, categories, taxYear) =>
    documents.filter((doc) => {
      const taxUserDoc = taxUserDocuments.find(
        (tud) => tud.userDocumentId === doc.id
      )
      const category = categories.find(
        (cat) => cat.id === doc.userDocumentCategoryId
      )
      return (
        taxUserDoc?.taxYear === taxYear &&
        (!category ||
          ![...required, ...optional].includes(
            category.internalName as UserDocumentCategoryIdentifier
          ))
      )
    })
)

const isDocumentWithCategoryUploaded = (
  requiredCat: string,
  documents: UserDocument[],
  documentCategories: UserDocumentCategory[]
) => {
  const category = getUserDocCategoryByInternalNameFromCategoryList(
    documentCategories,
    requiredCat
  )
  return (
    category &&
    documents.find((doc) => doc.userDocumentCategoryId === category.id)
  )
}

export const selectDocsUploadedOrSkippedAndTotalCount = createSelector(
  selectRequiredDocCatsForForm,
  selectUserDocumentList,
  selectUserDocumentCategoryList,
  selectDocumentProgressForForm,
  ({ required, optional }, documents, documentCategories, documentProgress) => {
    const skippedCategories =
      documentProgress?.responses?.[0]?.skippedDocumentCategories || []
    const requiredCount = required.length
    const optionalCount = optional.length

    return {
      requiredCount,
      optionalCount,
      completeCount:
        required.filter((requiredCat) =>
          isDocumentWithCategoryUploaded(
            requiredCat,
            documents,
            documentCategories
          )
        ).length +
        optional.filter(
          (optionalCat) =>
            isDocumentWithCategoryUploaded(
              optionalCat,
              documents,
              documentCategories
            ) || skippedCategories.includes(optionalCat)
        ).length,

      totalCount: requiredCount + optionalCount,
    }
  }
)

export const selectAllRequiredDocsUploaded = createSelector(
  selectDocsUploadedOrSkippedAndTotalCount,
  (counts) => {
    return (
      Boolean(counts.requiredCount) &&
      counts.completeCount === counts.totalCount
    )
  }
)

export const selectK1IsUploadedOrNotNeeded = createSelector(
  selectFilingFormsForYear,
  selectUserDocumentList,
  selectTaxUserDocuments,
  selectUserDocumentCategoryList,
  selectUserNeedsK1,
  (_: unknown, _year: string, formId: number | string) => formId,
  (
    forms,
    documents,
    taxUserDocuments,
    documentCategories,
    userNeedsK1,
    formId
  ) => {
    const form = forms.find((form) => form.id === formId)
    if (!form || !userNeedsK1) {
      return true
    }

    const k1Category = getUserDocCategoryByInternalNameFromCategoryList(
      documentCategories,
      UserDocumentCategoryIdentifier.scheduleK1
    )
    return documents.some((doc) => {
      const taxUserDoc = taxUserDocuments.find(
        (tud) => tud.userDocumentId === doc.id
      )
      return (
        k1Category &&
        doc.userDocumentCategoryId === k1Category.id &&
        taxUserDoc?.taxYear === form.year
      )
    })
  }
)

export const getProgressFromIdentifier = (
  identifier: SubStepIdentifiers,
  steps: AllEoyReviewStepState,
  progresses: UserEoyReviewProgressState
) => {
  const step = Object.values(steps).find(
    (step) => step.identifier === identifier
  )
  const progress = step
    ? Object.values(progresses).find(
        (progress) => progress.endOfYearReviewStepId === step.id
      )
    : undefined

  return {
    step,
    progress,
  }
}

export const getTaxChecklistSections = (
  isNewSubscription2023: boolean,
  filingForms: AnnualTaxFilingForm[],
  formId: string | number
) => {
  const isTwoFormFiler = filingForms.length > 1
  const formTypeName = filingForms.find((f) => f.id === formId)?.formType?.name

  const relevantSections: SubStepIdentifiers[][] = []
  if (formTypeName === TAX_ENTITY_TYPES.form_1120_s) {
    relevantSections.push(
      UPDATE_YOUR_BOOKS,
      REVIEW_INCOME_AND_EXPENSES_INITIAL,
      ...TAX_QUESTIONNAIRE_STEPS_1120S,
      REVIEW_INCOME_AND_EXPENSES_FINAL
    )
  } else if (isTwoFormFiler && formTypeName === TAX_ENTITY_TYPES.form_1040) {
    relevantSections.push(
      ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
      ...TAX_QUESTIONNAIRE_STEPS_1040
    )
    // only users with an old subscription have to pay
    if (!isNewSubscription2023) {
      relevantSections.push(COMPLETE_PAYMENT)
    }
  } else {
    relevantSections.push(
      ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
      UPDATE_YOUR_BOOKS.filter(
        (step) =>
          step !== SubStepIdentifiers.confirmOwnersInvestmentTransactions &&
          step !== SubStepIdentifiers.confirmOwnersDistributionTransactions
      ),
      REVIEW_INCOME_AND_EXPENSES_INITIAL,
      ...TAX_QUESTIONNAIRE_STEPS_1040,
      REVIEW_INCOME_AND_EXPENSES_FINAL
    )
  }

  return relevantSections
}

const selectTaxChecklistSections = createSelector(
  selectSubscriptionIncludesFreePersonalFiling,
  selectFilingFormsForYear,
  (_: unknown, _year: string, formId: number | string) => formId,
  getTaxChecklistSections
)

const getSubsectionsProgress = (
  progresses: UserEoyReviewProgressState,
  steps: AllEoyReviewStepState,
  relevantSections: SubStepIdentifiers[][]
) =>
  relevantSections.map((section) => {
    return Object.values(progresses).filter((progress) => {
      const progressIdentifier = Object.values(steps).find(
        (step) => step.id === progress.endOfYearReviewStepId
      )?.identifier
      return progressIdentifier && section.includes(progressIdentifier)
    })
  })

const getSectionsCompletedCount = (
  relevantSections: SubStepIdentifiers[][],
  progresses: UserEoyReviewProgressState,
  steps: AllEoyReviewStepState
) => {
  const progressesInSection = getSubsectionsProgress(
    progresses,
    steps,
    relevantSections
  )

  return progressesInSection.reduce(
    (acc, section) =>
      acc +
      (section.length > 0 && section.every((progress) => progress.completedAt)
        ? 1
        : 0),
    0
  )
}

const getSectionsCompleted = (
  relevantSections: SubStepIdentifiers[][],
  progresses: UserEoyReviewProgressState,
  steps: AllEoyReviewStepState
) => {
  return (
    getSectionsCompletedCount(relevantSections, progresses, steps) ===
    relevantSections.length
  )
}

export const selectTaxQuestionnaireSectionsCompleteAndTotalCount =
  createSelector(
    getUserEoyReviewProgress,
    getAllEoyReviewSteps,
    selectAnnualTaxFilingFormById,
    (progresses, steps, filingForm) => {
      const formTypeName = filingForm?.formType?.name
      if (formTypeName === TAX_ENTITY_TYPES.form_1120_s) {
        return {
          completeCount: getSectionsCompletedCount(
            TAX_QUESTIONNAIRE_STEPS_1120S,
            progresses,
            steps
          ),
          totalCount: TAX_QUESTIONNAIRE_STEPS_1120S.length,
        }
      } else {
        return {
          completeCount: getSectionsCompletedCount(
            TAX_QUESTIONNAIRE_STEPS_1040,
            progresses,
            steps
          ),
          totalCount: TAX_QUESTIONNAIRE_STEPS_1040.length,
        }
      }
    }
  )

export const selectApproveAndSubmitItemCompleteAndTotalCount = createSelector(
  selectSubscriptionIncludesFreePersonalFiling,
  getUserEoyReviewProgress,
  getAllEoyReviewSteps,
  selectFilingFormsForYear,
  (_: unknown, _year: string, formId: number | string) => formId,
  (isNewSubscription2023, progresses, steps, filingForms, formId) => {
    const formTypeName = filingForms.find((f) => f.id === formId)?.formType
      ?.name
    const twoFormFiler = filingForms.length > 1
    const twoFormPersonal =
      twoFormFiler && formTypeName === TAX_ENTITY_TYPES.form_1040
    const singleForm1040 =
      !twoFormFiler && formTypeName === TAX_ENTITY_TYPES.form_1040
    const businessForm = formTypeName === TAX_ENTITY_TYPES.form_1120_s
    const relevantSections = []
    if (businessForm || singleForm1040) {
      relevantSections.push(REVIEW_INCOME_AND_EXPENSES_FINAL)
    }
    // only users with an old subscription have to pay
    if (twoFormPersonal && !isNewSubscription2023) {
      relevantSections.push(COMPLETE_PAYMENT)
    }
    return {
      completeCount: getSectionsCompletedCount(
        relevantSections,
        progresses,
        steps
      ),
      totalCount: relevantSections.length + 1,
    }
  }
)

export const selectRequiresPaymentForPersonalFiling = createSelector(
  selectSubscriptionIncludesFreePersonalFiling,
  selectFilingFormsForCurrentTaxYear,
  (freePersonalFiling, filingForms) =>
    Boolean(filingForms.length > 1 && !freePersonalFiling)
)

export const selectAwaiting1120SCompletion = createSelector(
  selectFilingFormsForYear,
  selectFilingStatusFor1120s,
  (_: unknown, __: unknown, formId: number | string) => formId,
  (filingForms, filingStatus1120s, formId) => {
    const isTwoFormFiler = filingForms.length > 1
    const formTypeName = filingForms.find((f) => f.id === formId)?.formType
      ?.name

    return (
      isTwoFormFiler &&
      formTypeName === TAX_ENTITY_TYPES.form_1040 &&
      filingStatus1120s !== AnnualTaxFilingStepStatus.completeLocked
    )
  }
)

export const selectTaxChecklistReadyToSubmit = createSelector(
  getUserEoyReviewProgress,
  getAllEoyReviewSteps,
  selectTaxChecklistSections,
  selectAwaiting1120SCompletion,
  (progresses, steps, relevantSections, awaiting1120SCompletion) => {
    if (awaiting1120SCompletion) {
      return false
    }

    // TODO: Remove once payment flow completed: https://linear.app/heard/issue/AT-671/collect-stripe-payment
    const filteredSections =
      process.env.VITE_IS_PROD === 'false' &&
      process.env.VITE_SKIP_TAX_CHECKLIST_PAYMENT === 'true'
        ? relevantSections.filter(
            (section) => !section.includes(COMPLETE_PAYMENT[0])
          )
        : relevantSections

    return getSectionsCompleted(filteredSections, progresses, steps)
  }
)

export const selectAllSectionsExceptReviewAndApproveCompleted = createSelector(
  selectTaxChecklistSections,
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  selectUserBooksLockedForCurrentTaxYear,
  (sections, progresses, steps) => {
    // Pull out the payment and final review sections
    const filteredSections = sections.filter(
      (section) =>
        !section.includes(COMPLETE_PAYMENT[0]) &&
        !section.includes(REVIEW_INCOME_AND_EXPENSES_FINAL[0])
    )

    return getSectionsCompleted(filteredSections, progresses, steps)
  }
)

export const selectFinalReviewEnabled = createSelector(
  selectAllSectionsExceptReviewAndApproveCompleted,
  selectUserBooksLockedForCurrentTaxYear,
  (allSectionsCompleted, booksLocked) => allSectionsCompleted && booksLocked
)
