import { useEffect } from 'react'
import { Grid, Image } from 'semantic-ui-react'

import { TaxSeasonKickoffSurveyProps } from './YearEndKickoffSurvey'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { useSaveAnnualTaxFilingFormData } from '../helpers'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'

const HelpSubmission = ({ goToNextStep }: TaxSeasonKickoffSurveyProps) => {
  const currentAnnualTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentAnnualTaxYear
  )
  const saveTaxFiling = useSaveAnnualTaxFilingFormData(currentAnnualTaxYear)

  useEffect(() => {
    if (annualTaxFiling?.id) {
      saveTaxFiling({ taxSeasonSurveyNeedHelp: true })
    }
  }, [annualTaxFiling?.id, saveTaxFiling])

  return (
    <Grid>
      <GridRowColumn width={12}>
        <Card type="subsection" backgroundColor="stone40">
          <Grid>
            <GridRowColumn centerContent>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/plant-snake.svg"
                style={{ width: 300, height: 300 }}
              />
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)}>
              <Text as="h1" textAlign="center">
                Thanks for the details
              </Text>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)}>
              <Text as="h3" textAlign="center">
                We’ve forwarded your details to the Support Team
              </Text>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)}>
              <Text>
                While they are looking into your case, you can continue on with
                the rest of this form. Someone will follow up as soon as
                they&apos;ve reviewed your circumstance.
              </Text>
            </GridRowColumn>
            <GridRowColumn {...makeGridConfig([10, 14], true)} centerContent>
              <Button
                onClick={() =>
                  goToNextStep(
                    null,
                    TAX_SEASON_KICKOFF_SURVEY.contractorPayroll
                  )
                }
              >
                Continue
              </Button>
            </GridRowColumn>
          </Grid>
        </Card>
      </GridRowColumn>
    </Grid>
  )
}

export default HelpSubmission
