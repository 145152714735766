import styled from 'styled-components'
import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Text,
  Icon,
  IconButton,
} from '../../../../components/BaseComponents'
import { FC, ReactNode } from 'react'
import { Colors } from '../../../../styles/theme'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

export interface OnboardingItemRowProps {
  header: string
  headerExtra?: ReactNode
  description: ReactNode
  imageUrl: string
  timer: {
    unit: string
    value: number
  }
  skippable: boolean
  additionalDetails?: ReactNode
  started: boolean
  completed: boolean
  skipped: boolean
  onActionClick: (stepId?: number) => void
  onSkipClick?: () => void
  stepId?: number
}

const StyledGrid = styled(Grid)<{
  completedOrSkipped: boolean
  mobile: boolean
}>`
  background-color: ${(props) =>
    props.completedOrSkipped ? Colors.stone40 : Colors.natural};
  border-radius: 8px;
  padding: ${(props) => (props.mobile ? '16px' : '12px 24px')}!important;
  .item-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 0 0;
  }
  .close-icon-wrapper {
    display: flex;
    justify-content: ${(props) => (props.mobile ? 'space-between' : 'center')};
    align-items: center;
    gap: 20px;
  }
  .ui.image {
    width: ${(props) => (props.mobile ? '45px' : '80px')};
  }
  .header-wrapper {
    display: flex;
    gap: 16px;
  }
  &&&.ui.grid {
    margin: 0;
  }
`

const CloseIcon = ({
  onSkipClick,
  skippable,
  skipped,
  completed,
}: Pick<
  OnboardingItemRowProps,
  'onSkipClick' | 'skippable' | 'completed' | 'skipped'
>) => {
  if (!skippable || completed || skipped) {
    return null
  }
  return (
    <div>
      <IconButton width={16} icon={regular('close')} onClick={onSkipClick} />
    </div>
  )
}
const ArrowRightIcon = () => (
  <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
)
const IncompleteItemButtonContent = ({ started }: { started: boolean }) => (
  <>
    {started ? 'Continue' : 'Start'}
    <ArrowRightIcon />
  </>
)

const OnboardingItemRow: FC<OnboardingItemRowProps> = (
  props: OnboardingItemRowProps
) => {
  const {
    header,
    description,
    imageUrl,
    additionalDetails,
    timer,
    onActionClick,
    completed,
    skipped,
    started,
    stepId,
    headerExtra,
  } = props
  const completedOrSkipped = completed || skipped
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const onActionClickWithStepId = () => onActionClick(stepId)
  return (
    <StyledGrid
      stackable
      completedOrSkipped={completedOrSkipped}
      mobile={isMobile}
    >
      <Grid.Row verticalAlign="middle">
        <Grid.Column {...makeGridConfig([2, 2])}>
          <div className="close-icon-wrapper">
            <Image src={imageUrl} size="tiny" />
            {isMobile && <CloseIcon {...props} />}
          </div>
        </Grid.Column>
        <Grid.Column {...makeGridConfig([11, 10, 10])}>
          <div className="item-content">
            <div className="header-wrapper">
              <Text as="h3">{header}</Text>
              {headerExtra}
            </div>
            <Text as="bodySm">{description}</Text>
            <Text as="bodyXs" color="oak">
              <Icon icon={regular('timer')} style={{ marginRight: 4 }} />
              {timer.value} {timer.unit}
            </Text>
            {additionalDetails}
          </div>
        </Grid.Column>
        <Grid.Column {...makeGridConfig([3, 4, 4])}>
          <div className="close-icon-wrapper">
            {completedOrSkipped ? (
              <Button
                onClick={onActionClickWithStepId}
                variant="secondary"
                disabled={completed}
              >
                {completed ? (
                  <>
                    <Icon icon={regular('check')} style={{ marginRight: 8 }} />
                    Done
                  </>
                ) : (
                  <IncompleteItemButtonContent started={started} />
                )}
              </Button>
            ) : (
              <Button onClick={onActionClickWithStepId}>
                <IncompleteItemButtonContent started={started} />
              </Button>
            )}
            {!isMobile && <CloseIcon {...props} />}
          </div>
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  )
}

export default OnboardingItemRow
