import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  AnnualTaxFiling,
  CREATE_ANNUAL_TAX_FILING_KEY,
  FETCH_TAX_FILINGS_KEY,
  SUBMIT_ANNUAL_TAX_FILING_KEY,
  UPDATE_ANNUAL_TAX_FILING_KEY,
  fetchAnnualTaxFilingsIfNeeded,
} from '../annualTaxFilings.slice'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../components/FormFlow/formFlow'
import { Grid, Image } from 'semantic-ui-react'
import {
  Alert,
  Button,
  GridRowColumn,
  Icon,
} from '../../../../components/BaseComponents'
import PageHeader from '../../../../components/shared/PageHeader'
import { useSaveAnnualTaxFilingFormData } from '../helpers'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getIsFetchingOrNotStarted,
  selectFirstErrorMessageForKeys,
} from '../../../../reducers/fetch'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import TaxEntity from './TaxEntity'
import ContractorPayroll from './ContractorPayroll'
import TaxNeeds from './TSKNeeds/TaxNeeds'
import TSKReview from './TSKReview'
import ScorpConfirmation from './ScorpConfirmation'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import useTipsSideBar, {
  TAX_SEASON_KICKOFF_SURVEY,
  tskSideBarConfig,
} from './TskTipsSideBar'
import { isNil } from 'lodash'
import SolePropTaxEntityHelp from './SolePropTaxEntityHelp'
import HelpSubmission from './HelpSubmission'
import ScorpTaxEntityHelp from './ScorpTaxEntityHelp'
import CCorpDisqualified from './CCorpDisqualified'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { getFinancialProfile } from '../../../../selectors/user.selectors'

export interface TaxSeasonKickoffSurveyProps {
  goToNextStep: (
    data: Partial<AnnualTaxFiling> | null,
    newScreen: TAX_SEASON_KICKOFF_SURVEY | null
  ) => void
  goBack: (newScreen: TAX_SEASON_KICKOFF_SURVEY | null) => void
}

export const kickoffTicketTags = ['tax-season-kickoff', 'confirm-tax-entity']
export const kickoffTicketTopic = 'tax__annual__tsk_entity_verification'
export const kickoffTicketTasks = {
  revoke: 'task_taxes__annual__tsk_discuss_sc_revoke',
  dissolve: 'task_taxes__annual__tsk_entity_dissolution',
  requestCP261: 'task_taxes__annual__tsk_request_cp261',
  verifyCP261: 'task_taxes__annual__tsk_verify_cp261',
  election: 'task_taxes__annual__tsk_explore_sc_election',
  determine: 'task_taxes__annual__tsk_determine_tax_entity',
  offboard: 'task_taxes__annual__tsk_offboard_ineligible_client',
}

const YearEndKickoffSurvey = () => {
  const dispatch = useAppDispatch()

  const currentAnnualTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const fp = useReselector(getFinancialProfile)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentAnnualTaxYear
  )
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_TAX_FILINGS_KEY
  )
  const error = useReselector(selectFirstErrorMessageForKeys, [
    CREATE_ANNUAL_TAX_FILING_KEY,
    UPDATE_ANNUAL_TAX_FILING_KEY,
    SUBMIT_ANNUAL_TAX_FILING_KEY,
  ])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchUserDocuments())
  }, [dispatch])

  const saveTaxFiling = useSaveAnnualTaxFilingFormData(currentAnnualTaxYear)

  const { setScreen, currentScreen } = useSetScreen({
    backLink: '/taxes/annual',
    reviewScreen: TAX_SEASON_KICKOFF_SURVEY.review,
  })

  useEffect(() => {
    if (annualTaxFiling && !annualTaxFiling.taxSeasonSurveyStartedAt) {
      saveTaxFiling({ taxSeasonSurveyStartedAt: new Date().toISOString() })
    }
  }, [annualTaxFiling, saveTaxFiling])

  useEffect(() => {
    if (annualTaxFiling && !annualTaxFiling.taxSeasonSurveyInitialTaxEntity) {
      saveTaxFiling({ taxSeasonSurveyInitialTaxEntity: fp?.taxEntityType })
    }
  }, [annualTaxFiling, fp, saveTaxFiling])

  useEffect(() => {
    if (!currentScreen) {
      setScreen(TAX_SEASON_KICKOFF_SURVEY.taxEntity, true)
    }
  }, [currentScreen, setScreen, currentAnnualTaxYear])

  const saveFormData = useCallback(
    async (
      data: Partial<AnnualTaxFiling> | null,
      newScreen: TAX_SEASON_KICKOFF_SURVEY | null
    ) => {
      if (data) {
        const res = await saveTaxFiling({
          surveyLastUpdatedAt: new Date().toISOString(),
          ...data,
        })

        if (!res) {
          return false
        }
      }

      setScreen(newScreen)

      return true
    },
    [saveTaxFiling, setScreen]
  )

  const screenConfig = useMemo(() => {
    const props = {
      goToNextStep: saveFormData,
      goBack: setScreen,
    }

    return [
      {
        component: () => <TaxEntity {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.taxEntity,
      },
      {
        component: () => <ScorpConfirmation {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.scorpConfirmation,
      },
      {
        component: () => <SolePropTaxEntityHelp {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.solePropTaxEntityHelp,
      },
      {
        component: () => <ScorpTaxEntityHelp {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.scorpTaxEntityHelp,
      },
      {
        component: () => <CCorpDisqualified {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.cCorpDisqualified,
      },
      {
        component: () => <HelpSubmission {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.helpSubmission,
      },
      {
        component: () => <ContractorPayroll {...props} />,
        step: 1,
        screenName: TAX_SEASON_KICKOFF_SURVEY.contractorPayroll,
      },
      {
        component: () => <TaxNeeds {...props} />,
        step: 2,
        screenName: TAX_SEASON_KICKOFF_SURVEY.taxNeeds,
      },
      {
        component: () => <TSKReview />,
        step: 3,
        screenName: TAX_SEASON_KICKOFF_SURVEY.review,
      },
    ]
  }, [saveFormData, setScreen])

  const { progressBar, content } = useFormFlow({
    steps: ['Tax entity', 'Contractor payroll', 'Your tax needs', 'Review'],
    screens: screenConfig,
  })

  const showSideBar =
    currentScreen &&
    !isNil(
      tskSideBarConfig[currentScreen as keyof typeof TAX_SEASON_KICKOFF_SURVEY]
    )

  const { flyoutOpen, openTipsSidebar, closeTipsSidebar } = useTipsSideBar({
    currentScreenSideBarConfig:
      tskSideBarConfig[currentScreen as keyof typeof TAX_SEASON_KICKOFF_SURVEY],
  })

  return (
    <Grid className="inner-content-wrapper">
      <Grid.Column width={2}>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/end_of_year_cal.svg"
          style={{ width: '100%' }}
          alt="End of Year calendar"
        />
      </Grid.Column>
      <Grid.Column width={14}>
        <Grid>
          <Grid.Column width={12}>
            <PageHeader header="Tax Season Kickoff" />
          </Grid.Column>
          <Grid.Column width={4}>
            {showSideBar && (
              <Button
                size="small"
                variant="secondary"
                onClick={flyoutOpen ? closeTipsSidebar : openTipsSidebar}
              >
                <Icon icon={regular('hand')} style={{ marginRight: 5 }} />
                Need Help?
              </Button>
            )}
          </Grid.Column>
        </Grid>
        <GridRowColumn>{progressBar}</GridRowColumn>
        {error && (
          <GridRowColumn>
            <Alert type="error">{error}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{!loading && content}</GridRowColumn>
      </Grid.Column>
    </Grid>
  )
}

export default YearEndKickoffSurvey
