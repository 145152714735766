import { useEffect, useState } from 'react'
import { Grid, List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { uniq } from 'lodash'
import './styles.scss'

import {
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import AddTransactionCard from '../../../../components/Transactions/AddTransactionCard'
import { useScrollRef } from '../../../../utils/sharedHooks'
import { TransactionCategoryIdentifier } from '../../../../reducers/admin/allTransactions.slice'
import { StepHeaders } from '../../../../components/StepHeader'

const filterCategories = [
  TransactionCategoryIdentifier.insurance_health,
  TransactionCategoryIdentifier.psychotherapy_fees,
  TransactionCategoryIdentifier.continuing_education,
  TransactionCategoryIdentifier.meals_entertainment,
  TransactionCategoryIdentifier.travel_expenses,
  TransactionCategoryIdentifier.rent_and_lease,
  TransactionCategoryIdentifier.internet,
  TransactionCategoryIdentifier.communication_expenses,
  TransactionCategoryIdentifier.auto_related_expenses,
  TransactionCategoryIdentifier.equipment,
  TransactionCategoryIdentifier.furniture,
  TransactionCategoryIdentifier.computer_hardware,
]

export const BusinessExpenseInPersonal = () => {
  const [showAddTransactionModal, setShowAddTransactionModal] = useState(false)
  const [addedTransactionIds, setAddedTransactionIds] = useState<number[]>([])
  const [editingTransactionId, setEditingTransactionId] = useState<number>()
  const { scrollRef, scrollToRef } = useScrollRef()

  const [hasBusinessExpenseToAdd, setHasBusinessExpenseToAdd] = useState(false)

  useEffect(() => {
    if (showAddTransactionModal) {
      scrollToRef()
    }
  }, [scrollToRef, showAddTransactionModal])

  useEffect(() => {
    if (!hasBusinessExpenseToAdd) {
      setShowAddTransactionModal(false)
    }
  }, [hasBusinessExpenseToAdd])

  useEffect(() => {
    if (!showAddTransactionModal) {
      setEditingTransactionId(undefined)
    }
  }, [showAddTransactionModal])

  const addTransactionToList = (transactionId: number) => {
    setAddedTransactionIds(uniq([...addedTransactionIds, transactionId]))
  }

  const editTransaction = (transactionId: number) => {
    setShowAddTransactionModal(true)
    setEditingTransactionId(transactionId)
  }

  const deleteTransaction = (transactionId: number) => {
    setAddedTransactionIds(
      addedTransactionIds.filter((tId) => tId !== transactionId)
    )
  }

  return (
    <Grid>
      <GridRowColumn centerContent>
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/credit_card.svg"
          imageAlt="creditcard"
          imageWidth={180}
          imageHeight={180}
          title="Do you have business expenses from these categories in your personal accounts?"
          customColor="yellow"
        >
          <Grid>
            <Grid.Row className="list-column">
              <Grid.Column width={8}>
                <List bulleted className="flex-column">
                  <List.Item>Healthcare</List.Item>
                  <List.Item>Therapy</List.Item>
                  <List.Item>Continuing Education</List.Item>
                  <List.Item className="align-left">
                    Meals, Lodging and Airfare for Business Travel
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={8}>
                <List bulleted className="flex-column">
                  <List.Item>Office rent</List.Item>
                  <List.Item>Phone & Internet</List.Item>
                  <List.Item>Mileage</List.Item>
                  <List.Item className="align-left">
                    Equipment or Furniture over $2,500
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn className="align-left">
              <Text>
                We ask this to ensure that we account for any expenses that you
                may have accidentally made using a personal account.
              </Text>
            </GridRowColumn>
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      <Grid.Row className="buttons-grid">
        <Grid.Column width={6} />
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessExpenseToAdd(true)}
            variant="selector"
            fullWidth
            active={hasBusinessExpenseToAdd === true}
          >
            Yes
          </Button>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            onClick={() => setHasBusinessExpenseToAdd(false)}
            variant="selector"
            fullWidth
            active={hasBusinessExpenseToAdd === false}
          >
            No
          </Button>
        </Grid.Column>
      </Grid.Row>
      {hasBusinessExpenseToAdd && (
        <>
          <GridRowColumn>
            <Text textAlign="center">
              Please add transactions that haven’t already been recorded in
              Heard.
            </Text>
          </GridRowColumn>
          <GridRowColumn spacer={6} width={4}>
            <Button
              variant="secondary"
              fullWidth
              onClick={() => setShowAddTransactionModal(true)}
            >
              <Icon icon={regular('plus')} style={{ marginRight: 10 }} />
              Add Transaction
            </Button>
          </GridRowColumn>
          <Grid.Row />
          {showAddTransactionModal && (
            <>
              <GridRowColumn spacer={2} width={12}>
                <span ref={scrollRef} />
                <AddTransactionCard
                  onClose={() => setShowAddTransactionModal(false)}
                  onSave={addTransactionToList}
                  transactionId={editingTransactionId}
                  categoryType="Expenses"
                  categoryFilter={filterCategories}
                />
              </GridRowColumn>
              <Grid.Row />
            </>
          )}

          {addedTransactionIds.length > 0 && <TransactionHeader />}
          <GridRowColumn short className="transaction-grid">
            {addedTransactionIds.map((id) => (
              <TransactionCard
                key={id}
                transactionId={id}
                variant="edit"
                editAction={editTransaction}
                deleteAction={deleteTransaction}
              />
            ))}
          </GridRowColumn>
        </>
      )}
    </Grid>
  )
}

export default BusinessExpenseInPersonal
