import { useState, useEffect } from 'react'

import { actionButton as copy } from '../../../features/UserDocuments/copyConstants'
import {
  destroySingleUserDocument,
  UserDocument,
} from '../../../features/UserDocuments/userDocuments.slice'

// UI
import { Button, Modal } from '../../BaseComponents'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const DeleteStatement = ({
  document,
  close,
  updateDocumentStatements,
  open,
}: {
  document: UserDocument | undefined
  close: () => void
  updateDocumentStatements: () => void
  open: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const [customText, setCustomText] = useState<string>('')
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (document) {
      setCustomText(copy.dynamic.confirmContent(document))
    }
  }, [document, open])

  const onConfirm = async () => {
    if (loading) {
      return
    }

    if (!document) {
      return
    }

    setLoading(true)

    await destroySingleUserDocument(document.id)(dispatch)
    updateDocumentStatements()
    setLoading(false)
    close()
  }

  return (
    <Modal size="small" dimmer="inverted" open={open} onClose={close}>
      <Modal.Content>{customText}</Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onConfirm}>
          {loading
            ? copy.static.confirmButtonLoadingText
            : copy.static.confirmButtonNotLoadingText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteStatement
