import { createSelector } from 'reselect'
import { ReduxState } from '../../utils/typeHelpers'
import { YearEndModuleStatusOptions } from './yearEndModuleStatus.slice'

export const getYearEndModuleStatusesByType = (state: ReduxState) =>
  state.yearEndModuleStatuses.byModuleType
export const getYearEndModuleStatuses = (state: ReduxState) =>
  state.yearEndModuleStatuses.allModules
export const getMostUrgentYearEndModuleType = (state: ReduxState) =>
  state.yearEndModuleStatuses.mostUrgentModule

export const getCompleteYearEndModuleStatuses = createSelector(
  getYearEndModuleStatuses,
  (modules) =>
    modules.filter(
      (module) => module.status === YearEndModuleStatusOptions.complete
    )
)
export const getIncompleteYearEndModuleStatuses = createSelector(
  getYearEndModuleStatuses,
  (modules) =>
    modules.filter(
      (module) => module.status !== YearEndModuleStatusOptions.complete
    )
)

export const getOrderedCompletedItems = createSelector(
  getCompleteYearEndModuleStatuses,
  (completeModules) => completeModules.sort((a, b) => a.order - b.order)
)
export const getOrderedIncompleteItems = createSelector(
  getIncompleteYearEndModuleStatuses,
  (incompleteModules) => incompleteModules.sort((a, b) => a.order - b.order)
)
export const getMostUrgentModule = createSelector(
  getYearEndModuleStatusesByType,
  getMostUrgentYearEndModuleType,
  (byModuleType, mostUrgentModule) =>
    mostUrgentModule ? byModuleType[mostUrgentModule] : null
)

export const haveAnyModulesBeenStarted = createSelector(
  getYearEndModuleStatuses,
  (byModuleType) =>
    Object.values(byModuleType).some(
      (module) => module.startedAt || module.completedAt
    )
)
