import { useState } from 'react'
import { Moment } from 'moment'
import { Card, Text, IconButton, Button } from '../../../BaseComponents'
import { Icon } from 'semantic-ui-react'
import FileUploadModal from '../../../FileUpload/FileUploadModal'
import { DeleteStatement } from '../DeleteStatementModal'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import styles from './styles.module.scss'
import { markUserActionItemCompleteIfExists } from '../../../../features/Dashboard/UserActionItems/service'
import { UserActionItemActionItemIdentifiers } from '../../../../features/Dashboard/UserActionItems/userActionItems.slice'
import { UserDocument } from '../../../../features/UserDocuments/userDocuments.slice'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

export const MissingStatementCard = ({
  month,
  yearMonth,
  setStatementMap,
}: {
  month: string
  yearMonth: string
  setStatementMap: (value: string, statements: UserDocument[]) => void
}) => {
  const [uploadedStatements, setUploadedStatements] = useState<UserDocument[]>(
    []
  )
  const [openUpload, setOpenUpload] = useState(false)
  const [deleteDocumentModalOpen, setDeleteDocumentModalOpen] = useState(false)
  const [documentToDelete, setDocumentToDelete] = useState<UserDocument>()

  const setUploadedFile = async ({
    statementMonth,
    doc,
  }: {
    statementMonth?: Moment
    fileName?: string
    id?: number
    doc?: UserDocument
  }) => {
    if (statementMonth) {
      const statements = uploadedStatements
      await markUserActionItemCompleteIfExists(
        UserActionItemActionItemIdentifiers.uploadBankStatements({
          month: (statementMonth.month() + 1).toString(),
          year: statementMonth.year().toString(),
        })
      )
      if (doc?.id) {
        statements.push(doc)
        setUploadedStatements(statements)
        setStatementMap(yearMonth, statements)
      }
    }
  }

  return (
    <Card
      key={month}
      backgroundColor={
        uploadedStatements.length >= 1 ? 'lightGreen' : 'stone40'
      }
      className={styles.bodyCard}
    >
      <div className={styles.accountCard}>
        <div className={styles.topRow}>
          <div className={styles.accountName}>{month}</div>
          <div className={styles.isChecked}>
            <Button
              icon
              labelPosition="left"
              onClick={() => setOpenUpload(true)}
              style={{ float: 'right', width: '105px', fontSize: '14px' }}
              variant="secondary"
            >
              Upload
              <Icon
                name="upload"
                style={{
                  background: 'none',
                  border: 'none',
                  boxShadow: 'none',
                  fontSize: '16px',
                }}
              />
            </Button>
          </div>
        </div>
        <div className={styles.fileListCard}>
          {Boolean(uploadedStatements.length) && (
            <>
              {uploadedStatements.map((file) => (
                <Text key={file.id}>
                  {file.fileDescription}
                  <IconButton
                    onClick={() => {
                      setDeleteDocumentModalOpen(true)
                      setDocumentToDelete(file)
                    }}
                    icon={regular('trash-can')}
                    style={{ marginLeft: 6 }}
                  />
                </Text>
              ))}
            </>
          )}
        </div>
      </div>
      <FileUploadModal
        documentType={UploadDocumentType.STATEMENT}
        open={openUpload}
        close={() => setOpenUpload(false)}
        userFacing
        monthYear={yearMonth || undefined}
        setUploadedFile={setUploadedFile}
      />
      <DeleteStatement
        document={documentToDelete}
        open={deleteDocumentModalOpen}
        close={() => setDeleteDocumentModalOpen(false)}
        updateDocumentStatements={() =>
          setUploadedStatements(
            uploadedStatements.filter((doc) => doc.id !== documentToDelete?.id)
          )
        }
      />
    </Card>
  )
}
