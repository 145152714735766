import {
  Accordion,
  Card,
  Dropdown,
  Text,
} from '../../../../components/BaseComponents'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import { StepHeaders } from '../../../../components/StepHeader'
import './styles.scss'
import { Divider } from 'semantic-ui-react'

const dropdownOptions = [
  {
    id: 1,
    text: 'Gusto',
    value: 'Gusto' as const,
  },
  {
    id: 2,
    text: 'ADP',
    value: 'ADP' as const,
  },
  {
    id: 3,
    text: 'Quickbooks',
    value: 'Quickbooks' as const,
  },
  {
    id: 4,
    text: 'Other',
    value: 'Other' as const,
  },
]

type PayrollDocumentsStepContext = {
  payrollProviders?: string[]
}

export interface IPayrollDocumentsProps {
  stepContext: PayrollDocumentsStepContext
  setStepContext: (context: PayrollDocumentsStepContext) => void
}

export const PayrollDocuments = ({
  stepContext,
  setStepContext,
}: IPayrollDocumentsProps) => {
  const handleNotRelevant = (provider: string) => {
    console.log('Not Relevant:', provider) // skipcq: JS-0002 pending hea-5102
  }

  return (
    <div className="eoybk__payroll__container">
      <StepHeaders
        customColor="yellow"
        description="If you pay employees through a payroll provider, you’ll need to upload a Form W-3."
        imageSrc="https://heard-images.s3.amazonaws.com/assets/checkbooks.svg"
        imageAlt="stacked checkbooks"
        imageWidth={180}
        imageHeight={180}
        kickerText="YEAR-END DOCUMENTS"
        title="Payroll"
      />

      <div>
        <Text>Which payroll provider(s) did you use in 2024?</Text>
        <Text as="bodyXs">
          If you used multiple, select all providers you used.
        </Text>

        <Dropdown
          placeholder="Select payroll provider(s)"
          options={dropdownOptions}
          multiple
          clearable
          fullWidth
          value={stepContext?.payrollProviders ?? []}
          onChange={(val: string[]) =>
            setStepContext({
              ...stepContext,
              payrollProviders: val,
            })
          }
        />

        {stepContext?.payrollProviders &&
          stepContext.payrollProviders.length > 0 && (
            <Card backgroundColor="stone40">
              <Text as="eyebrow" color="darkGray">
                DOCUMENTS YOU NEED
              </Text>

              <Text>Based on your transactions, you’ll need to upload:</Text>

              {stepContext.payrollProviders.map((provider) => (
                <ul key={provider} className="eoybk__payroll__provider-uploads">
                  <li>
                    <strong>{provider}</strong>
                  </li>
                  <ul className="eoybk__payroll__provider-forms">
                    <li>Form W-3</li>
                    <li>Payroll Journal</li>
                  </ul>
                </ul>
              ))}

              <Text>
                Please contact your provider’s support if you need assistance
                locating your documents.
              </Text>
            </Card>
          )}
      </div>

      <Accordion
        className="eoybk__payroll__accordion-text"
        title="What if my payroll provider isn’t listed?"
        content={
          <Text>
            We need your Form W-3 for all payroll providers you used in 2024.
            Please download it from your payroll provider’s website and upload
            it under “Other documents” below.
          </Text>
        }
        variant="text"
      />

      <Divider className="eoybk__payroll__divider" />

      <Text as="h2">Upload payroll documents</Text>

      <Text>
        If you don’t have a document on hand, you may skip for now. You’ll be
        able to view instructions and upload everything at the end.
      </Text>

      <div className="eoybk__payroll_document-cards">
        {stepContext?.payrollProviders?.map((provider) => (
          <>
            <DocumentUploadCard
              name={`${provider} - Form W-3`}
              description="This summarizes your business’ total salary payments and withholding amounts."
              setChecked={() => {
                handleNotRelevant(provider)
              }}
            />

            <DocumentUploadCard
              name={`${provider} - Payroll Journal`}
              description="Includes a summary of gross wages, employee taxes, employer taxes, net pay."
              setChecked={() => {
                handleNotRelevant(provider)
              }}
            />
          </>
        ))}

        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you’d like to share with the bookkeeping team here."
          status="disabled"
        />
      </div>
    </div>
  )
}
