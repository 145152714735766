import { Grid, Icon, Image } from 'semantic-ui-react'
import {
  Alert,
  Button,
  GridRowColumn,
  Pill,
  Text,
} from '../../../components/BaseComponents'
import Card from '../../../components/BaseComponents/Card'
import {
  createUpriseAccount,
  FinancialAdvisoryFeatureFlagValues,
  FinancialAdvisoryProfile,
  FinancialAdvisoryProfilesStatus,
  putFinancialAdvisoryProfile,
} from '../financialAdvisory.slice'
import { useCallback, useEffect, useState } from 'react'
import { track } from '@amplitude/analytics-browser'
import { useAnalyticsView } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  StripeLineItem,
  fetchCheckoutSession,
} from '../../../actions/settings/billingActions'
import { fetchAdvisoryPrices } from '../../../constants/pricingConstants'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { UpriseIntroCard } from './IntroCallCTAPanel'
import { getAdvisoryCallPricing } from '../financialAdvisory.selectors'
import { useStringFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'

const PostIntroCallPanel = ({
  faProfile,
}: {
  faProfile: FinancialAdvisoryProfile
}) => {
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const callPricing = useReselector(getAdvisoryCallPricing)
  const advisoryPrices = fetchAdvisoryPrices()
  const annualPlan = advisoryPrices.annual.priceId
  const monthlyPlan = advisoryPrices.monthly.priceId
  const user = useReselector(getCurrentUser)

  const advisoryPilotEnabled =
    useStringFlagValue(
      FEATURE_FLAG_KEYS.financialAdvisoryPilot,
      FinancialAdvisoryFeatureFlagValues.noPilot
    ) === FinancialAdvisoryFeatureFlagValues.pilotEnabled
  /**
   * Navs to Payment
   */
  const navToPayment = useCallback(
    async (selectedPriceId: string) => {
      if (!user?.id || !selectedPriceId) {
        return
      }
      track('click nav to advisory purchase — advisory page ', {
        fa_profile_id: faProfile.id,
        user_id: faProfile.userId,
      })

      const lineItems: StripeLineItem[] = [
        {
          priceId: selectedPriceId,
          quantity: 1,
        },
      ]
      const metadata: Record<string, string> = {}

      let subscriptionCheckoutUrl: string | null = null
      const res = await fetchCheckoutSession({
        userId: user?.id,
        lineItems,
        success_url: {
          url: '/advisory/overview?paymentComplete=true',
        },
        // Navigate back to current page with query params
        cancel_url: {
          url: `${location.pathname}${location.search}`,
        },
        freeTrialEnabled: undefined,
        freeTrialSearchParamValue: null,
        metadata,
      })(dispatch)
      if (res) {
        subscriptionCheckoutUrl = res.url
      }

      if (subscriptionCheckoutUrl !== null) {
        window.location.href = subscriptionCheckoutUrl
      }
    },
    [user?.id, faProfile.id, faProfile.userId, dispatch]
  )

  // This officially enables the user to start onboarding to Uprise
  const submitUpriseOnboarding = async () => {
    setLoading(true)
    setError('')
    try {
      track('started uprise onboarding — post cancellation or disinterest', {
        fa_profile_id: faProfile.id,
        user_id: faProfile.userId,
      })
      const response = await createUpriseAccount()(dispatch)
      await putFinancialAdvisoryProfile(faProfile.id, {
        status: FinancialAdvisoryProfilesStatus.onboarding,
      })(dispatch)
      if (!response) {
        setError('Something went wrong! Please refresh and try again.')
      }
    } catch {
      setError('An unexpected error occurred. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  // not_interested
  const declineUpriseOnboarding = async () => {
    track('declined uprise onboarding', {
      fa_profile_id: faProfile.id,
      user_id: faProfile.userId,
    })
    const updated = await putFinancialAdvisoryProfile(faProfile.id, {
      status: FinancialAdvisoryProfilesStatus.not_interested,
    })(dispatch)
    if (!updated) {
      setError('Something went wrong!')
    }
  }
  useEffect(() => {
    pageView('advisory: post intro call panel')
  }, [pageView])

  const renderButtonGrouping = () => {
    if (advisoryPilotEnabled) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="primary"
            loading={loading}
            onClick={() => submitUpriseOnboarding()}
          >
            Start Onboarding
          </Button>
          <Button
            style={{ marginLeft: '20px' }}
            variant="secondary"
            loading={loading}
            onClick={() => declineUpriseOnboarding()}
          >
            {' '}
            Decline Advisory
          </Button>
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Card type="subsection" style={{ margin: '10px', minWidth: '300px' }}>
            <Text as="h3">Annual Plan</Text>
            <br />
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <Text as="bodyMd">
                <span style={{ textDecoration: 'line-through' }}>$29 /mo</span>
              </Text>
              <Text as="bodyLg" color="green">
                {' '}
                $25/mo (save 15%)
              </Text>
            </div>
            <Text as="bodySm" color="darkGray">
              Billed annually
            </Text>
            <br />
            <Button variant="primary" onClick={() => navToPayment(annualPlan)}>
              {' '}
              Select Plan
            </Button>
          </Card>
          <Card type="subsection" style={{ margin: '10px', minWidth: '300px' }}>
            <Text as="h3">Monthly Plan</Text>
            <br />
            <Text as="bodyLg" color="green">
              {' '}
              $29 /mo
            </Text>
            <Text as="bodySm" color="darkGray">
              Billed monthly
            </Text>
            <br />
            <Button
              variant="secondary"
              onClick={() => navToPayment(monthlyPlan)}
            >
              {' '}
              Select Plan
            </Button>
          </Card>
        </div>
      )
    }
  }

  return (
    <>
      {error && (
        <Alert type="error">
          It looks like we encountered an error while trying to onboard you!
          Please try again.
        </Alert>
      )}
      <Card type="subsection" backgroundColor="natural">
        <Grid stackable doubling centered>
          <GridRowColumn />
          <Grid.Row>
            <Grid.Column width={10}>
              <Grid style={{ margin: '0 5px' }}>
                <GridRowColumn>
                  <Pill>One More Step</Pill>
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="h1">
                    Introducing Advisory: Small business and personal financial
                    planning for therapists like you
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="bodyMd">
                    We hoped you enjoyed your free introductory call with a
                    Certified Financial Planner! To unlock expert financial
                    guidance for your private practice, add the Advisory add on
                    and receive your 100% personalized, comprehensive financial
                    plan. With the help of your financial advisor, Heard is here
                    to support you with your financial goals.
                  </Text>
                </GridRowColumn>
                <GridRowColumn>{renderButtonGrouping()}</GridRowColumn>
              </Grid>
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle">
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisory_screen.svg"
                alt="heard financial advisory"
              />
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn />
        </Grid>
      </Card>
      <br />
      <Grid stackable doubling centered>
        <GridRowColumn />
        <Grid.Row>
          <Grid.Column width={6} verticalAlign="middle" textAlign="center">
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/advisory_goals.svg"
              alt="heard financial advisory"
            />
          </Grid.Column>
          <Grid.Column width={10} verticalAlign="middle" textAlign="left">
            <Grid style={{ margin: '0 5px' }}>
              <GridRowColumn>
                <Text as="h2">Included in your Advisory Add On:</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  <Icon name="check" size="small" />A 100% comprehensive
                  financial plan, build for both your personal and business
                  financial goals
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Unlimited revisions of your financial plan, to accommodate
                  changes in goals
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Expert guidance from your Advisor, who is a Certified
                  Financial Planner (CFP®)
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Unlimited messaging with your advisor to keep your plan
                  updated throughout the year
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  Accountability in ensuring you are progressing towards your
                  financial goals
                </Text>
                <br />
                <Text>
                  <Icon name="check" size="small" />
                  For additional support, one on one 45 minute follow up video
                  calls at ${callPricing}
                </Text>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn />
      </Grid>
      <br />
      <UpriseIntroCard />
      <br />
      {!advisoryPilotEnabled && (
        <Card type="subsection" backgroundColor="moss">
          <Grid stackable doubling centered>
            <GridRowColumn />
            <Grid.Row>
              <Grid.Column width={6} textAlign="center">
                <Text as="h2">Get Started with Financial Advisory</Text>
                <Text as="bodyMd">$29 per month, or $300 per year</Text>
              </Grid.Column>
              <Grid.Column width={5} verticalAlign="middle">
                <Button
                  variant="primary"
                  fullWidth
                  onClick={() => navToPayment(annualPlan)}
                >
                  {' '}
                  Get Started with Annual Plan(Save 15%)
                </Button>
              </Grid.Column>
              <Grid.Column width={5} verticalAlign="middle">
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => navToPayment(monthlyPlan)}
                >
                  {' '}
                  Get Started with Monthly Plan
                </Button>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn />
          </Grid>
        </Card>
      )}
      <br />
      <br />
      <br />
    </>
  )
}

export default PostIntroCallPanel
