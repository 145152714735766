import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectTaxListQuestionResponsesByQuestionIds } from '../../taxChecklist.selectors'
import { INCOME_LIABILITIES_SCREENS, IncomeAndLiabilitiesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import ReviewSubSection from '../../Shared/ReviewSubSection'
import { IncomeAndLiabilitiesQuestionIds } from './IncomeAndLiabilitiesPanel'
import { rentalPropertyQuestionIds } from './RentalPropertyPanel'
import { investmentsQuestionIds } from './InvestmentsPanel'
import { retirementContributedQuestionIds } from './RetirementContributedPanel'
import { lessCommonScenariosQuestionIds } from './LessCommonScenariosIntroPanel'
import { lessCommonScenarioFollowUpQuestionIds } from './LessCommonScenariosPanel'
import { TaxListQuestionId } from '../../service'
import { useNavigateWithLocation } from '../../../../../../utils/routeHelpers'
import { selectTqIsReadOnly } from '../../../annualTaxFilings.selector'

const IncomeAndLiabilitiesReviewPanel = ({
  goBack,
}: IncomeAndLiabilitiesStepProps) => {
  const navigate = useNavigateWithLocation()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqIsReadOnly, formId)
  const lessCommonScenarioResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    [TaxListQuestionId.crypto_made_txns, TaxListQuestionId.k1_income],
    Number(formId)
  )
  const hasFollowUpQuestions = useMemo(
    () =>
      lessCommonScenarioResponses.some((response) => response?.value === true),
    [lessCommonScenarioResponses]
  )

  const ownInvestmentsResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    [TaxListQuestionId.own_investments],
    Number(formId)
  )
  const showInvestmentsSection = useMemo(
    () => ownInvestmentsResponses.some((response) => response?.value === true),
    [ownInvestmentsResponses]
  )

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            Review your answers for accuracy. Click edit to go back to the
            previous section.
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            You can no longer edit your Personal Tax Questionnaire responses,
            but you can view them anytime.
          </Text>
        </GridRowColumn>
      )}
      <Grid.Row />
      <ReviewSubSection
        title="income and liabilities"
        questionIds={IncomeAndLiabilitiesQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.incomeAndLiabilitiesIntro}
      />
      <ReviewSubSection
        title="rental property"
        questionIds={rentalPropertyQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.rentalProperty}
        group="groupId"
      />
      {showInvestmentsSection && (
        <ReviewSubSection
          title="investments"
          questionIds={investmentsQuestionIds}
          editScreen={INCOME_LIABILITIES_SCREENS.investments}
        />
      )}
      <ReviewSubSection
        title="retirement"
        questionIds={retirementContributedQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.retirementContributed}
        group="groupId"
      />
      <ReviewSubSection
        title="Less common scenarios"
        questionIds={lessCommonScenariosQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.lessCommonScenariosIntro}
      />
      {hasFollowUpQuestions && (
        <ReviewSubSection
          title="Less common scenarios follow-up"
          questionIds={lessCommonScenarioFollowUpQuestionIds}
          editScreen={INCOME_LIABILITIES_SCREENS.lessCommonScenarios}
        />
      )}
      {!readOnly && (
        <FormFlowFooter
          isSubmit
          onBack={() =>
            goBack(
              hasFollowUpQuestions
                ? INCOME_LIABILITIES_SCREENS.lessCommonScenarios
                : INCOME_LIABILITIES_SCREENS.lessCommonScenariosIntro
            )
          }
          onForward={() => navigate('/taxes/annual/tax_checklist/')}
        />
      )}
    </Grid>
  )
}

export default IncomeAndLiabilitiesReviewPanel
