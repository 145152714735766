import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'

export const NeedHelpTip = () => (
  <Card backgroundColor="sunrise" type="subsection">
    <Grid>
      <Grid.Column width={12}>
        <Grid>
          <GridRowColumn>
            <Text as="eyebrow" color="darkGray">
              Tip
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">
              What if I don&apos;t know how to answer a question or find a
              document?
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              Use the &apos;Unsure, follow-up with tax preparer&apos; checkbox
              to alert your tax preparer that you need guidance, regardless of
              whether you&apos;ve answered the question.
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Button variant="link">When can I expect follow-up?</Button>
          </GridRowColumn>
        </Grid>
      </Grid.Column>
      <Grid.Column width={4} verticalAlign="middle">
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/clipboard_checklist.svg"
          width={180}
        />
      </Grid.Column>
    </Grid>
  </Card>
)
