import { orderBy } from 'lodash'
import { createSelector } from 'reselect'
import { ReduxState } from '../../../utils/typeHelpers'
import { Pathway } from './qaFeatures.slice'

const allQAUsers = (state: ReduxState) => state.admin.allQAUsers

export const selectQAUsers = createSelector([allQAUsers], (users) =>
  Object.values(users)
)

export const selectQAUsersRecentlyActiveFirst = createSelector(
  [selectQAUsers],
  (users) => orderBy(users, (user) => user.qaDetails.lastActiveAt, ['desc'])
)

export const selectQAUsersForFeature = createSelector(
  [
    selectQAUsersRecentlyActiveFirst,
    (_: unknown, pathways: Pathway[]) => pathways,
  ],
  (users, pathways) => {
    const pathwayIdentifiers = pathways.map((p) => p.identifier)
    return users.filter((user) =>
      pathwayIdentifiers.includes(user.qaDetails.pathway.identifier)
    )
  }
)

/**
 * If the user does not have a role assigned in the database,
 * we assume they are a Customer
 */
export const selectCSVRolesForUser = createSelector(
  [selectQAUsers, (_: unknown, userId: number) => userId],
  (users, id) => {
    const user = users.find((u) => u.id === id)
    if (user) {
      return user.roles?.map((role) => role.name)?.join(',') || 'Customer'
    }

    return ''
  }
)
