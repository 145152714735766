import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  Alert,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Popup,
  Text,
  makeReqBoolSchema,
  getFieldNames,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import { LifeChangesStepProps } from '.'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import DocumentAddedAlert from '../../Shared/SpecialtyAlerts'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'

export const miscQuestionIds = [
  TaxListQuestionId.audited_last_year,
  TaxListQuestionId.offer_in_compromise,
  TaxListQuestionId.irs_installment_plan,
  TaxListQuestionId.debt_cancellation,
  TaxListQuestionId.paid_house_employee,
]

const MiscQuestionsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: LifeChangesStepProps) => {
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const auditedQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.audited_last_year,
  })

  const offerInCompromiseQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.offer_in_compromise,
  })

  const installmentPlanQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.irs_installment_plan,
  })

  const debtCancellationQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.debt_cancellation,
  })

  const houseEmployeeQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.paid_house_employee,
  })

  const skippedQuestions = useSkipQuestion(miscQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.audited_last_year]: auditedQR.initialResponse,
      [TaxListQuestionId.offer_in_compromise]:
        offerInCompromiseQR.initialResponse,
      [TaxListQuestionId.irs_installment_plan]:
        installmentPlanQR.initialResponse,
      [TaxListQuestionId.debt_cancellation]: debtCancellationQR.initialResponse,
      [TaxListQuestionId.paid_house_employee]: houseEmployeeQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData = [
        auditedQR.getResponseData(values),
        offerInCompromiseQR.getResponseData(values),
        installmentPlanQR.getResponseData(values),
        debtCancellationQR.getResponseData(values),
        houseEmployeeQR.getResponseData(values),
      ]

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.miscellaneousQuestions],
      })
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik

  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="display2" textAlign="center">
            Miscellaneous Questions
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg" textAlign="center">
            We just have a few more questions to ask about your year.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={fieldNames[TaxListQuestionId.audited_last_year]}
            label={auditedQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.audited_last_year,
              makeReqBoolSchema()
            )}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.audited_last_year]}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.audited_last_year]}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn {...makeGridConfig([6, 14], true)} short>
          <SkipQuestion questionId={TaxListQuestionId.audited_last_year} />
        </GridRowColumn>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={fieldNames[TaxListQuestionId.offer_in_compromise]}
            label={offerInCompromiseQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.offer_in_compromise,
              makeReqBoolSchema()
            )}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.offer_in_compromise]}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.offer_in_compromise]}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn {...makeGridConfig([6, 14], true)} short>
          <SkipQuestion questionId={TaxListQuestionId.offer_in_compromise} />
        </GridRowColumn>
        {values[TaxListQuestionId.offer_in_compromise] && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <DocumentAddedAlert>
              Documentation on the details of the Offer in Compromise added to
              checklist
            </DocumentAddedAlert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={fieldNames[TaxListQuestionId.irs_installment_plan]}
            label={installmentPlanQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.irs_installment_plan,
              makeReqBoolSchema()
            )}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.irs_installment_plan]}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.irs_installment_plan]}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn {...makeGridConfig([6, 14], true)} short>
          <SkipQuestion questionId={TaxListQuestionId.irs_installment_plan} />
        </GridRowColumn>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={fieldNames[TaxListQuestionId.debt_cancellation]}
            label={debtCancellationQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.debt_cancellation,
              makeReqBoolSchema()
            )}
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.debt_cancellation]}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.debt_cancellation]}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn {...makeGridConfig([6, 14], true)} short>
          <SkipQuestion questionId={TaxListQuestionId.debt_cancellation} />
        </GridRowColumn>
        {values[TaxListQuestionId.debt_cancellation] && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <Alert type="info">
              Got it! Your tax preparer will follow up later regarding this
              matter.
            </Alert>
          </GridRowColumn>
        )}
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([6, 14], true)}>
          <FormikLabelError
            name={fieldNames[TaxListQuestionId.paid_house_employee]}
            label={houseEmployeeQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.paid_house_employee,
              makeReqBoolSchema()
            )}
            afterLabel={
              <Popup
                content={
                  <Grid>
                    <GridRowColumn>
                      <Text as="h3">Household Employees</Text>
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Text>
                        Household employees include: housekeepers, maids,
                        babysitters, gardeners, and others who perform household
                        work in or around your private residence as your
                        employee.
                      </Text>
                    </GridRowColumn>
                    <GridRowColumn>
                      <Text>
                        Household employees do <b>NOT</b> include repairmen,
                        plumbers, contractors and other business people who
                        provide their services as independent contractors.
                      </Text>
                    </GridRowColumn>
                    <GridRowColumn>
                      <Text>
                        Household workers are your employees if you can control
                        not only the work they do, but also how they do it.
                      </Text>
                    </GridRowColumn>
                  </Grid>
                }
              />
            }
          />
        </GridRowColumn>
        <Grid.Row className="short" style={{ justifyContent: 'center' }}>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.paid_house_employee]}
              value
            >
              Yes
            </FormikRadioToggleButton>
          </Grid.Column>
          <Grid.Column width={3}>
            <FormikRadioToggleButton
              fullWidth
              name={fieldNames[TaxListQuestionId.paid_house_employee]}
              value={false}
            >
              No
            </FormikRadioToggleButton>
          </Grid.Column>
        </Grid.Row>
        {values[TaxListQuestionId.paid_house_employee] && (
          <GridRowColumn short {...makeGridConfig([6, 14], true)}>
            <Alert type="info">
              Got it! Your tax preparer will follow up later regarding this
              matter.
            </Alert>
          </GridRowColumn>
        )}
        <GridRowColumn {...makeGridConfig([6, 14], true)} short>
          <SkipQuestion questionId={TaxListQuestionId.paid_house_employee} />
        </GridRowColumn>
        <GridRowColumn short {...makeGridConfig([6, 14], true)}>
          <Divider />
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([10, 14], true)}>
          <UploadDocumentSubSection
            formId={formId}
            categories={[
              values[TaxListQuestionId.offer_in_compromise]
                ? UserDocumentCategoryIdentifier.offerInCompromise
                : undefined,
            ]}
          />
        </GridRowColumn>

        <Grid.Row />
        <FormFlowFooter
          continueDisabled={!isValid || isUpdating || isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isUpdating || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default MiscQuestionsPanel
