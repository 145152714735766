import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import { GridRowColumn, Text } from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { Colors } from '../../../../styles/theme'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import { selectFilteredGroupedUserTransactions } from '../../../Transactions/transactions.selectors'
import { TransactionCategoryRow } from '../../../../components/Transactions/TransactionCategoryList'
import { StepHeaders } from '../../../../components/StepHeader'
import './styles.scss'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'

interface TransactionData {
  [key: string]: Transaction[]
}

export interface ReviewIncomeProps {
  groupedExpenses: TransactionData
  totalExpenses: number
}

export const ReviewExpenses = () => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  // not updating these to Luxon for now because
  // they're used in the transaction selectors and would be high risk to change
  const startDate = useMemo(
    () => moment().utc().year(Number(taxYear)).startOf('year'),
    [taxYear]
  )
  const endDate = useMemo(
    () => moment().utc().year(Number(taxYear)).endOf('year'),
    [taxYear]
  )

  const expensesTransactions = useReselector(
    selectFilteredGroupedUserTransactions,
    { startDate, endDate }
  )
  const { groupedExpenses, totalExpenses } = expensesTransactions

  return (
    <Grid className="income-grid">
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/ledger_review.svg"
          imageAlt="ledger_review"
          imageWidth={180}
          imageHeight={180}
          title="Review your 2024 expenses"
          description=""
          customColor="yellow"
        >
          <Grid>
            <GridRowColumn className="align-left">
              <Text>
                Please conduct a final review of your expenses. If anything
                looks off, add a note. Our bookkeeping team will take a look and
                make any necessary revisions.
              </Text>
            </GridRowColumn>
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      <GridRowColumn
        spacer={2}
        width={12}
        columnStyle={{ backgroundColor: Colors.stone, borderRadius: 8 }}
      >
        <Grid>
          <Grid.Row className="total-transaction-grid" verticalAlign="middle">
            <Grid.Column width={8}>
              <Text as="h3">Total Expenses</Text>
            </Grid.Column>
            <Grid.Column width={8}>
              <Text textAlign="right" as="h3">
                {formatCurrencyFromCents(totalExpenses)}
              </Text>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </GridRowColumn>
      {Object.values(groupedExpenses).map((transactions) => (
        <TransactionCategoryRow
          key={transactions[0]?.transactionCategoryId || 'null'}
          transactions={transactions}
          defaultTitle="Uncategorized Expenses"
        />
      ))}
    </Grid>
  )
}

export default ReviewExpenses
