import { useMemo, useState } from 'react'
import { TransactionOrSplit } from '../../reducers/admin/allTransactions.slice'
import { useReselector } from '../../utils/sharedHooks'
import { Button, GridRowColumn, IconButton, Text } from '../BaseComponents'
import { Grid, Divider } from 'semantic-ui-react'
import {
  addCurrencyArray,
  formatCurrencyFromCents,
} from '../../utils/currencyHelpers'
import { getTransactionCategoryById } from '../../features/Reports/reports.selectors'
import { Colors } from '../../styles/theme'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { TransactionRow } from './TransactionRow'
import './TransactionCategoryList.scss'

export const TransactionCategoryRow = ({
  transactions,
  defaultTitle,
}: {
  transactions: TransactionOrSplit[]
  defaultTitle: string
}) => {
  const TRANSACTIONS_TO_SHOW = 10
  const [open, setOpen] = useState(false)
  const [showAll, setShowAll] = useState(
    transactions.length <= TRANSACTIONS_TO_SHOW
  )
  const category = useReselector(
    getTransactionCategoryById,
    transactions[0]?.transactionCategoryId
  )

  const incomeForCat = useMemo(
    () =>
      addCurrencyArray(transactions.map(({ amountInCents }) => amountInCents)),
    [transactions]
  )

  return (
    <GridRowColumn
      spacer={2}
      width={12}
      columnStyle={{ backgroundColor: Colors.stone40, borderRadius: 8 }}
    >
      <Grid>
        <Grid.Row
          verticalAlign="middle"
          className="category-row"
          onClick={() => setOpen((prev) => !prev)}
        >
          <Grid.Column width={9}>
            <Text as="h3">{category?.name || defaultTitle}</Text>
          </Grid.Column>
          <Grid.Column width={6}>
            <Text textAlign="right" as="bodyLg">
              {formatCurrencyFromCents(incomeForCat)}
            </Text>
          </Grid.Column>
          <Grid.Column width={1}>
            <IconButton icon={open ? light('minus') : light('plus')} />
          </Grid.Column>
        </Grid.Row>
        {open && (
          <>
            <Divider className="category-divider" />
            {transactions.map((transaction, index) =>
              showAll || index < TRANSACTIONS_TO_SHOW ? (
                <TransactionRow
                  key={transaction.id}
                  transaction={transaction}
                />
              ) : null
            )}
            <Grid.Row>
              <Grid.Column width={8}>
                <Text color="darkGray">
                  Showing{' '}
                  {!showAll
                    ? Math.min(transactions.length, TRANSACTIONS_TO_SHOW)
                    : transactions.length}{' '}
                  of {transactions.length}
                </Text>
              </Grid.Column>
              {!showAll && (
                <Grid.Column width={8} className="all-transactions-grid">
                  <Button
                    variant="secondaryLink"
                    onClick={() => setShowAll(true)}
                  >
                    View all transactions
                  </Button>
                </Grid.Column>
              )}
              {showAll && (
                <Grid.Column width={8} className="all-transactions-grid">
                  <Button
                    variant="secondaryLink"
                    onClick={() => setShowAll(false)}
                  >
                    View less transactions
                  </Button>
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row className="short" />
          </>
        )}
      </Grid>
    </GridRowColumn>
  )
}
