import { Grid } from 'semantic-ui-react'
import { Button, Icon, Text } from '../../../components/BaseComponents'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NextStepPill } from '../NextStepStatusPillRules'
import { useNavigate } from 'react-router-dom'
import { isNil } from 'lodash'

const EOYNextStepPill = ({
  icon,
  title,
  subtext,
  backgroundColor,
  width,
  navigateTo,
}: NextStepPill) => {
  const navigate = useNavigate()

  return (
    <div
      style={{
        borderRadius: '100px',
        backgroundColor,
        width,
        marginTop: '16px',
      }}
    >
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={1} style={{ paddingLeft: 30 }}>
            {icon}
          </Grid.Column>
          <Grid.Column width={navigateTo ? 10 : 12}>
            <div>
              {title && (
                <Text as="bodySm" style={{ fontWeight: 500 }}>
                  {title}
                </Text>
              )}
              <Text as="bodyXs">{subtext}</Text>
            </div>
          </Grid.Column>
          {!isNil(navigateTo) && (
            <Grid.Column width={2}>
              <Button
                variant="secondaryLink"
                onClick={() => navigate(navigateTo)}
              >
                <Icon icon={solid('arrow-right')} />
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  )
}
export default EOYNextStepPill
