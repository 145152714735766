import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Alert,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { Colors, Fonts } from '../../../../styles/theme'
import Tooltip from '../../../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import { StepHeaders } from '../../../../components/StepHeader'
import './styles.scss'

interface IOwnerInvestmentProps {
  investmentTransactions: Transaction[]
}

export const OwnersInvestment = ({
  investmentTransactions = [],
}: IOwnerInvestmentProps) => {
  const [investmentTransactionsIds, setInvestmentTransactionIds] = useState<
    number[]
  >([])

  useEffect(() => {
    if (investmentTransactions.length && !investmentTransactionsIds.length) {
      setInvestmentTransactionIds(investmentTransactions.map((t) => t.id))
    }
  }, [investmentTransactionsIds.length, investmentTransactions])

  return (
    <Grid>
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/taxesV2.svg"
          imageAlt="taxes"
          imageWidth={180}
          imageHeight={180}
          title="Do these Owner’s Investments look correct?"
          description=""
          customColor="green"
        >
          <Grid>
            <GridRowColumn className="align-left">
              <Text>
                We categorized the following transactions as{' '}
                <b>Owner’s Investments</b>, or money you put into your business.
                If something doesn’t look right, please add a note to explain
                why.
              </Text>
            </GridRowColumn>
            <GridRowColumn width={12} className="tooltip-grid">
              <Tooltip
                popup={{
                  title: 'Owner’s Investments',
                  body: 'Money or assets that an owner contributes towards starting or running a business.<br/>An example of this is income that was originally paid into your personal account, but transferred back into your business account.',
                }}
                labelComponent="What is an Owner’s Investment?"
                style={{
                  display: 'inline-block',
                  borderBottom: `2px dashed ${Colors.green}`,
                  ...Fonts.bodySm,
                }}
              />
            </GridRowColumn>
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      {investmentTransactionsIds?.length > 0 && <TransactionHeader />}
      <GridRowColumn short>
        {investmentTransactionsIds.map((tId) => (
          <TransactionCard key={tId} transactionId={tId} />
        ))}
      </GridRowColumn>
      {investmentTransactionsIds?.length === 0 && (
        <GridRowColumn width={10} spacer={3}>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been recategorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn width={10} spacer={3} className="alert-footer">
        <Alert title="Why are we asking this?">
          Sometimes business income is miscategorized as Owner’s Investments. We
          need you to confirm if the above transactions are truly Owner’s
          Investments.
        </Alert>
      </GridRowColumn>
    </Grid>
  )
}

export default OwnersInvestment
