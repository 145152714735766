import { Grid } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { useNavigate } from 'react-router-dom'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import Lottie from 'lottie-react'
import tskBalloons from '../../../../assets/lottie/tskBalloons.json'
import { useSaveAnnualTaxFilingFormData } from '../helpers'
import { useEffect } from 'react'

const TSKReview = () => {
  const navigate = useNavigate()
  const currentAnnualTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentAnnualTaxYear
  )
  const saveTaxFiling = useSaveAnnualTaxFilingFormData(currentAnnualTaxYear)

  const navigationButton = annualTaxFiling?.optedOutAt
    ? {
        text: 'View Home',
        path: '/dashboard',
      }
    : {
        text: 'View Tax Center',
        path: '/taxes/annual',
      }

  useEffect(() => {
    if (annualTaxFiling?.id) {
      saveTaxFiling({ pretaxSurveySubmittedAt: new Date().toISOString() })
    }
  }, [annualTaxFiling?.id, saveTaxFiling])

  return (
    <Grid>
      <GridRowColumn centerContent style={{ marginTop: 30 }}>
        <Lottie animationData={tskBalloons} />
      </GridRowColumn>

      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Next steps
        </Text>
      </GridRowColumn>

      <GridRowColumn style={{ textAlign: 'center' }}>
        <Text>
          {annualTaxFiling?.optedOutAt ? (
            <>
              Taxes are only part of the picture of what is needed to close out
              the year. We&apos;ll guide you through the end of the year
              accounting activities you&apos;ll need in order to work with your
              tax prep provider.
            </>
          ) : (
            <>
              We&apos;re excited to support your accounting and tax needs for{' '}
              {currentAnnualTaxYear}!
              <br />
              We&apos;ll guide you through the end of year accounting and tax
              activities you&apos;ll need to successfully navigate the{' '}
              {currentAnnualTaxYear} tax season.
            </>
          )}
          <br />
        </Text>
      </GridRowColumn>

      <GridRowColumn
        columnStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="primary"
          onClick={() => navigate(navigationButton.path)}
        >
          {navigationButton.text}
        </Button>
      </GridRowColumn>
    </Grid>
  )
}

export default TSKReview
