import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { StepHeaders } from '../../../../components/StepHeader'
import './styles.scss'

interface IOtherExpensesProps {
  otherExpenses?: Transaction[]
}

export const OtherExpenses = ({ otherExpenses = [] }: IOtherExpensesProps) => {
  const [otherExpensesTransactionIds, setOtherExpensesTransactionIds] =
    useState<number[]>([])

  useEffect(() => {
    if (otherExpenses.length && !otherExpensesTransactionIds.length) {
      setOtherExpensesTransactionIds(otherExpenses.map((t) => t.id))
    }
  }, [otherExpensesTransactionIds.length, otherExpenses])

  return (
    <>
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/coins-falling.svg"
          imageAlt="coins"
          imageWidth={180}
          imageHeight={180}
          title="What are these expenses?"
          description="Transactions that are categorized as “Other” are at a high audit risk. 
            Select a category or add a note if you aren’t sure.
            Please be as descriptive as possible."
          customColor="green"
        />
      </GridRowColumn>

      <Grid className="footer">
        <Grid.Row />
        {otherExpensesTransactionIds?.length > 0 && <TransactionHeader />}
        <GridRowColumn short>
          {otherExpensesTransactionIds.map((tId) => (
            <TransactionCard key={tId} transactionId={tId} />
          ))}
        </GridRowColumn>
        {otherExpensesTransactionIds?.length === 0 && (
          <GridRowColumn width={10} spacer={3}>
            <Card backgroundColor="lightGreen">
              <Text>
                All transactions have been recategorized. You may continue to
                the next step.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        <Grid.Row />
      </Grid>
    </>
  )
}
