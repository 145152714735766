import { ReactNode, useState } from 'react'
import {
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { Grid, Image } from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const SideBarEntityCard = ({
  title,
  subtitle,
  image,
  description,
}: {
  title: string
  subtitle: string
  image: string
  description: ReactNode
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Card
      type="subsection"
      backgroundColor="natural"
      onClick={() => setIsExpanded(!isExpanded)}
      style={{ cursor: 'pointer' }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <Image src={image} style={{ width: 80, height: 80 }} />
          </Grid.Column>
          <Grid.Column width={11} verticalAlign="middle">
            <Text as="h2">{title}</Text>
            <Text as="bodyXs">{subtitle}</Text>
          </Grid.Column>
          <Grid.Column width={2} verticalAlign="middle">
            <Icon
              icon={isExpanded ? solid('minus') : solid('plus')}
              color="blue"
            />
          </Grid.Column>
        </Grid.Row>
        {isExpanded && (
          <GridRowColumn>
            <Card type="subsection" backgroundColor="blush">
              {description}
            </Card>
          </GridRowColumn>
        )}
      </Grid>
    </Card>
  )
}

export default SideBarEntityCard
