import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'
import { TaxSeasonKickoffSurveyProps } from './YearEndKickoffSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import {
  Card,
  FormikLabelError,
  FormikRadioButton,
  getFieldNames,
  GridRowColumn,
  makeReqBoolSchema,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { needContractorFilingOption } from '../annualTaxFilings.slice'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'

const ContractorPayroll = ({
  goToNextStep,
  goBack,
}: TaxSeasonKickoffSurveyProps) => {
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, currentTaxYear)

  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  const tskDueAt =
    annualTaxDetails?.taxSeasonKickoffDueAt &&
    isoToUTCDateTime(annualTaxDetails.taxSeasonKickoffDueAt).toFormat(
      DATE_FORMATS_LUXON.DISPLAY_LONG
    )

  const ten99DueAt =
    annualTaxDetails?.ten99NECFormsDueAt &&
    isoToUTCDateTime(annualTaxDetails.ten99NECFormsDueAt).toFormat(
      DATE_FORMATS_LUXON.DISPLAY_LONG
    )

  const formik = useFormik({
    initialValues: {
      paidContractorThisYear: annualTaxFiling?.paidContractorThisYear,
      needContractorFiling: annualTaxFiling?.needContractorFiling,
    },
    onSubmit: ({ paidContractorThisYear, needContractorFiling }) =>
      goToNextStep(
        {
          paidContractorThisYear,
          needContractorFiling:
            paidContractorThisYear === false
              ? needContractorFilingOption.no
              : needContractorFiling,
        },
        TAX_SEASON_KICKOFF_SURVEY.taxNeeds
      ),
  })

  const { submitForm, isSubmitting, isValid, values } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn
          width={12}
          columnStyle={{ display: 'flex', alignItems: 'center' }}
        >
          <Text as="display2" style={{ marginRight: 10 }}>
            Contractor tax payroll form
          </Text>
          <Text as="h2" color="darkGray">
            (1099-NEC)
          </Text>
        </GridRowColumn>
        <GridRowColumn width={12}>
          <Card type="subsection" backgroundColor="blush">
            <Text as="bodyLg">
              You only need to file a 1099-NEC in the following situations:
              <ul style={{ marginTop: 0, marginLeft: -10 }}>
                <li>
                  You paid a contractor <b>at least $600</b> in {currentTaxYear}
                  .
                </li>
                <li>You paid them by cash, check, or bank transfer.</li>
                <li>
                  You did <b>not</b> use a payroll provider to pay your
                  contractor.
                </li>
              </ul>
            </Text>
          </Card>
        </GridRowColumn>

        <GridRowColumn width={12}>
          <FormikLabelError
            name={fieldNames.paidContractorThisYear}
            label={`Did you pay a contractor in ${currentTaxYear} according to the terms above?`}
            schema={makeReqBoolSchema()}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            name={fieldNames.paidContractorThisYear}
            label="Yes"
            value
            variant="default"
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            name={fieldNames.paidContractorThisYear}
            label="No"
            value={false}
            variant="default"
          />
        </GridRowColumn>

        {values.paidContractorThisYear === false && (
          <GridRowColumn short width={12}>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">
                Got it. You won’t need to file a form 1099-NEC for{' '}
                {currentTaxYear}. If this changes in the future, you will be
                able to request one until {tskDueAt}.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        {values.paidContractorThisYear && (
          <>
            <GridRowColumn width={12}>
              <FormikLabelError
                name={fieldNames.needContractorFiling}
                label="Would you like Heard to create 1099-NECs for your contractors?"
                schema={makeReqStringSchema()}
              />
            </GridRowColumn>
            <GridRowColumn short width={12}>
              <FormikRadioButton
                name={fieldNames.needContractorFiling}
                label="Yes"
                value={needContractorFilingOption.yes}
                variant="default"
              />
            </GridRowColumn>
            <GridRowColumn short width={12}>
              <FormikRadioButton
                name={fieldNames.needContractorFiling}
                label="No"
                value={needContractorFilingOption.no}
                variant="default"
              />
            </GridRowColumn>

            {values.needContractorFiling === needContractorFilingOption.yes && (
              <GridRowColumn short width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    Great, we’ll add this to your checklist. 1099-NECs are due{' '}
                    {ten99DueAt}, so we will help you file these before the
                    deadline.
                  </Text>
                </Card>
              </GridRowColumn>
            )}

            {values.needContractorFiling === needContractorFilingOption.no && (
              <GridRowColumn short width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    Got it. We’ll let you handle this on your own. If you change
                    your mind, you will be able to request one until {tskDueAt}.
                  </Text>
                </Card>
              </GridRowColumn>
            )}
          </>
        )}

        <FormFlowFooter
          onBack={() => goBack(TAX_SEASON_KICKOFF_SURVEY.taxEntity)}
          onForward={submitForm}
          continueDisabled={!isValid || isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default ContractorPayroll
