import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Alert,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { Colors, Fonts } from '../../../../styles/theme'
import Tooltip from '../../../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import { StepHeaders } from '../../../../components/StepHeader'
import './styles.scss'

interface IOwnerDistributionProps {
  distributionTransactions: Transaction[]
}

export const OwnersDistribution = ({
  distributionTransactions = [],
}: IOwnerDistributionProps) => {
  const [distributionTransactionIds, setDistributionTransactionIds] = useState<
    number[]
  >([])

  useEffect(() => {
    // Still want to display accounts after category updates,
    // so don't update the distributionTransactionsIds list that was initially loaded
    if (distributionTransactions.length && !distributionTransactionIds.length) {
      setDistributionTransactionIds(distributionTransactions.map((t) => t.id))
    }
  }, [distributionTransactionIds.length, distributionTransactions])

  return (
    <Grid>
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/coinsV2.svg"
          imageAlt="coins"
          imageWidth={180}
          imageHeight={180}
          title="Do these Owner’s Distributions look correct?"
          description=""
          customColor="yellow"
        >
          <Grid>
            <GridRowColumn className="align-left">
              <Text>
                We categorized these expenses as <b>Owner’s Distributions</b>,
                or business funds that you took out for personal use. If
                something doesn’t look right, please add a note to explain why.
              </Text>
            </GridRowColumn>
            <GridRowColumn width={12} className="tooltip-grid">
              <Tooltip
                popup={{
                  title: 'Owner’s Distributions',
                  body: 'Funds that you draw from your business checking account into your personal checking account, and are used solely for personal purposes (no business-related expenses).<br/>For example, paying yourself is an Owner’s Distribution.',
                }}
                labelComponent="What is an Owner’s Distribution?"
                style={{
                  display: 'inline-block',
                  borderBottom: `2px dashed ${Colors.green}`,
                  ...Fonts.bodySm,
                }}
              />
            </GridRowColumn>
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      <Grid.Row />
      {distributionTransactionIds?.length > 0 && <TransactionHeader />}
      <GridRowColumn short>
        {distributionTransactionIds.map((tId) => (
          <TransactionCard key={tId} transactionId={tId} />
        ))}
      </GridRowColumn>
      {distributionTransactionIds?.length === 0 && (
        <GridRowColumn width={10} spacer={3}>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been recategorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn width={10} spacer={3} className="alert-footer">
        <Alert title="Why are we asking this?">
          Sometimes these transactions get miscategorized. For example, you may
          have reimbursed yourself for a business expense you paid for on a
          personal card. It’s categorized as an Owner’s Distribution, but it’s
          actually a business expense.
        </Alert>
      </GridRowColumn>
    </Grid>
  )
}

export default OwnersDistribution
