import { useBooleanFlagValue } from '@openfeature/react-sdk'

import { Alert, Link } from '../../components/BaseComponents'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import { useQTEReminderBanner } from '../Taxes/QuarterlyTaxEstimates/QTEReminderBanner'

const HeardCommunityBanner = () => {
  const isShowingQTEBanner = useQTEReminderBanner()
  const shouldDisplayCommunityLink = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableHeardCommunity,
    false
  )

  if (!shouldDisplayCommunityLink || isShowingQTEBanner) {
    return null
  }

  return (
    <Alert type="announcement">
      Join the new{' '}
      <Link href="https://support.joinheard.com/hc/en-us/community/topics">
        Heard Community
      </Link>{' '}
      — connect with other practice owners to share tips, insights, and get
      support.
    </Alert>
  )
}

export default HeardCommunityBanner
