import { useEffect, useMemo, useState } from 'react'
import { Grid, List } from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import './styles.scss'

import {
  Alert,
  Icon,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import { StepHeaders } from '../../../../components/StepHeader'

interface IOtherExpensesProps {
  otherIncomes?: Transaction[]
}

export const OtherIncome = ({ otherIncomes = [] }: IOtherExpensesProps) => {
  const [otherIncomeTransactionsIds, setOtherIncomeTransactionsIds] = useState<
    number[]
  >([])

  // Finds ids that are no longer in state (because they were updated)
  const updatedIds = useMemo(
    () =>
      otherIncomeTransactionsIds.filter(
        (id) => !otherIncomes.map((t) => t.id).includes(id)
      ),
    [otherIncomeTransactionsIds, otherIncomes]
  )

  useEffect(() => {
    // Still want to display accounts after category updates,
    // so don't update the otherIncomeTransactions list that was initially loaded
    if (otherIncomes.length && !otherIncomeTransactionsIds.length) {
      setOtherIncomeTransactionsIds(otherIncomes.map((t) => t.id))
    }
  }, [otherIncomeTransactionsIds.length, otherIncomes])

  const hasUpdatedCategory = (id: number) =>
    Boolean(updatedIds.find((uId) => uId === id))

  return (
    <Grid>
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/ledger-bookkeeping.svg"
          imageAlt="ledger"
          imageWidth={180}
          imageHeight={180}
          title="Does any of this income belong to one of these categories?"
          description=""
          customColor="yellow"
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <List bulleted className="flex-column">
                  <List.Item>Consulting Income</List.Item>
                  <List.Item>Retail Income</List.Item>
                  <List.Item>Speaking Income</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6}>
                <List bulleted className="flex-column">
                  <List.Item>Supervision Income</List.Item>
                  <List.Item>Therapy Income</List.Item>
                  <List.Item>Teaching Income</List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn className="align-left">
              <Text>
                If a transaction doesn’t fit in any of these categories, add a
                note.
              </Text>
            </GridRowColumn>
            <Grid.Row />
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      <TransactionHeader />
      <GridRowColumn short>
        {otherIncomeTransactionsIds.map((tId) => (
          <TransactionCard
            key={tId}
            transactionId={tId}
            backgroundColor={hasUpdatedCategory(tId) ? 'lightGreen' : undefined}
          />
        ))}
      </GridRowColumn>
      {otherIncomeTransactionsIds?.length === 0 && (
        <GridRowColumn width={10} spacer={3}>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been recategorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}
      <GridRowColumn>
        <div className="alert-footer">
          <Alert
            title="Why are we asking this?"
            style={{ width: '520px' }}
            customIcon={<Icon icon={solid('info-circle')} />}
            contentStyle={{ textAlign: 'left' }}
          >
            Transactions that are categorized as “Other” are at a high audit
            risk.
          </Alert>
        </div>
      </GridRowColumn>
    </Grid>
  )
}

export default OtherIncome
