import UploadCard from './UploadCard'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { useSkippedDocumentCategories } from '.'
import { filterNulls } from '../../../../../utils/typeHelpers'
import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'

const UploadDocumentSubSection = ({
  categories,
  formId,
}: {
  categories: Array<UserDocumentCategoryIdentifier | undefined>
  formId: number | undefined | string
}) => {
  const { skippedCategories, updateSkipped } =
    useSkippedDocumentCategories(formId)

  const filteredCats = filterNulls(categories)

  if (!filteredCats.length) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h2">Upload documents</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          If you don’t have a document on hand, you may skip for now. You’ll be
          able to view instructions and upload everything at the end.
        </Text>
      </GridRowColumn>
      {filteredCats.map((category) => (
        <UploadCard
          key={category}
          docCategoryIdentifier={category}
          formId={Number(formId)}
          skipped={skippedCategories?.includes(category)}
          updateSkipped={updateSkipped}
        />
      ))}
    </Grid>
  )
}

export default UploadDocumentSubSection
