import { FormikProvider, useFormik } from 'formik'
import { Divider } from 'semantic-ui-react'
import {
  Accordion,
  Card,
  FormikDropdown,
  getFieldName,
  Text,
} from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import './styles.scss'

const dropdownOptions = [
  {
    id: 1,
    text: 'Gusto',
    value: 'Gusto' as const,
  },
  {
    id: 2,
    text: 'ADP',
    value: 'ADP' as const,
  },
  {
    id: 3,
    text: 'Quickbooks',
    value: 'Quickbooks' as const,
  },
  {
    id: 4,
    text: 'Other',
    value: 'Other' as const,
  },
]

export const ContractorDocuments = () => {
  const handleNotRelevant = (provider: string) => {
    console.log('Not Relevant:', provider) // skipcq: JS-0002 pending hea-5102
  }

  const formik = useFormik({
    initialValues: {
      payrollProviders: [],
    },
    onSubmit: (values) => {
      console.log('Selected Providers:', values.payrollProviders) // skipcq: JS-0002 pending hea-5102
    },
  })

  const { values, setFieldValue, handleSubmit } = formik

  return (
    <div className="eoybk__contractor__container">
      <StepHeaders
        customColor="yellow"
        description="If you pay contractors through a payroll provider, you need to upload a Contractor Payment Report for each provider."
        imageSrc="https://heard-images.s3.amazonaws.com/assets/writing_check.svg"
        imageAlt="stacked checkbooks"
        imageWidth={180}
        imageHeight={180}
        kickerText="YEAR-END DOCUMENTS"
        title="Contractor payments"
      />

      <div>
        <Text>
          Which payroll provider(s) did you use to pay contractors in 2024?
        </Text>

        <Text as="bodyXs" className="eoybk__contractor__multi-provider">
          If you used multiple, select all providers you used.
        </Text>

        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <FormikDropdown
              name={getFieldName<typeof values>('payrollProviders')}
              placeholder="Select payroll provider(s)"
              options={dropdownOptions}
              multiple
              clearable
              fullWidth
              onChange={(val) => {
                setFieldValue('payrollProviders', val)
              }}
            />
          </form>
        </FormikProvider>

        {values.payrollProviders.length > 0 && (
          <Card backgroundColor="stone40">
            <Text as="eyebrow" color="darkGray">
              DOCUMENTS YOU NEED
            </Text>

            <Text>
              Based on your answer, you’ll need to upload a contractor payment
              report from:
            </Text>

            <ul>
              {values.payrollProviders.map((provider) => (
                <li
                  key={provider}
                  className="eoybk__contractor__provider-uploads"
                >
                  <strong>{provider}</strong>
                </li>
              ))}
            </ul>

            <Text>
              Please contact your provider’s support if you need assistance
              locating your documents.
            </Text>
          </Card>
        )}
      </div>

      <Accordion
        className="eoybk__contractor__accordion-text"
        title="What if my payroll provider isn’t listed?"
        content={
          <Text>
            We need Contractor Payment Reports for all payroll providers you
            used in 2024. Please download it from your payroll provider’s
            website and upload it under “Other documents” below.
          </Text>
        }
        variant="text"
      />

      <Divider className="eoybk__contractor__divider" />

      <Text as="h2">Upload Contractor Payment Reports</Text>

      <Text>
        If you don’t have a document on hand, you may skip for now. You’ll be
        able to view instructions and upload everything at the end.
      </Text>

      <div className="eoybk__contractor__document-cards">
        {values.payrollProviders.map((provider) => (
          <DocumentUploadCard
            key={provider}
            name={`${provider} - Contractor Payment Report`}
            description="This summarizes your business’ total salary payments and withholding amounts."
            setChecked={() => {
              handleNotRelevant(provider)
            }}
          />
        ))}

        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you’d like to share with the bookkeeping team here."
          status="disabled"
        />
      </div>
    </div>
  )
}
