import React, { ReactNode } from 'react'
import { Image } from 'semantic-ui-react'
import styles from './styles.module.scss'

interface ILandingPageProps {
  children?: React.ReactNode
  imageAlt: string
  imageSrc: string
  imageHeight: number
  imageWidth: number
  kickerText?: ReactNode
  title: string
}

export const LandingPage: React.FC<ILandingPageProps> = ({
  children,
  imageAlt,
  imageSrc,
  imageHeight,
  imageWidth,
  kickerText,
  title,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image
          src={imageSrc}
          alt={imageAlt}
          width={imageWidth}
          height={imageHeight}
        />
      </div>

      {kickerText && <text>{kickerText}</text>}
      <h1 className={styles.header}>{title}</h1>

      {children}
    </div>
  )
}
