import { useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import { distinctTransactionsByDateDESC } from '../../../Transactions/transactions.selectors'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import { StepHeaders } from '../../../../components/StepHeader'
import './styles.scss'

interface IUnclarifiedTransactionProps {
  uncatTransactions?: Transaction[]
  bookkeeperClarification?: Transaction[]
}
export const UnclarifiedTransactions = ({
  uncatTransactions = [],
  bookkeeperClarification = [],
}: IUnclarifiedTransactionProps) => {
  const transactionIds = useMemo(
    () => [
      ...uncatTransactions.map((t) => t.id),
      ...bookkeeperClarification.map((t) => t.id),
    ],
    [uncatTransactions, bookkeeperClarification]
  )

  const [intialUncategorizedTxns, setintialUncategorizedTxns] = useState<
    Transaction[]
  >([])
  useEffect(() => {
    if (uncatTransactions.length && !intialUncategorizedTxns.length) {
      // Note - the above logic assumes uncategorized transactions and needs clarification transactions have been fetched
      // from the server by a higher component. Otherwise we could run into timing issues and would need a loading flag
      setintialUncategorizedTxns(
        distinctTransactionsByDateDESC(uncatTransactions)
      )
    }
  }, [intialUncategorizedTxns.length, uncatTransactions])

  const updatedTransactionsIds = useMemo(
    () =>
      [...intialUncategorizedTxns].flatMap((t) =>
        !transactionIds.includes(t.id) ? t.id : []
      ),
    [transactionIds, intialUncategorizedTxns]
  )

  return (
    <Grid>
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/notepad.svg"
          imageAlt="notepad"
          imageWidth={180}
          imageHeight={180}
          title="How should we categorize these transactions?"
          description=""
          customColor="green"
        >
          <Grid>
            <GridRowColumn className="align-left">
              <Text>
                Please provide clarity on the transactions below. Add a note to
                provide context, or select a category.
              </Text>
            </GridRowColumn>
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      <Grid.Row />
      {!intialUncategorizedTxns.length && (
        <GridRowColumn>
          <Card backgroundColor="lightGreen">
            <Text>
              All transactions have been categorized. You may continue to the
              next step.
            </Text>
          </Card>
        </GridRowColumn>
      )}

      {Boolean(intialUncategorizedTxns.length) && (
        <>
          <TransactionHeader />
          <GridRowColumn className="grid-body">
            {intialUncategorizedTxns.map((t) => (
              <TransactionCard
                key={t.id}
                transactionId={t.id}
                backgroundColor={
                  updatedTransactionsIds.includes(t.id)
                    ? 'lightGreen'
                    : undefined
                }
              />
            ))}
          </GridRowColumn>
        </>
      )}
    </Grid>
  )
}

export default UnclarifiedTransactions
