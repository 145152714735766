import { Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  TaxSeasonKickoffSurveyProps,
  kickoffTicketTags,
  kickoffTicketTopic,
  kickoffTicketTasks,
} from './YearEndKickoffSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import {
  Card,
  FormikLabelError,
  FormikRadioButton,
  getFieldNames,
  GridRowColumn,
  Icon,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { Colors } from '../../../../styles/theme'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import FileUploadFormInput from '../../../../components/FileUpload/FileUploadFormInput'
import { getUserDocumentsByCategoryInternalName } from '../../../UserDocuments/userDocuments.selector'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'
import { postCreateNewTicket } from '../../../Conversations/actions'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  selectIsCurrentUserScorp,
  selectUserName,
} from '../../../../selectors/user.selectors'

enum HasForm {
  yes = 'yes',
  noHasFiledForm = 'noFiledForm',
  noForm = 'noForm',
}

const ScorpConfirmation = ({
  goToNextStep,
  goBack,
}: TaxSeasonKickoffSurveyProps) => {
  const dispatch = useAppDispatch()
  const userName = useReselector(selectUserName)
  const cp261Notice = useReselector(
    getUserDocumentsByCategoryInternalName,
    UserDocumentCategoryIdentifier.cp261Notice
  )
  const verifiedScorp = Boolean(cp261Notice.length)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isScorp = useReselector(selectIsCurrentUserScorp)

  const formik = useFormik({
    initialValues: {
      hasForm: undefined as undefined | HasForm,
    },
    onSubmit: (answers) => {
      let body
      let subject
      let ticketTask
      const tags = [...kickoffTicketTags]

      if (answers.hasForm === HasForm.yes) {
        subject = `Verifying your CP261 for ${taxYear} taxes`
        tags.push('verify-cp261')
        ticketTask = kickoffTicketTasks.verifyCP261

        if (isScorp) {
          body = `${userName} is currently listed as an S-Corp but has not been verified by Heard, and they have uploaded a CP261 for review.`
        } else {
          body = `${userName} is currently listed as a Sole Prop but has indicated they elected to being an S-corp, and has uploaded a CP261 for verification.`
        }
      } else if (answers.hasForm === HasForm.noHasFiledForm) {
        subject = `Obtaining your CP261 for ${taxYear} taxes`
        tags.push('waiting-on-cp261')
        ticketTask = kickoffTicketTasks.requestCP261

        if (isScorp) {
          body = `${userName} is currently listed as an S-corp but is waiting on their CP261.`
        } else {
          body = `${userName} is currently listed as a Sole Prop but has indicated they elected to being an S-corp, and are still waiting for their CP261.`
        }
      } else {
        subject = `Obtaining your CP261 for ${taxYear} taxes`
        tags.push('cant-find-cp261')
        ticketTask = kickoffTicketTasks.requestCP261

        if (isScorp) {
          body = `${userName} is currently listed as an S-corp but cannot find their CP261.`
        } else {
          body = `${userName} is currently listed as a Sole Prop but has indicated they elected to being an S-corp, and they are unable to find their CP261.`
        }
      }

      dispatch(
        postCreateNewTicket({
          comment: { body, htmlBody: `<p>${body}</p>` },
          internalSubmitter: true,
          ticketTopic: kickoffTicketTopic,
          ticketTask,
          subject,
          tags,
        })
      )

      goToNextStep(null, TAX_SEASON_KICKOFF_SURVEY.contractorPayroll)
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn width={12}>
          <Text as="display2" textAlign="center">
            Verify your S corporation tax status
          </Text>
        </GridRowColumn>
        <GridRowColumn width={12}>
          <Card
            style={{ display: 'flex', alignItems: 'center' }}
            type="subsection"
            backgroundColor="lightYellow"
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/upload-statement.png"
              style={{
                width: 152,
                height: 152,
                backgroundColor: Colors.transparent,
              }}
            />
          </Card>
        </GridRowColumn>
        <GridRowColumn width={12}>
          <Text>
            The IRS sent you a letter (called Form CP261) that confirms they
            accepted your request to elect to S corporation status. This is the
            only way Heard can reliably know that the IRS considers you an
            S-corporation.
            <br />
            <br />
            This is required to receive the correct tax filing and accounting
            services to close out {taxYear}.
          </Text>
        </GridRowColumn>
        <GridRowColumn width={12}>
          <FormikLabelError
            label="Do any of the above situations apply?"
            name={fieldNames.hasForm}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="Yes"
            variant="default"
            value={HasForm.yes}
            name={fieldNames.hasForm}
          />
        </GridRowColumn>
        {values.hasForm === HasForm.yes && (
          <GridRowColumn width={12}>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">Great, please upload your Form CP261.</Text>
              <br />
              <FileUploadFormInput
                uploadedDocsLabel="Uploaded documents:"
                hideUploadIfDocs={false}
                documentCat={UserDocumentCategoryIdentifier.cp261Notice}
              />
            </Card>
          </GridRowColumn>
        )}
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="No, I filed Form 2553, and applied to be an S corporation with the IRS, but haven’t received Form CP261 yet"
            variant="default"
            value={HasForm.noHasFiledForm}
            name={fieldNames.hasForm}
          />
        </GridRowColumn>
        {values.hasForm === HasForm.noHasFiledForm && (
          <GridRowColumn width={12}>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">
                Once you’ve received your Form CP261, upload it to Heard right
                away to proceed with the rest of your Tax Season accounting and
                tax activities.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="No, I can’t find my form or I don’t have one"
            variant="default"
            value={HasForm.noForm}
            name={fieldNames.hasForm}
          />
        </GridRowColumn>
        {values.hasForm === HasForm.noForm && (
          <GridRowColumn width={12}>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">
                You can request a copy from the IRS by calling 1-800-829-4933.
                We’ll add this to your checklist to help you remember.
                <br />
                <br />
                In the meantime, if you have any questions, you can reach out to
                Support.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        {verifiedScorp && (
          <GridRowColumn width={12}>
            <Card
              type="subsection"
              backgroundColor="lightGreen"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Icon
                icon={regular('check')}
                color="green"
                style={{ marginRight: 20 }}
              />
              <Text as="bodyLg">
                Thanks for uploading your Form CP261. We’ll let you know if we
                need anything else.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        <FormFlowFooter
          onBack={() => goBack(TAX_SEASON_KICKOFF_SURVEY.taxEntity)}
          onForward={submitForm}
          continueDisabled={
            isSubmitting ||
            !isValid ||
            // Block if form has not been uploaded
            (!verifiedScorp && values.hasForm === HasForm.yes)
          }
        />
      </Grid>
    </FormikProvider>
  )
}

export default ScorpConfirmation
