import { Grid } from 'semantic-ui-react'
import { Alert, Button, Text } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { selectActiveQTEDetailsAsDates } from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useEffect, useMemo } from 'react'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY,
  fetchAllQuarterlyTaxEstimateDetailsIfNeeded,
} from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { DateTime } from 'luxon'
import {
  selectAllQTEChecklistItemsComplete,
  selectUserCreatedPastQteCutoff,
} from './userTaxEstimates.selector'
import { selectIsBasicPlan } from '../../../reducers/subscription.slice'
import {
  getCurrentUser,
  getFreeTrialStatus,
} from '../../../selectors/user.selectors'
import { TrialStatus } from '../../../reducers/auth/userReducer'
import {
  FETCH_TAX_ESTIMATES_KEY,
  fetchUserTaxEstimatesIfNeeded,
} from './userTaxEstimates.slice'
import {
  FETCH_BOOKKEEPING_REPORT_KEY,
  fetchBookkeepingReports,
} from '../../../actions/bookkeepingReportActions'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'
import { useNavigate } from 'react-router-dom'

export const useQTEReminderBanner = () => {
  const activeQuarter = useReselector(selectActiveQTEDetailsAsDates)
  const userJoinedTooLate = useReselector(selectUserCreatedPastQteCutoff)
  const checklistComplete = useReselector(
    selectAllQTEChecklistItemsComplete,
    activeQuarter?.taxYear
  )
  const isBasicPlan = useReselector(selectIsBasicPlan)
  const trialStatus = useReselector(getFreeTrialStatus)
  const hasActiveTrial = trialStatus === TrialStatus.active
  const isAdminUser = useReselector(getCurrentUser)?.admin
  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_BOOKKEEPING_REPORT_KEY,
  ])
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
    dispatch(fetchUserTaxEstimatesIfNeeded())
    dispatch(fetchBookkeepingReports())
  }, [dispatch])

  return (
    (isFetching ||
      userJoinedTooLate ||
      checklistComplete ||
      isBasicPlan ||
      hasActiveTrial ||
      isAdminUser) === false
  )
}

const QTEReminderBanner = () => {
  const activeQuarter = useReselector(selectActiveQTEDetailsAsDates)
  const initialDueDate =
    activeQuarter?.forDisplay.initialCalculationLock.toFormat('L/d')
  const extendedDueDate =
    activeQuarter?.forDisplay.clientNotified.toFormat('L/d')
  const displayDate = useMemo(() => {
    return activeQuarter?.forDisplay.initialCalculationLock &&
      DateTime.now() > activeQuarter?.forDisplay.initialCalculationLock
      ? extendedDueDate
      : initialDueDate
  }, [
    initialDueDate,
    extendedDueDate,
    activeQuarter?.forDisplay.initialCalculationLock,
  ])
  const navigate = useNavigate()
  const shouldShowBanner = useQTEReminderBanner()

  if (!shouldShowBanner) {
    return null
  }

  return (
    <Alert type="warn">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={13}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text>
              Don&apos;t forget to get your quarterly tax estimate by completing
              your QTE Checklist by {displayDate}.
            </Text>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              variant="primary"
              onClick={() => navigate('/taxes/quarterly')}
            >
              Get Estimate
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Alert>
  )
}

export default QTEReminderBanner
