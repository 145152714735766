import { useCallback, useState } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  LabelDescription,
  LabelDescriptionProps,
} from '../BaseComponents/Input'
import { Button, Icon, IconButton, Link, Text } from '../BaseComponents'
import FileUploadModal from './FileUploadModal'
import { useReselector } from '../../utils/sharedHooks'
import { selectUserDocumentCategoryByInternalName } from '../../features/Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { getUserDocumentsByCategoryInternalName } from '../../features/UserDocuments/userDocuments.selector'
import { UploadDocumentType } from '../../constants/businessConstants'
import { destroySingleUserDocument } from '../../features/UserDocuments/userDocuments.slice'
import { useAppDispatch } from '../../utils/typeHelpers'
import { UserDocumentCategoryIdentifier } from '../../features/Admin/UserDocumentCategories/userDocumentCategory.constants'

// Used for forms but doesn't use formik so doesn't belong in form inputs
const FileUploadFormInput = ({
  documentCat,
  documentType = UploadDocumentType.TAX,
  taxYear,
  hideUploadIfDocs = true,
  uploadedDocsLabel,
  ...rest
}: LabelDescriptionProps & {
  documentCat?: UserDocumentCategoryIdentifier
  documentType?: UploadDocumentType
  taxYear?: string
  hideUploadIfDocs?: boolean
  uploadedDocsLabel?: string
}) => {
  const dispatch = useAppDispatch()
  const [modelOpen, setModalOpen] = useState(false)

  const taxDocCat = useReselector(
    selectUserDocumentCategoryByInternalName,
    documentCat
  )

  const userDocs = useReselector(
    getUserDocumentsByCategoryInternalName,
    documentCat
  )

  const deleteFile = useCallback(
    async (fileId: number) => {
      await destroySingleUserDocument(fileId)(dispatch)
    },
    [dispatch]
  )

  return (
    <>
      <LabelDescription {...rest} />

      {taxDocCat && (!hideUploadIfDocs || !userDocs.length) && (
        <Button
          variant="secondary"
          onClick={() => setModalOpen(true)}
          style={{ width: 150 }}
        >
          <Icon
            icon={regular('upload')}
            size="1x"
            style={{ marginRight: 10 }}
          />
          Upload
        </Button>
      )}

      <br />
      {Boolean(uploadedDocsLabel && userDocs.length) && (
        <Text as="eyebrow">{uploadedDocsLabel}</Text>
      )}
      {userDocs.map((doc) => (
        <div key={doc.id}>
          {doc?.signedUrl ? (
            <Link href={doc.signedUrl}>{doc?.fileDescription}</Link>
          ) : (
            <Text>{doc?.fileDescription}</Text>
          )}
          <IconButton
            icon={regular('trash-can')}
            onClick={() => deleteFile(doc.id)}
            style={{ marginLeft: 10 }}
            color="green"
          />
        </div>
      ))}

      <FileUploadModal
        open={modelOpen}
        close={() => setModalOpen(false)}
        userFacing
        documentType={documentType}
        categoryId={taxDocCat?.id}
        year={taxYear}
      />
    </>
  )
}

export default FileUploadFormInput
