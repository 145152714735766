import { Grid, Image } from 'semantic-ui-react'
import './AnnualTaxOverview.scss'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { AnnualTaxFiling } from '../annualTaxFilings.slice'
import UpgradeToPremiumModal from '../../../../components/shared/UpgradeToPremium/UpgradeToPremiumModal'

const centeredColumnStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const AnnualTaxTskEntryPoint = ({
  userHasBasicPlan,
  annualTaxFiling,
}: {
  userHasBasicPlan: boolean
  annualTaxFiling: AnnualTaxFiling | undefined
}) => {
  const navigate = useNavigate()
  return (
    <Grid
      style={{
        maxWidth: '600px',
        margin: '64px auto',
      }}
    >
      {userHasBasicPlan ? (
        <UpgradeToPremiumModal feature="annual-taxes" />
      ) : (
        <>
          <GridRowColumn columnStyle={centeredColumnStyle}>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg"
              style={{
                width: '250px',
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </GridRowColumn>

          <GridRowColumn columnStyle={centeredColumnStyle}>
            {annualTaxFiling?.pretaxSurveySubmittedAt ? (
              <Text as="display2">Annual taxes are coming soon!</Text>
            ) : (
              <Text as="display2">Let us know your annual tax needs</Text>
            )}
          </GridRowColumn>

          <GridRowColumn
            columnStyle={{ textAlign: 'center', justifyItems: 'center' }}
          >
            {annualTaxFiling?.pretaxSurveySubmittedAt ? (
              <>
                <Text>
                  You&apos;ll be able to start your annual taxes on January 6th
                </Text>
                <Card backgroundColor="stone40" style={{ width: '80%' }}>
                  <Text>Change of tax plans?</Text>
                  <br />
                  <Button
                    variant="link"
                    onClick={() => navigate('/taxes/annual/kickoff-survey')}
                  >
                    Update your Tax Season Kickoff response{' '}
                    <Icon
                      icon={regular('arrow-right')}
                      style={{ marginLeft: 5 }}
                    />
                  </Button>
                </Card>
              </>
            ) : (
              <>
                <Text>
                  Please complete your <b>Tax Season Kickoff</b> to let us know
                  if you&apos;d like to file taxes with Heard. You can find this
                  one your dashboard.
                </Text>
                <br />
                <Text>
                  After you&apos;ve completed the survey, you&apos;ll need to
                  complete all of your Get Started items before you can start on
                  your annual tax preparation.
                </Text>
                <br />
                <Text as="bodySm">
                  <Icon
                    icon={regular('timer')}
                    style={{ marginRight: 10 }}
                    size="1x"
                  />
                  5 minutes
                </Text>
              </>
            )}
          </GridRowColumn>
          <GridRowColumn columnStyle={centeredColumnStyle}>
            {annualTaxFiling?.pretaxSurveySubmittedAt ? (
              <Button variant="primary" onClick={() => navigate('/dashboard')}>
                Return Home
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => navigate('/taxes/annual/kickoff-survey')}
              >
                Start Tax Season Kickoff
              </Button>
            )}
          </GridRowColumn>
        </>
      )}
    </Grid>
  )
}

export default AnnualTaxTskEntryPoint
