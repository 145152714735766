import { Grid, Image } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

export const PersonalFilingOptInReminder = () => (
  <Card backgroundColor="sunrise" type="subsection">
    <Grid>
      <Grid.Column width={14}>
        <Grid>
          <GridRowColumn>
            <Text as="eyebrow" color="red">
              Reminder
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">Business owners must file personal taxes</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              We noticed you opted out of having your personal tax return (Form
              1040) prepared through Heard. While you&apos;re not filing with
              us, you&apos;re still required to submit your personal tax return
              this year.
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              Changed your mind? There&apos;s still time to opt-in with us.
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Link
              style={{ display: 'flex', gap: 4, fontSize: 14 }}
              to="/taxes/annual"
            >
              File personal taxes with Heard{' '}
              <Icon icon={regular('arrow-right')} />
            </Link>
          </GridRowColumn>
        </Grid>
      </Grid.Column>
      <Grid.Column width={2} verticalAlign="middle">
        <Image src="https://heard-images.s3.amazonaws.com/assets/Sparkles.svg" />
      </Grid.Column>
    </Grid>
  </Card>
)
