import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'

import {
  kickoffTicketTags,
  kickoffTicketTasks,
  kickoffTicketTopic,
  TaxSeasonKickoffSurveyProps,
} from './YearEndKickoffSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import {
  Card,
  FormikLabelError,
  FormikRadioButton,
  FormikTextArea,
  getFieldNames,
  GridRowColumn,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { postCreateNewTicket } from '../../../Conversations/actions'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'
import { selectUserName } from '../../../../selectors/user.selectors'

enum SolePropHelpAnswer {
  wantToBeScorp = 'wantToBeScorp',
  notSure = 'notSure',
  somethingElse = 'somethingElse',
}

const SolePropTaxEntityHelp = ({
  goToNextStep,
  goBack,
}: TaxSeasonKickoffSurveyProps) => {
  const dispatch = useAppDispatch()
  const userName = useReselector(selectUserName)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const formik = useFormik({
    initialValues: {
      solePropAnswer: undefined as undefined | SolePropHelpAnswer,
      solePropOther: '',
    },
    onSubmit: (answers) => {
      let body
      let subject
      let ticketTask
      const tags = [...kickoffTicketTags]

      if (answers.solePropAnswer === SolePropHelpAnswer.wantToBeScorp) {
        ticketTask = kickoffTicketTasks.election
        subject = `Considering S Corp election for ${taxYear} taxes`
        body = `${userName} is currently listed as a Sole Prop but has indicated they want to elect to become an S-corp.`
        tags.push('scorp-election-help')
      } else if (answers.solePropAnswer === SolePropHelpAnswer.notSure) {
        ticketTask = kickoffTicketTasks.determine
        subject = `Determining your tax entity type for ${taxYear} taxes`
        body = `${userName} is currently listed as a Sole Prop but has indicated they are unclear if they are still a Sole Prop, and are not sure what their tax entity is.`
        tags.push('tax-entity-confusion')
      } else {
        ticketTask = kickoffTicketTasks.determine
        subject = `Determining your tax entity type for ${taxYear} taxes`
        body = `${userName} is currently listed as a Sole Prop but has indicated they need help with confirming their tax entity.\nComment: ${answers.solePropOther}`
        tags.push('tax-entity-confusion-other')
      }

      dispatch(
        postCreateNewTicket({
          comment: { body, htmlBody: `<p>${body}</p>` },
          internalSubmitter: true,
          ticketTopic: kickoffTicketTopic,
          ticketTask,
          subject,
          tags,
        })
      )

      goToNextStep(null, TAX_SEASON_KICKOFF_SURVEY.helpSubmission)
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <Grid.Row />
        <GridRowColumn>
          <Text as="display2">Let’s get you some help</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="bodyLg">
            We need to get a little more information so a member of our Heard
            support team can better help you.
          </Text>
        </GridRowColumn>
        <GridRowColumn width={12}>
          <FormikLabelError
            label="Which best describes your situation?"
            name={fieldNames.solePropAnswer}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="I'm a sole proprietor but I want to be an S corporation"
            variant="default"
            value={SolePropHelpAnswer.wantToBeScorp}
            name={fieldNames.solePropAnswer}
          />
        </GridRowColumn>
        {values.solePropAnswer === SolePropHelpAnswer.wantToBeScorp && (
          <GridRowColumn width={12}>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">
                Got it. We’ll have someone from our Support team reach out to
                help.
                <br />
                <br />
                Please be advised, S corporation election is an involved
                process. It may not be completed in time for the {taxYear} tax
                year.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="I'm not sure what type of business entity I'm operating or what my tax entity type is"
            variant="default"
            value={SolePropHelpAnswer.notSure}
            name={fieldNames.solePropAnswer}
          />
        </GridRowColumn>
        {values.solePropAnswer === SolePropHelpAnswer.notSure && (
          <GridRowColumn width={12}>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">
                Got it. We’ll have someone from our Support team reach out to
                help.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="It’s something else"
            variant="default"
            value={SolePropHelpAnswer.somethingElse}
            name={fieldNames.solePropAnswer}
          />
        </GridRowColumn>
        {values.solePropAnswer === SolePropHelpAnswer.somethingElse && (
          <GridRowColumn width={12}>
            <FormikTextArea
              label="Let our Support team know how we can help"
              name={fieldNames.solePropOther}
              schema={makeReqStringSchema()}
            />
          </GridRowColumn>
        )}
        <FormFlowFooter
          onBack={() => goBack(TAX_SEASON_KICKOFF_SURVEY.taxEntity)}
          onForward={submitForm}
          continueDisabled={isSubmitting || !isValid}
        />
      </Grid>
    </FormikProvider>
  )
}

export default SolePropTaxEntityHelp
