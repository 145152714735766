import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { MissingStatement } from '../../UserDocuments/userDocuments.slice'
import { Intro } from './intro'
import { AllDone } from './all-done'
import { OtherExpenses } from './other-expenses'
import { OtherIncome } from './other-income'
import { UnclarifiedTransactions } from './unclarified-transactions'
import { BusinessExpenseInPersonal } from './business-expense-in-personal-account'
import { BusinessIncomeInPersonal } from './business-income-in-personal-account'
import { OwnersInvestment } from './owners-investments'
import { OwnersDistribution } from './owners-distribution'
import { DisconnectedAccounts } from './disconnected-accounts'
import { ReviewIncome, ReviewIncomeProps } from './review_income'
import { ReviewExpenses } from './review_expenses'
import { DocumentsIntro } from './documents-intro'
import { PayrollDocuments } from './payroll-documents'
import { ContractorDocuments } from './contractor-documents'
import { MissingStatements } from './missing-bank-statements'

export interface IUserBookkeepingStatus {
  disconnectedBankAccounts: FinancialAccount[]
  missingBankStatements: MissingStatement[] | undefined
  unclarifiedTransactions: {
    uncatagorized: Transaction[]
    bookkeeperClarification: Transaction[]
  }
  otherExpenses: Transaction[]
  otherIncome: Transaction[]
  investmentTransactions: Transaction[]
  distributionTransactions: Transaction[]
  isSCorp: boolean
  lastEoyBookkeepingScreen: string
  incomeTransactions?: ReviewIncomeProps
}

export interface IStep {
  id: string | undefined // Identifier for the step in the backend
  name: string
  route: string
  component: unknown
  props: ((userBookkeepingStatus: IUserBookkeepingStatus) => unknown) | null
  isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => boolean
  isAlwaysComplete: boolean
}

export const STEPS: IStep[] = [
  {
    id: 'landing',
    name: 'Landing',
    route: 'intro',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: true,
  },
  {
    id: 'disconnected-accounts',
    name: 'Disconnected Accounts',
    route: 'disconnected-accounts',
    component: DisconnectedAccounts,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        financialAccounts: userBookkeepingStatus.disconnectedBankAccounts,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { disconnectedBankAccounts } = userBookkeepingStatus
      return disconnectedBankAccounts.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    id: undefined,
    name: 'Review Accounts',
    route: 'review-accounts',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    id: 'upload-missing-statements',
    name: 'Missing Bank Statements',
    route: 'missing-bank-statements',
    component: MissingStatements,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        missingStatements: userBookkeepingStatus.missingBankStatements,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { missingBankStatements } = userBookkeepingStatus
      return missingBankStatements ? missingBankStatements?.length > 0 : false
    },
    isAlwaysComplete: false,
  },
  {
    id: 'unclarified-transactions',
    name: 'Unclarified Transactions',
    route: 'unclarified-transactions',
    component: UnclarifiedTransactions,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        uncatTransactions:
          userBookkeepingStatus.unclarifiedTransactions.uncatagorized,
        bookkeeperClarification:
          userBookkeepingStatus.unclarifiedTransactions.bookkeeperClarification,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const {
        unclarifiedTransactions: { uncatagorized },
      } = userBookkeepingStatus

      return uncatagorized.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    id: 'other-income',
    name: 'Other Income',
    route: 'other-income',
    component: OtherIncome,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        otherIncomes: userBookkeepingStatus.otherIncome,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { otherIncome } = userBookkeepingStatus
      return otherIncome.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    id: 'other-expenses',
    name: 'Other Expenses',
    route: 'other-expenses',
    component: OtherExpenses,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        otherExpenses: userBookkeepingStatus.otherExpenses,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { otherExpenses } = userBookkeepingStatus
      return otherExpenses.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    id: 'personal-account-business-income',
    name: 'Business Income in Personal Accounts',
    route: 'business-income-in-personal-accounts',
    component: BusinessIncomeInPersonal,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    id: 'personal-account-business-expense',
    name: 'Business Expenses in Personal Accounts',
    route: 'business-expenses-in-personal-accounts',
    component: BusinessExpenseInPersonal,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    id: 'owner-investments',
    name: "Owner's Investments",
    route: 'owners-investments',
    component: OwnersInvestment,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        investmentTransactions: userBookkeepingStatus.investmentTransactions,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.isSCorp
    },
    isAlwaysComplete: false,
  },
  {
    id: 'distributions',
    name: "Owner's Distributions",
    route: 'owners-distributions',
    component: OwnersDistribution,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        distributionTransactions:
          userBookkeepingStatus.distributionTransactions,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return userBookkeepingStatus.isSCorp
    },
    isAlwaysComplete: false,
  },
  {
    id: 'docs-intro',
    name: 'Documents Intro',
    route: 'documents-intro',
    component: DocumentsIntro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: true,
  },
  {
    id: 'docs-payroll',
    name: 'Payroll',
    route: 'payroll-documents',
    component: PayrollDocuments,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    id: 'docs-contractor-payments',
    name: 'Contractor Payments',
    route: 'contractor-documents',
    component: ContractorDocuments,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: true,
  },
  {
    id: undefined,
    name: 'Review Income',
    route: 'review-income',
    component: ReviewIncome,
    props: () => {
      return {}
    },
    isRequired: () => {
      return true
    },
    isAlwaysComplete: false,
  },
  {
    id: undefined,
    name: 'Review Expenses',
    route: 'review-expenses',
    component: ReviewExpenses,
    props: () => {
      return {}
    },
    isRequired: () => {
      return true
    },
    isAlwaysComplete: false,
  },
  {
    id: undefined,
    name: 'Final Review',
    route: 'final-review',
    component: Intro,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    id: undefined,
    name: 'All Done',
    route: 'all-done',
    component: AllDone,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: false,
  },
]
