import { Grid, Image } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { FORM_1040_DETAILS_SCREENS, Form1040DetailsProps } from '.'
import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../../../components/BaseComponents/Grid'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TAX_ENTITY_TYPES } from '../../../../taxConstants'
import {
  getPersonalQuestionIds,
  getBusinessQuestionIds,
} from '../../Shared/PersonalDetails/CheckYourDetailsPanel'
import ReviewSubSection from '../../Shared/ReviewSubSection'
import { selectIsTwoFormFiler } from '../../../annualTaxFilingForms.selector'
import { businessInfoQuestionIds } from './BusinessInfoPanel'
import { locationInfoQuestionIds } from './LocationInfo'
import { healthcareInfoQuestionIds } from './HealthcareInfoPanel'
import { filingStatusQuestionIds } from './FilingStatusPanel'
import { spouseQuestionIds } from './SpousePanel'
import { TaxListQuestionId } from '../../service'
import { dependentQuestionIds } from './Dependents'
import { useNavigateWithLocation } from '../../../../../../utils/routeHelpers'
import { selectTqIsReadOnly } from '../../../annualTaxFilings.selector'

const Form1040ReviewPanel = ({
  goBack,
  previousScreen,
}: Form1040DetailsProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const navigate = useNavigateWithLocation()
  const { formId } = useParams()
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, taxYear)
  const readOnly = useReselector(selectTqIsReadOnly, formId)

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            Review your answers for accuracy. Click edit to go back to the
            previous section.
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text as="bodyLg">
            You can no longer edit your Personal Tax Questionnaire responses,
            but you can view them anytime.
          </Text>
        </GridRowColumn>
      )}
      <Grid.Row />
      <ReviewSubSection
        title="confirm personal details"
        questionIds={getPersonalQuestionIds(
          TAX_ENTITY_TYPES.form_1040,
          isTwoFormFiler
        )}
        editScreen={FORM_1040_DETAILS_SCREENS.confirmDetails}
      />
      {!isTwoFormFiler && (
        <ReviewSubSection
          title="confirm business details"
          questionIds={getBusinessQuestionIds(TAX_ENTITY_TYPES.form_1040)}
          editScreen={FORM_1040_DETAILS_SCREENS.confirmDetails}
        />
      )}
      <ReviewSubSection
        title="filing status"
        questionIds={filingStatusQuestionIds}
        editScreen={FORM_1040_DETAILS_SCREENS.filingStatus}
      />
      <ReviewSubSection
        title="update business information"
        questionIds={businessInfoQuestionIds}
        editScreen={FORM_1040_DETAILS_SCREENS.businessInfo}
      />
      <ReviewSubSection
        title="Update location information"
        questionIds={locationInfoQuestionIds}
        editScreen={FORM_1040_DETAILS_SCREENS.locationInfo}
      />
      <ReviewSubSection
        title="update healthcare information"
        questionIds={healthcareInfoQuestionIds}
        editScreen={FORM_1040_DETAILS_SCREENS.healthcareInfo}
      />
      <ReviewSubSection
        title="your spouse"
        questionIds={spouseQuestionIds}
        editScreen={FORM_1040_DETAILS_SCREENS.spouse}
      />
      <ReviewSubSection
        title="your dependents"
        questionIds={[TaxListQuestionId.has_dependents]}
        editScreen={FORM_1040_DETAILS_SCREENS.dependents}
      />
      <ReviewSubSection
        title="Dependent"
        questionIds={dependentQuestionIds}
        editScreen={FORM_1040_DETAILS_SCREENS.dependents}
        group="userDependentId"
      />
      {!readOnly && (
        <FormFlowFooter
          isSubmit
          onBack={() => goBack(previousScreen ?? null)}
          onForward={() => navigate('/taxes/annual/tax_checklist/')}
        />
      )}
    </Grid>
  )
}

export default Form1040ReviewPanel
